// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS

import * as jwt_decode from 'jwt-decode';



@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router) { }
    userData: any;
    token: any;
    tokenData: any;
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // return ;
        /*return this.store
            .pipe(
                select(isLoggedIn),
                tap(loggedIn => {
                    if (!loggedIn) {
                        this.router.navigateByUrl('/auth/login');
                    }
                })
            );*/
        this.token = localStorage.getItem('authToken');
        if (this.token) {
            this.tokenData = jwt_decode(this.token);
            //let permissionArr = this.tokenData.usergroup[0].permission;
            console.log(route.data.modulename)
            let permissionArr = this.tokenData.usergroup[0].permission;
            // tslint:disable-next-line: max-line-length  
            if (permissionArr && permissionArr.length > 0 && permissionArr[0].hasOwnProperty(route.data.modulename)
                && permissionArr[0][route.data.modulename].indexOf(route.data.permission) !== -1) {
                return true;
            } else {
                this.router.navigate(['/admin/access-forbidden']);
                return false;
            }
        } else {
            this.router.navigate(['/auth/login']);
            return false;
        }
    }
}

