// Angular
import { BrowserModule, HAMMER_GESTURE_CONFIG } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule, ErrorHandler } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpModule } from "@angular/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { GestureConfig, MatProgressSpinnerModule } from "@angular/material";
import { OverlayModule } from "@angular/cdk/overlay";
import { MatSelectModule } from "@angular/material/select";

// bugsnag
import Bugsnag from "@bugsnag/js";
import { BugsnagErrorHandler } from "@bugsnag/plugin-angular";

// Angular in memory
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
// NgBootstrap
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// Perfect Scroll bar
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
// SVG inline
import { InlineSVGModule } from "ng-inline-svg";
// Env

// Hammer JS
import "hammerjs";
// NGX Permissions
// Copmponents
import { AppComponent } from "./app.component";
// Modules
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
// Partials
import { PartialsModule } from "./views/partials/partials.module";
// Services
import { DataTableService } from "./core/base/layout";
// Layout Services
import { LayoutConfigService, LayoutRefService, MenuAsideService, MenuConfigService, MenuHorizontalService, PageConfigService, SplashScreenService, SubheaderService, KtDialogService } from "./core/base/layout";
// Auth

// CRUD
import { HttpUtilsService, LayoutUtilsService, TypesUtilsService, InterceptService } from "./core/base/crud";
// Config
import { LayoutConfig } from "./core/config/atladmin/layout.config";
// Highlight JS
import { HIGHLIGHT_OPTIONS, HighlightLanguage } from "ngx-highlightjs";
import * as typescript from "highlight.js/lib/languages/typescript";
import * as scss from "highlight.js/lib/languages/scss";
import * as xml from "highlight.js/lib/languages/xml";
import * as json from "highlight.js/lib/languages/json";
import { ToastrModule } from "ngx-toastr";
import { LoaderService } from "./core/base/layout/services/loader.service";
import { AuthGuard } from "./core/modules/auth/_guards/auth.guard";
import { ArtisteauthGuard } from "./core/modules/auth/_guards/artisteauth.guard";
import { ReactiveFormsModule } from "@angular/forms";
import { Ng8BreadcrumbModule } from "ng8-breadcrumb";
import { CookieService, CookieOptions } from "angular2-cookie/core";

import { ImageCropperModule } from "ngx-image-cropper";

// Bugsnag.start({ apiKey: "e8ac4d1ee2efde0f8500c87ca07164fb" });

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	wheelSpeed: 0.5,
	swipeEasing: true,
	minScrollbarLength: 40,
	maxScrollbarLength: 300,
};

export function errorHandlerFactory() {
	return new BugsnagErrorHandler();
}
export function initializeLayoutConfig(appConfig: LayoutConfigService) {
	// initialize app by loading default demo layout config
	return () => {
		if (appConfig.getConfig() === null) {
			appConfig.loadConfigs(new LayoutConfig().configs);
		}
	};
}

export function hljsLanguages(): HighlightLanguage[] {
	return [
		{ name: "typescript", func: typescript },
		{ name: "scss", func: scss },
		{ name: "xml", func: xml },
		{ name: "json", func: json },
	];
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		MatSelectModule,
		BrowserAnimationsModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		PartialsModule,
		CoreModule,
		OverlayModule,
		ReactiveFormsModule,
		NgbModule,
		HttpModule,
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		InlineSVGModule.forRoot(),
		ToastrModule.forRoot({
			timeOut: 3000,
			positionClass: "toast-bottom-right",
			preventDuplicates: true,
		}),
		HttpClientModule,
		Ng8BreadcrumbModule.forRoot({
			prefix: "Home", // default 'false'
		}),
		ImageCropperModule,
	],
	exports: [],
	providers: [
		{ provide: ErrorHandler, useFactory: errorHandlerFactory },
		AuthGuard,
		ArtisteauthGuard,
		LayoutConfigService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		KtDialogService,
		DataTableService,
		SplashScreenService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: GestureConfig,
		},
		{
			// layout config initializer
			provide: APP_INITIALIZER,
			useFactory: initializeLayoutConfig,
			deps: [LayoutConfigService],
			multi: true,
		},
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: { languages: hljsLanguages },
		},
		// template services
		SubheaderService,
		MenuHorizontalService,
		MenuAsideService,
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService,
		LoaderService,
		{ provide: CookieOptions, useValue: {} },
		{ provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
		// Store
	],
	
	bootstrap: [AppComponent],
})
export class AppModule {}
