import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import { CommonService } from "../../../common/services/common.service";

@Injectable({
	providedIn: "root",
})
export class NotificationService extends CommonService {
	constructor(http: HttpClient) {
		super("managerinvite", http);
	}
}
