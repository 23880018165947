import { Injectable } from "@angular/core";
import { Headers, Http, RequestOptions } from "@angular/http";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Observable, of } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ListingService {
	menu: any[];

	data: any;
	constructor(public http: HttpClient) {}
	baseUrl = environment.baseURL;
	socketUrl = environment.socketUrl;

	searchAll(searchObj, modulename) {
		if (modulename == "chat") {
			return this.http.post(this.baseUrl + modulename + "/connections", searchObj).pipe(
				map((response: any) => {
					return response;
				}),
				catchError((err) => {
					return null;
				})
			);
		}
		return this.http.post(this.baseUrl + modulename + "/list", searchObj).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}

	managerArtisteAll(searchObj, modulename) {
		return this.http.post(this.baseUrl + modulename + "/artisteList", searchObj).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}
	delete(id, modulename) {
		return this.http.delete(this.baseUrl + modulename + "/" + id).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}
	markAsRead(body, modulename) {
		return this.http.post(this.baseUrl + modulename + "/update", body).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}

	deleteSelected(idsForDeletion, modulename) {
		return this.http.post(this.baseUrl + modulename + "/updateMany", idsForDeletion).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}

	updateStatusMany(obj, modulename) {
		return this.http.post(this.baseUrl + modulename + "/updateMany", obj).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}

	sendSms(obj, modulename) {
		return this.http.post(this.baseUrl + modulename + "/sendSms", obj).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}
	unblock(obj, modulename) {
		console.log(obj);
		return this.http.post(this.baseUrl + modulename + "/unblock", obj).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}
	getOnlineUser() {
		try {
			return this.http.get(this.socketUrl + "ws").pipe(
				map((response: any) => {
					return response;
				}),
				catchError((err) => {
					return of(null); // Use 'of' to return an observable
				})
			);
		} catch (error) {
			return null;
		}
	}

	private handleError<T>(operation = "operation", result?: any) {
		return (error: any): Observable<any> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead
			// Let the app keep running by returning an empty result.
			return of(result);
		};
	}
}
