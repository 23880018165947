// Angular
import { Component, HostBinding, OnInit, Input } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
// RxJS
import { filter } from 'rxjs/operators';
// Translate
import { TranslationService } from '../../../../../core/base/layout';
import { LunguageselectionService } from './language-selector.service';
import { CountryselectionService } from '../country-selector/country-selector.service';
import { LanguageService } from '../../../../../core/common/services/language.service';
import { GridOption } from '../../../../../core/base/layout/models/gridOption.model';



@Component({
	selector: 'kt-language-selector',
	templateUrl: './language-selector.component.html',
	styleUrls: ['./language-selector.scss']
})
export class LanguageSelectorComponent implements OnInit {
	// Public properties
	@HostBinding('class') classes = '';
	@Input() iconType: '' | 'brand';
	country: any;
	language: any;
	languages: any;
	gridOption: GridOption = {
		pageTitle: 'select Page',
		page: 1,
		pagesize: 5,
		total: 0,
		order: '-_id',
		search: '',
		filter: {},
		sortField: '',
		sortOrder: '',
		skip: 0,
		limit: 0,
		allrecords: false
	};
	/**
	 * Component constructor
	 *
	 * @param translationService: TranslationService
	 * @param router: Router
	 */
	// tslint:disable-next-line: max-line-length
	constructor(private translationService: TranslationService, private router: Router ,   private lunguageselectionService: LunguageselectionService , private countryselectionService: CountryselectionService,private languageService: LanguageService) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		// tslint:disable-next-line: triple-equals
		// this.countryselectionService.countryVisibilityChange.subscribe((value) => {
		// 	this.country = value;
		// });
		this.getLanguage();
		this.router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe(event => {
				this.setSelectedLanguage();
			});
	}

	getLanguage() {
				  this.languages = this.languageService.getLanguage();
				  if (!localStorage.getItem('language')) {
					this.translationService.setLanguage(this.languages[0]);
				}
				  this.setSelectedLanguage();
	}

	/**
	 * Set language
	 *
	 * @param lang: any
	 */
	setLanguage(lang) {
		this.languages.forEach((language) => {
			if (language.locale === lang.locale) {
				language.active = true;
				this.language = language;
			} else {
				language.active = false;
			}
		});
		// this.languageService.getLanguage(this.languages);
		this.lunguageselectionService.changeLanguage(lang);
		// this.translationService.setLanguage(this.language);
	}

	/**
	 * Set selected language
	 */
	setSelectedLanguage(): any {
		this.setLanguage(this.translationService.getSelectedLanguage());
	}
}
