import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, ElementRef, AfterViewInit, EventEmitter, Inject, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";

import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

export interface DialogData3 {
	rejectOptions: any;
}

@Component({
	selector: "reject-dialog",
	templateUrl: "./rejectDialoge.html",
	styleUrls: ["./rejectDialoge.scss"],
})
export class RejectDialoge implements OnInit, OnDestroy {
	rejectOptions: any;
	constructor(public dialogRef: MatDialogRef<RejectDialoge>, private toastr: ToastrService, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) public data: DialogData3) {}
	ngOnInit() {
		this.rejectOptions = this.data.rejectOptions;
	}

	save(): void {}
	onKeyDown(event: KeyboardEvent, value: string) {}
	onRejectCancel() {
		this.dialogRef.close();
	}
	onRejectSubmit(reject: any) {
		let finalArr = [];
		for (let element of this.rejectOptions) {
			if (element.checked) {
				finalArr.push(element);
			}
		}
		if (reject.form.valid) {
			this.dialogRef.close(finalArr);
		}
	}
	ngOnDestroy() {}
}
