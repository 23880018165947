// Angular
import { Pipe, PipeTransform } from "@angular/core";
import { TranslationService } from "../services/translation.service";

/**
 * Returns object from parent object
 */
@Pipe({
	name: "getColumnObjectValue",
})
export class GetColumnObjectValuePipe implements PipeTransform {
	/**
	 * Transform
	 *
	 * @param value: any
	 */
	transform(value: any, fields: any): any {
		if (fields) {
			let strArr = fields.split(".");
			let res = value;
			for (let key in strArr) {
				let k = strArr[key];
				res = res[k];
			}

			return res;
		} else {
			let strArr = value.split(".");
			if (strArr.length > 1) {
				if (strArr[0] == "translations") {
					// check first is translations or not
					return value.split(".").pop().replace(/_/g, "_");
				} else {
				
					return strArr[0];
				}
			} else {
				return value;
			}
		}
	}
}
