import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of, BehaviorSubject } from "rxjs";
import { catchError, startWith, switchMap } from "rxjs/operators";
import { User } from "../../../../../app/views/partials/layout/topbar/user-profile/model/user.model";
import { QueryResultsModel } from "../../../base/crud";
import { Router } from "@angular/router";
import { environment } from "../../../../../environments/environment";
import { map } from "rxjs/operators";

const baseURL = environment.baseURL;
const API_USERS_URL = environment.baseURL + "user/";
const API_MANAGER_URL = environment.baseURL + "manager/";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	// private currentUserSubject: BehaviorSubject<User>;

	currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(JSON.parse(localStorage.getItem("currentUser")));
	currentUser$ = this.currentUser.asObservable();

	constructor(private myRoute: Router, private http: HttpClient) {}

	httpOptions = {
		headers: new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: "Bearer " + localStorage.getItem("currentUserToken"),
		}),
	};

	// Authentication/Authorization
	login(searchObj): Observable<any> {
		return this.http.post<User>(baseURL + "auth/login", searchObj).pipe(
			map((res: any) => {
				// login successful if there's a jwt token in the response
				if (res && res.status === 1) {
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					this.currentUser.next(res.data.user);
				}
				return res;
			})
		);
	}

	// Authentication/Authorization
	otpSent(searchObj): Observable<any> {
		return this.http.post<User>(baseURL + "user/adminArtisteLoginOtpSent", searchObj).pipe(
			map((res: any) => {
				// login successful if there's a jwt token in the response
				if (res && res.status === 1) {
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					return res;
				}
				//console.log(res);
				return res;
			})
		);
	}

	artisteLogin(searchObj): Observable<any> {
		return this.http.post<User>(baseURL + "user/adminArtisteLoginOtpVerify", searchObj).pipe(
			map((res: any) => {
				// login successful if there's a jwt token in the response
				if (res && res.status === 1) {
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					this.currentUser.next(res.data.user);
				}
				return res;
			})
		);
	}

	managerLogin(searchObj): Observable<any> {
		return this.http.post<User>(baseURL + "manager/login", searchObj).pipe(
			map((res: any) => {
				// login successful if there's a jwt token in the response
				if (res && res.status === 1) {
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					this.currentUser.next(res.data.user);
				}
				return res;
			})
		);
	}

	webLogin(searchObj): Observable<any> {
		return this.http.post<User>(baseURL + "talent/webLoginSentOTp", searchObj).pipe(
			map((res: any) => {
				// login successful if there's a jwt token in the response
				if (res && res.status === 1) {
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					this.currentUser.next(res.data.user);
				}
				return res;
			})
		);
	}
	webLoginVerify(searchObj): Observable<any> {
		return this.http.post<User>(baseURL + "talent/webLoginVerifyOTp", searchObj).pipe(
			map((res: any) => {
				// login successful if there's a jwt token in the response
				if (res && res.status === 1) {
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					this.currentUser.next(res.data.user);
				}
				return res;
			})
		);
	}

	getUserDetail() {
		return this.currentUser.asObservable();
	}

	verifyEmail(data) {
		let token = {
			user_token: data,
		};
		return this.http.post<any>(baseURL + "verifyemail/", token);
	}

	getQrCodeData() {
		return this.http.get<any>(baseURL + "qr/");
	}
	checkLogin(data) {
		return this.http.post<any>(baseURL + "qr/", data).pipe(
			map((res: any) => {
				return res;
			})
		);
		// return this.http.post<User>(baseURL + 'artiste/webLogin', searchObj)
	}
	confirmlogin(data) {
		const httpHeaders = new HttpHeaders();
		return this.http.post<any>(baseURL + "qr/confirmlogin", data, { headers: httpHeaders });
	}

	verifyToken(data) {
		let token = { token: data };
		return this.http.post<any>(API_USERS_URL + "verify/", token);
	}

	/*
	 * Submit set new password request
	 *
	 * @param {string} email
	 * @returns {Observable<any>}
	 */
	setPassword(data) {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set("Content-Type", "application/json");
		return this.http.post<any>(API_USERS_URL + "resetPassword", data, { headers: httpHeaders });
	}

	getUserByToken(): Observable<User> {
		const httpHeaders = new HttpHeaders();
		return this.http.get<User>(baseURL, { headers: httpHeaders });
	}

	/*
	 * Submit forgot password request
	 *
	 * @param {string} email
	 * @returns {Observable<any>}
	 */
	public requestPassword(searchobj): Observable<any> {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set("Content-Type", "application/json");
		return this.http.post<User>(API_USERS_URL + "forgotPassword", searchobj, { headers: httpHeaders });
	}

	getAllUsers(): Observable<User[]> {
		return this.http.get<User[]>(API_USERS_URL);
	}

	getUserById(userId: any) {
		//return this.http.get(API_USERS_URL + `${userId}`);
		return this.http.get(API_USERS_URL + `${userId}`).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}

	getManagerById(userId: any) {
		//return this.http.get(API_USERS_URL + `${userId}`);
		return this.http.get(API_MANAGER_URL + `${userId}`).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((err) => {
				return null;
			})
		);
	}

	// DELETE => delete the user from the server
	deleteUser(userId: number) {
		const url = `${API_USERS_URL}/${userId}`;
		return this.http.delete(url);
	}

	editUser(data) {
		return this.http.put<any>(API_USERS_URL, data).pipe(
			map((user: any) => {
				// login successful if there's a jwt token in the response
				if (user && user.status === 1) {
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					localStorage.setItem("currentUser", JSON.stringify(user.data));
					this.currentUser.next(user.data);
				}
				return user;
			})
		);
	}

	// UPDATE => PUT: update the user on the server
	updateUser(_user: User): Observable<any> {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set("Content-Type", "application/json");
		return this.http.put(API_USERS_URL, _user, { headers: httpHeaders });
	}

	// CREATE =>  POST: add a new user to the server
	createUser(user: User): Observable<User> {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set("Content-Type", "application/json");
		return this.http.post<User>(API_USERS_URL, user, { headers: httpHeaders });
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	findUsers(queryParams): Observable<QueryResultsModel> {
		const httpHeaders = new HttpHeaders();
		httpHeaders.set("Content-Type", "application/json");
		return this.http.post<QueryResultsModel>(API_USERS_URL, queryParams, { headers: httpHeaders });
	}

	userChartCount() {
		return this.http.get(API_USERS_URL + "getMonthWiseCount");
	}

	removeImage(obj) {
		return this.http.post<any>(API_USERS_URL + "removeImage", obj);
	}

	changePassword(_user) {
		const httpHeaders = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("authToken"),
		});
		return this.http.post(API_USERS_URL + "changepassword", _user, { headers: httpHeaders });
	}

	managerChangePassword(_user) {
		const httpHeaders = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("authToken"),
		});
		return this.http.post(API_MANAGER_URL + "changepassword", _user, { headers: httpHeaders });
	}

	/*
	 * Handle Http operation that failed.
	 * Let the app continue.
	 *
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private handleError<T>(operation = "operation", result?: any) {
		return (error: any): Observable<any> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// Let the app keep running by returning an empty result.
			return of(result);
		};
	}

	sendToken(token: string) {
		localStorage.setItem("currentUser", token);
	}

	logout() {
		let rememberme = localStorage.getItem("rememberme");
		let userObj = localStorage.getItem(`currentUser`);
		let parsed = JSON.parse(userObj);
		if (rememberme === "true") {
			localStorage.removeItem(environment.authTokenKey);
			localStorage.removeItem("currentUser");
			// localStorage.removeItem('authToken');
		} else {
			localStorage.removeItem(environment.authTokenKey);
			localStorage.removeItem("currentUser");
			localStorage.removeItem("authToken");
		}
		if (parsed && parsed.userType == "01") {
			this.myRoute.navigateByUrl("auth/talent");
		} else {
			this.myRoute.navigateByUrl("auth/login");
		}
	}
}
