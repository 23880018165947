import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET, ActivatedRouteSnapshot, RoutesRecognized } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../../../../../app/core/base/layout/services/translation.service';
import { LunguageselectionService } from '../../../../../app/views/partials/layout/topbar/language-selector/language-selector.service';
import { isNullOrUndefined } from 'util';

interface IBreadcrumb {
  label: string;
  params?: Params;
  url: string;
}

@Component({
  selector: 'kt-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  langobj: any;
  public breadcrumbs: IBreadcrumb[];
  private subscription;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private translationService: TranslationService,
    private lunguageselectionService: LunguageselectionService,
  ) {
    this.breadcrumbs = [];
    this.subscription =  this.lunguageselectionService.languageVisibilityChange.subscribe((value) => {
    this.langobj = value;
    this.translateService.use(this.langobj.locale);
    });
  }
  ngOnInit() {
    const ROUTE_DATA_BREADCRUMB: string = 'breadcrumb';
    // subscribe to the NavigationEnd event
    let root: ActivatedRoute = this.activatedRoute.root;
    this.breadcrumbs = this.getBreadcrumbs(root);
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe(x => {
          let path: ActivatedRoute = this.activatedRoute.root;
          this.breadcrumbs = this.getBreadcrumbs(path);
        }
      );
  }
  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    const children: ActivatedRoute[] = route.children;
    const ROUTE_DATA_BREADCRUMB: string = 'breadcrumb';
    if (children.length === 0) {
      return breadcrumbs;
    }
    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
      const label = child.snapshot.data[ROUTE_DATA_BREADCRUMB];
      const params = child.snapshot.params;
      if (!isNullOrUndefined(label)) {
        breadcrumbs.push({label, url,params});
      }
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
  }
  titleCase(str: any) {
    if (str) {
      let strAr = str.toLowerCase().split(' ');
      for (let i = 0; i < strAr.length; i++) {
        strAr[i] = strAr[i].charAt(0).toUpperCase() + strAr[i].slice(1);
      }
      return strAr.join(' ');
    }
  }
}
