// Angular
import { Component, HostBinding, OnInit, Input } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
// RxJS
import { filter } from 'rxjs/operators';
// Translate
import { TranslationService } from '../../../../../core/base/layout';
import { CountryselectionService } from './country-selector.service';

interface LanguageFlag {
    co: string;
    name: string;
    flag: string;
    active?: boolean;
}

@Component({
    selector: 'kt-country-selector',
    templateUrl: './country-selector.component.html',
})
export class CountrySelectorComponent implements OnInit {
    // Public properties
    @HostBinding('class') classes = '';
    @Input() iconType: '' | 'brand';

    country: LanguageFlag;
    countries: LanguageFlag[] = [
        {
            co: 'ind',
            name: 'India',
            flag: './assets/media/flags/010-india.svg'
        },
        {
            co: 'usa',
            name: 'USA',
            flag: './assets/media/flags/012-uk.svg'
        },
        {
            co: 'esp',
            name: 'Spain',
            flag: './assets/media/flags/016-spain.svg'
        },
        {
            co: 'kor',
            name: 'Korea',
            flag: './assets/media/flags/018-south-korea.svg'
        },
        {
            co: 'ger',
            name: 'Germany',
            flag: './assets/media/flags/017-germany.svg'
        },
        {
            co: 'fr',
            name: 'France',
            flag: './assets/media/flags/019-france.svg'
        },
    ];

	/**
	 * Component constructor
	 *
	 * @param translationService: TranslationService
	 * @param router: Router
	 */
    constructor(private translationService: TranslationService, private router: Router, private countryselectionService: CountryselectionService) {
    }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
    ngOnInit() {
        this.setSelectedCountry();
        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(event => {
                this.setSelectedCountry();
            });
        if (!this.country) {
            this.setCountry(JSON.stringify(this.countries[0]));
        }
    }

	/**
	 * Set country
	 *
	 * @param country: any
	 */
    setCountry(coun) {
        this.countries.forEach((country: LanguageFlag) => {
            if (country.co === coun) {
                country.active = true;
                this.country = country;
            } else {
                country.active = false;
            }
        });
        this.translationService.setCountry(coun);
        this.countryselectionService.changeCountry(coun);
    }

    setSelectedCountry(): any {
        this.setCountry(this.translationService.getSelectedCountry());
    }
}
