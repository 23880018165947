export class Address {
    resident_location: {
        address: string ;
        city: string;
        state: string;
        country: string;
        zipcode: string;
    };
    work_location: {
        address: string;
        city: string;
        state: string;
        country: string;
        zipcode: string;
    }

    clear() {
        this.resident_location = {
            address: '',
            city: '',
            state: '',
            country: '',
            zipcode: '',
        };
        this.work_location = {
            address: '',
            city: '',
            state: '',
            country: '',
            zipcode: '',
        }
    }
}
