import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from "@angular/core";

@Component({
	selector: "tfilter-dropdown",
	templateUrl: "./dropdown.html",
	styleUrls: ["./dropdown.scss"],
})
export class TFilterDropDown implements OnInit, OnDestroy {
	@Input() options = [];
	@Input() click: any;

	selectedValue = "";
	openDownDropdown: boolean = false;

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnInit() {
		if (this.options.length) {
			this.selectedValue = this.options[0];
		}
	}

	handleDropdown() {
		this.openDownDropdown = !this.openDownDropdown;
	}

	handleClick(option) {
		this.selectedValue = option;
		this.handleDropdown();
		this.click(option);
	}

	handleDropdownClick() {}

	ngOnDestroy() {}
}
