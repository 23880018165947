import { AfterViewInit, Component, ViewChild, OnInit, ChangeDetectionStrategy, 
  ChangeDetectorRef, Input, OnDestroy, HostListener, Inject } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { ListingService } from '../../../common/services/listing.service';
import { GridOption } from '../../../../../app/core/base/layout/models/gridOption.model';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap, filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { DeleteEntityDialogComponent } from '../../../../../app/views/partials/content/crud/delete-entity-dialog/delete-entity-dialog.component';
import { UpdateStatusDialogComponent } from '../../../../../app/views/partials/content/crud/update-status-dialog/update-status-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { ModuleUpdateModel } from './model/moduleUpdate-model';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../../../../../app/core/base/layout/services/translation.service';
import { LunguageselectionService } from '../../../../../app/views/partials/layout/topbar/language-selector/language-selector.service';
import { NgForm } from '@angular/forms';
import { LanguageService } from '../../services/language.service';
import { Subject } from 'rxjs';
import * as jwt_decode from 'jwt-decode';

import {DashboardService} from '../../../modules/artiste/dashboard/service/dashboard.service';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'kt-listing-manager-artiste',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListingmanagerartisteComponent implements OnInit, OnDestroy {
  @Input() modulenameSingular;
  @Input() modulenamePlural;
  @Input() moduleNameServiceRoute;
  @Input() displayedColumns;  
  @Input() field;
  @Input() notdelete;
  @Input() advanceSearchFields;
  @Input() notedit;
  @Input() source;
  @Input() fieldgroup;
  @Input() actionButton;
  result: any;
  private subscription;
  companyselect: string;
  dataSource: any = new MatTableDataSource();
  selection: any = new SelectionModel(true);
  moduleUpdateModel = new ModuleUpdateModel();
  resultsLength = 0;
  campaignListDeleteSuccess = '';
  campaignListDeleteConfirm = '';
  isLoadingResults = false;
  isRateLimitReached = false;
  EnoughPermission: any = '';
  isCreate = false;
  isRead = false;
  isReadCamp = false;
  isDelete = false;
  isUpdate = false;
  siteTitle = '';
  langobj: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selectedStatus: number = 2;
  statusArr: any = [{ 'value':  this.translateService.instant('PAGES.COMMON.ALL'), 'key': 2 },
    { 'value': this.translateService.instant('PAGES.COMMON.ACTIVE'), 'key': 1 },
    { 'value': this.translateService.instant('PAGES.COMMON.INACTIVE'), 'key': 0 }
  ];
  filterArr: any = [0,1];
  gridOption: GridOption = {
    order: '-_id',
    search: '',
    filter: {},
    sortField: '_id',
    sortOrder: 'desc',
    skip: 0,
    limit: 10,
    allrecords: false
  };
  languageArr: any;
  currentLang: any;
  actionURL: string;
  token: any;
  tokenData: any;
  routeData: any;
  permissionArr: any;
  status: any = null;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService,
    private commonlistingService: ListingService,
    private cd: ChangeDetectorRef,
    private translateService: TranslateService,
    private translationService: TranslationService,
    private languageService: LanguageService,
    private lunguageselectionService: LunguageselectionService,
    private activatedRoute: ActivatedRoute

  ) {

    this.subscription = this.lunguageselectionService.languageVisibilityChange.subscribe((value) => {
      this.currentLang = JSON.parse(localStorage.getItem('language'));
      this.langobj = value;
      this.statusArr = [{ 'value':  this.translateService.instant('PAGES.COMMON.ALL'), 'key': 2 },
      { 'value': this.translateService.instant('PAGES.COMMON.ACTIVE'), 'key': 1 },
      { 'value': this.translateService.instant('PAGES.COMMON.INACTIVE'), 'key': 0 }
    ];
      if (this.currentLang.locale !== this.langobj.locale) {
        this.translationService.setLanguage(this.langobj);
        this.statusArr = [{ 'value':  this.translateService.instant('PAGES.COMMON.ALL'), 'key': 2 },
        { 'value': this.translateService.instant('PAGES.COMMON.ACTIVE'), 'key': 1 },
        { 'value': this.translateService.instant('PAGES.COMMON.INACTIVE'), 'key': 0 }
      ];
        this.getData();
      }
    });
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(); // create new object
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.gridOption.filter.status = { $in: [0, 1] };
    this.actionURL = this.router.url;
   
    this.token = localStorage.getItem('authToken');
    this.tokenData = jwt_decode( this.token);
    this.permissionArr = this.tokenData.usergroup[0].permission;    
    this.routeData = this.activatedRoute.snapshot.data;    
    let usergroup = this.tokenData.usergroup;

    if(this.routeData && this.routeData.modulename === 'profilerequest'){
      this.gridOption.filter.status = { $in: [0, 1, 3] };
      //this.status = 0;
      this.filterArr = [0,1,3];
      this.statusArr = [
        { 'value':  this.translateService.instant('PAGES.COMMON.ALL'), 'key': 2 },
        { 'value': this.translateService.instant('PAGES.PROFILEREQUEST.PENDING'), 'key': 3 },
        { 'value': this.translateService.instant('PAGES.PROFILEREQUEST.APPROVED'), 'key': 1 },
        { 'value': this.translateService.instant('PAGES.PROFILEREQUEST.REJECTED'), 'key': 0 }
      ];
    }
    if(this.routeData && this.routeData.modulename === 'artiste'){
      this.gridOption.filter.status = { $in: [0, 1, 3] };
      //this.status = 0;
      this.filterArr = [0,1,3];
      this.statusArr = [
        { 'value':  this.translateService.instant('PAGES.COMMON.ALL'), 'key': 2 },
        { 'value': this.translateService.instant('PAGES.ARTISTE.PENDING'), 'key': 3 },
        { 'value': this.translateService.instant('PAGES.COMMON.ACTIVE'), 'key': 1 },
        { 'value': this.translateService.instant('PAGES.COMMON.INACTIVE'), 'key': 0 }
      ];
    }
    if(this.routeData && this.routeData.modulename === 'notification'){
      this.gridOption.filter.status = { $in: [0, 1, 3, 4] };
      //this.status = 0;
      this.filterArr = [0,1, 3, 4];
      this.statusArr = [
        { 'value':  this.translateService.instant('PAGES.COMMON.ALL'), 'key': 2 },
        { 'value': this.translateService.instant('PAGES.NOTIFICATION.ACTIVE'), 'key': 1 },
        { 'value': this.translateService.instant('PAGES.NOTIFICATION.SENT'), 'key': 3 },
        { 'value': this.translateService.instant('PAGES.NOTIFICATION.SENTERROR'), 'key': 4 },
        { 'value': this.translateService.instant('PAGES.NOTIFICATION.INACTIVE'), 'key': 0 }
      ];
    }
    this.getData();
  }


  Sort() {
    this.gridOption.limit = this.paginator.pageSize;
    this.gridOption.skip = this.paginator.pageSize * this.paginator.pageIndex;
    this.gridOption.sortField = this.sort.active;
    this.gridOption.sortOrder = this.sort.direction;
    this.paginator.pageIndex = 0;
    this.getData();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  pageChanged() {
    this.gridOption.limit = this.paginator.pageSize;
          this.gridOption.skip = this.paginator.pageSize * this.paginator.pageIndex;
          this.gridOption.sortField = this.sort.active;
          this.gridOption.sortOrder = this.sort.direction;
          this.getData();
  }

  deleteModule(id): void {
    let dialogRef = this.dialog.open(DeleteEntityDialogComponent, {
      // tslint:disable-next-line: max-line-length
      data: { 'description': this.translateService.instant('PAGES.COMMON.PERMANTLYDELETE') + ' ' + this.titleCase(this.modulenameSingular), 'title': this.titleCase(this.modulenameSingular) + ' ' + this.translateService.instant('PAGES.COMMON.DELETE') + ' ', 'waitDesciption': this.titleCase(this.modulenameSingular) + ' ' + this.translateService.instant('PAGES.COMMON.ISDELETING') + '...' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.commonlistingService.delete(id, this.moduleNameServiceRoute).subscribe(res => {
          if (res.message === 'success' || res.status === 1) {
            if (this.source) {
              this.router.navigate(['/' + this.moduleNameServiceRoute + '/' + this.source]).then(() => {
                // tslint:disable-next-line: max-line-length
                this.toastr.success(this.translateService.instant('PAGES.COMMON.DELETE') + ' ' + this.modulenameSingular + ' ' + this.translateService.instant('PAGES.COMMON.SUCCESS'), this.translateService.instant('PAGES.COMMON.SUCCESS'));
                this.getData();
              });
            } else {
              this.router.navigate([this.actionURL]).then(() => {
                // tslint:disable-next-line: max-line-length
                this.toastr.success(this.translateService.instant('PAGES.COMMON.DELETE') + ' ' + this.modulenameSingular + ' ' + this.translateService.instant('PAGES.COMMON.SUCCESS'), this.translateService.instant('PAGES.COMMON.SUCCESS'));
                this.getData();
              });
            }
          } else {
            this.toastr.error(res.message.id, this.translateService.instant('PAGES.COMMON.ERROR'));
          }
        });
      }
    });
  }

  deleteModules() {
    const dialogRef = this.dialog.open(DeleteEntityDialogComponent, {
      // tslint:disable-next-line: max-line-length
      data: { 'description': this.translateService.instant('PAGES.COMMON.PERMANTLYDELETE') + ' ' + this.titleCase(this.modulenamePlural) + '?', 'title': '' + this.titleCase(this.modulenamePlural) + ' ' + this.translateService.instant('PAGES.COMMON.DELETE') + ' ', 'waitDesciption': this.titleCase(this.modulenamePlural) + ' ' + this.translateService.instant('PAGES.COMMON.ISDELETING') + '...' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        let idsForDeletion = [];
        for (let i = 0; i < this.selection.selected.length; i++) {
          idsForDeletion.push(this.selection.selected[i]._id);
        }
        this.moduleUpdateModel.ids = idsForDeletion;
        this.moduleUpdateModel.action = 'delete';
        this.moduleUpdateModel.value = 2;
        this.commonlistingService.deleteSelected(this.moduleUpdateModel, this.moduleNameServiceRoute).subscribe(res => {
          if (res.message === 'success' || res.status === 1) {
            this.router.navigate([this.actionURL]).then(() => {
              // tslint:disable-next-line: max-line-length
              this.toastr.success(this.translateService.instant('PAGES.COMMON.DELETE') + ' ' + this.modulenameSingular + ' ' + this.translateService.instant('PAGES.COMMON.SUCCESS'), this.translateService.instant('PAGES.COMMON.SUCCESS'));
              this.selection.clear();
              this.getData();
            });
          } else {
            this.toastr.error(res.message.id, this.translateService.instant('PAGES.COMMON.ERROR'));
          }
        });
      }
    });
  }

  updateModules() {
    const statuses = [{ value: 1, text: 'Active' }, { value: 0, text: 'InActive' }];
    const dialogRef = this.dialog.open(UpdateStatusDialogComponent, {
      // tslint:disable-next-line: max-line-length
      data: { 'Message': this.translateService.instant('PAGES.COMMON.SELECTED') + ' ' + this.titleCase(this.modulenamePlural) + ' ' + this.translateService.instant('PAGES.COMMON.STATUSUPDATED'), 'title': this.translateService.instant('PAGES.COMMON.STATUSSELECTEDUPDATE') + ' ' + this.titleCase(this.modulenamePlural), 'statuses': statuses }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        let idsForDeletion = [];
        for (let i = 0; i < this.selection.selected.length; i++) {
          idsForDeletion.push(this.selection.selected[i]._id);
        }
        this.moduleUpdateModel.ids = idsForDeletion;
        this.moduleUpdateModel.action = 'update';
        this.moduleUpdateModel.value = result;
        this.commonlistingService.updateStatusMany(this.moduleUpdateModel, this.moduleNameServiceRoute).subscribe(res => {
          if (res.status === 1) {
            this.router.navigate([this.actionURL]).then(() => {
              // tslint:disable-next-line: max-line-length
              this.toastr.success(this.translateService.instant('PAGES.COMMON.SELECTED') + ' ' + this.titleCase(this.modulenamePlural) + ' ' + this.translateService.instant('PAGES.COMMON.STATUSUPDATED'), this.translateService.instant('PAGES.COMMON.SUCCESS'));
              this.selection.clear();
              this.getData();
            });
          }
        });
      }
    });

  }

  getData() {
    if (this.source) {
      this.gridOption.filter.source = this.source;
    }
    this.commonlistingService.managerArtisteAll(this.gridOption, this.moduleNameServiceRoute).subscribe((data: any) => {
      this.result = data;
      this.dataSource = new MatTableDataSource(this.result.data);
      this.resultsLength = this.result.totalCount;
      this.cd.detectChanges();
   });
 }



  applyFilter(filterValue: NgForm) {
    let filterData = filterValue.value;
    let filterStatus = filterData.status;
    this.gridOption.limit = this.paginator.pageSize;
    this.gridOption.skip = this.paginator.pageSize * this.gridOption.page;
    this.gridOption.search = filterData.search;
    // delete filterData.search;  // remove search and status from original object
    // delete filterData.status;
    // tslint:disable-next-line: triple-equals
    let filterDataTest = Object.entries(filterData).reduce((a,[k,v]) => ((v == null || v == '' ||  v == undefined) ? a : {...a, [k]: v}), {}); // delete null/undefined/blank from filter
    this.gridOption.filter = filterDataTest;
    delete this.gridOption.filter.search;
    // tslint:disable-next-line: triple-equals
    //if ((filterStatus == 0 || filterStatus == 1) && filterStatus) {
    
    if(this.filterArr.indexOf(Number(filterStatus)) > -1 && filterStatus != "2" && filterStatus != null){      
      this.gridOption.filter.status = Number(filterStatus);
    } else {
      if(this.routeData && (this.routeData.modulename === 'profilerequest' || this.routeData.modulename === 'artiste')){
        this.gridOption.filter.status = { $in: [0, 1, 3] };
      }else if(this.routeData && this.routeData.modulename === 'notification'){
        this.gridOption.filter.status = { $in: [0, 1, 3, 4] };
      }else{
        this.gridOption.filter.status = { $in: [0, 1] };
      }
      
    }
    this.paginator.pageIndex = 0;
    this.getData();
  }

  resetFilter(filterForm: NgForm) {
    filterForm.reset();   
    this.applyFilter(filterForm);
  }

  /** FILTRATION */
  filterConfiguration(filterValue): any {
    const filter: any = {};
    filter.name = filterValue;
    filter.status = this.gridOption.filter.status;
    return filter;
  }

  search() {
    if (this.source) {
      this.gridOption.filter.source = this.source;
    }
    setTimeout(() => {
    return this.commonlistingService.searchAll(this.gridOption, this.moduleNameServiceRoute);
  }, 0);
  }

  loadModuleList(statusValue: any) {
    this.selectedStatus = Number(statusValue);
    this.gridOption.limit = this.paginator.pageSize;
    this.gridOption.skip = this.paginator.pageSize * this.gridOption.page;
    if (this.selectedStatus === 0 || this.selectedStatus === 1) {
      this.gridOption.filter.status = Number(statusValue);
    } else {
      this.gridOption.filter.status = { $in: [0, 1] };
    }
    this.getData();
  }

  titleCase(str: any) {
    if (str) {
      let strAr = str.toLowerCase().split(' ');
      for (let i = 0; i < strAr.length; i++) {
        strAr[i] = strAr[i].charAt(0).toUpperCase() + strAr[i].slice(1);
      }
      return strAr.join(' ');
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openProfileViewDialog(userId): void {
		const dialogRef = this.dialog.open(ManagerArtisteProfileViewDialog, {
      width: '800px',
      panelClass:'icon-outside',
		  data: {userId: userId}
		});
	
		dialogRef.afterClosed().subscribe(result => {
		 if(result){		  	
		 }else{
		}  
		});		
	}

}

export interface DialogData {
	userId: string;	
}

@Component({
	selector: 'artiste-view-dialog',
  templateUrl: 'artiste-view.component.html',
  styleUrls: ['artiste-view.css'],
})
export class ManagerArtisteProfileViewDialog {  
  private subscription;
  useProfileViewFilter: string;
  paymentGraph2 = false;
	constructor(
    public dialogRef: MatDialogRef<ManagerArtisteProfileViewDialog>,
    private dashboardService: DashboardService,
		@Inject(MAT_DIALOG_DATA) public data: DialogData,		
	) {}

  ngOnInit(): void {		
		this.useProfileViewFilter = 'm';
		this.getProfileView();
  }
  
  close(managerForm): void {
		this.dialogRef.close();
  }
  filterProfileView(filterBy){
		this.useProfileViewFilter = filterBy;
		this.getProfileView();		
	}
	getProfileView(){
		this.dashboardService.getArtisteProfileViews(this.useProfileViewFilter, this.data.userId).subscribe(response => {
			var redData = response.data;			
			if(response.status == 1 && redData.xAxis && redData.yAxis){	
				this.paymentGraph2 = true;			
				Highcharts.chart('artisteProfileViews', {
					chart: {
						type: 'column'
					},
					title: {
						text: ''
					},
					series:redData.yAxis,
					xAxis:{ categories: redData.xAxis},
					yAxis: {
						min: 0,
						title: {
							text: 'Profile View(s)'
						}
					},
					credits: {
						enabled: false
					},
					tooltip: {
						headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
						pointFormat: '<tr><td style="color:{series.color};padding:0">Total User(s): </td>' +
							'<td style="padding:0"><b>{point.y}</b></td></tr>',
						footerFormat: '</table>',
						shared: true,
						useHTML: true
					}
				});	
				//this.cdr.detectChanges();			
			}			
		});		
	}
}
