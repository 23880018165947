import { Component, OnInit, OnDestroy, Input, Inject } from "@angular/core";

@Component({
	selector: "tdata-status",
	templateUrl: "./status.html",
	styleUrls: ["./status.scss"],
})
export class TDataStatus implements OnInit, OnDestroy {
	@Input() config;
	@Input() data;

	constructor() {}
	// options: any = [{ value: "Active", key: 1}];
	selectedOption: string | null = null;

	selectStatusFiled = true;
	ngOnInit() {
	}

	getStatusText(key: any) {
		key = key.toString()
		switch (key) {
			case "1":
				return "Active";
			case "2":
				return "All";
			case "3":
				return "Requested";
			case "4":
				return "Incomplete";
			case "5":
				return "Reject";
			case "0":
				return "Inactive";
		}
	}

	getTextColur(option: string): string {
		return this.getTextClass(option);
	}

	getDotClass(option: string): string {
		return this.getColorClass(option);
	}

	private getTextClass(option: string): string {
		option = option.toString()

		switch (option) {
			case "2":
				return "text-black";
			case "1":
				return "text-blue";
			case "3":
				return "text-purpule";
			case "4":
				return "text-yellow";
			case "5":
				return "text-red";
			case "0":
				return "text-ligthgray";
			default:
				return "text-ligthgray";
		}
	}

	private getColorClass(option: string): string {
		option = option.toString()

		switch (option) {
			case "2":
				return "dot-black";
			case "1":
				return "dot-blue";
			case "3":
				return "dot-purpule";
			case "4":
				return "dot-yellow";
			case "5":
				return "dot-red";
			case "0":
				return "dot-ligthgray";
			default:
				return "";
		}
	}
	ngOnDestroy() {}
}
