import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private _updateComponentSource = new Subject<void>();

  updateComponent$ = this._updateComponentSource.asObservable();

  triggerUpdate() {
    this._updateComponentSource.next();
  }
}