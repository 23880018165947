import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  baseUrl = environment.baseURL + 'dashboard';
  constructor(private http: HttpClient) {}
 
  // tslint:disable-next-line: one-line
  getTotalDashboardData(){
    return this.http.get<any>(this.baseUrl + 'getdashboard');
  }
  getUserbyCategory(signupByCategory){
    return this.http.post(this.baseUrl + '/graphbycategory', {dateFilter:signupByCategory}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        return null;
      })
    );
  }
  
  getUserbyPayment(signupByCategory){
    return this.http.post(this.baseUrl + '/graphbypayment', {dateFilter:signupByCategory}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        return null;
      })
    );
  }  

  getArtisteProfileViews(signupByCategory, userId = null){
    return this.http.post(this.baseUrl + '/graphprofileview', {dateFilter:signupByCategory, userId:userId}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        return null;
      })
    );
  }  
  
  
}
