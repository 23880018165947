import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, ElementRef, AfterViewInit, EventEmitter, Inject, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";

import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
	selector: "accordion",
	templateUrl: "./accordion.html",
	styleUrls: ["./accordion.scss"],
})
export class Reject implements OnInit, OnDestroy {
	@Input() openAccordion;
	@Input() header;
	@Input() data;
	constructor(private toastr: ToastrService, private cdr: ChangeDetectorRef) {}
	ngOnInit() {}

	handleAccordion(){
		this.openAccordion = !this.openAccordion;
	}
	ngOnDestroy() {}
}
