// USA

const projectname = 'ATL';

export const locale = {
	lang: 'gs',
	data: {
		UPDATE: 'અપડેટ',
		LIST: 'યાદી',
		HOME: 'ઘર',
		ADD: 'ઉમેરો',
		NAME: 'નામ',
		ADSH: 'Adsh',
		CIK: 'સીઆઇકે',
		SIC: 'એસઆઇસી',
		COUNTRY: 'દેશ',
		STPRBA: 'રાજ્ય',
		CITY: 'શહેર',
		ZIP: 'પિન કોડ',
		BAS1: 'BAS 1',
		BAS2: 'BAS 2',
		COUNTRY_ID: 'આઈડી',
		Usergroup: 'વપરાશકર્તા જૂથ',
		QUESTION_TYPE: 'પ્રશ્ન પ્રકાર',
		ANSWER_INPUT: 'જવાબ ઇનપુટ',
		ANSWER_TYPE: 'જવાબ પ્રકાર',
		ANSWER_OPTIONS: 'જવાબ વિકલ્પો',
		QUESTION_TEXT: 'પ્રશ્ન ટેક્સ્ટ',
		DESCRIPTION: 'વર્ણન',
		FIRSTNAME: 'પ્રથમ નામ',
		LASTNAME: 'છેલ્લા નામ',
		EMAIL: 'ઇમેઇલ',
		PHONE: 'ફોન',
		COUNTRY_CODE: 'દેશનો કોડ',
		REGIONS: 'પ્રદેશો',
		STATES: 'રાજ્ય',
		CITIES: 'શહેર',
		LOCALE: 'સ્થાનિક',
		COUNTRIES: 'દેશો',
		VALUES: 'મૂલ્યો',
		SETTINGTYPES: 'સુયોજિત પ્રકારો',
		CONTACT_NO: 'સંપર્ક નંબર',
		USERGROUPS: 'વપરાશકર્તા જૂથ',
		USERS: 'વપરાશકર્તાઓ',
		ZONES: 'ઝોન',
		WARD: 'વોર્ડ',
		CONVENIENCES: 'જાહેર સગવડ નામ',
		TITLE: 'શીર્ષક',
		SUBJECT: 'વિષય',
		TRANSLATOR: {
			SELECT: 'તમારી ભાષા પસંદ કરો',
		},
		MENU: {
			NEW: 'નવું',
			ACTIONS: 'ક્રિયાઓ',
			CREATE_POST: 'નવી પોસ્ટ બનાવો',
			PAGES: 'પાના',
			FEATURES: 'વિશેષતા',
			APPS: 'એપ્લિકેશન્સ',
			DASHBOARD: 'ડેશબોર્ડ',
			ASSIGNMENT: 'સોંપણી સૂચિ',
			USERS: 'વપરાશકર્તાઓ',
			MASTERS: 'માસ્ટર્સ',
			MODULES : 'મોડ્યુલો',
			COMPANY: 'કંપની',
			REGION: 'પ્રદેશ',
			COUNTRY: 'દેશ',
			STATE: 'રાજ્ય',
			CITY: 'શહેર',
			DISTRICT: 'જીલ્લો',
			ZONE: 'ઝોન',
			TAG: 'ટેગ',
			LANGUAGE: 'ભાષા',
			USERMANAGEMENT: 'વપરાશકર્તા વ્યવસ્થાપન',
			USERGROUP: 'વપરાશકર્તા જૂથ',
			PACKAGE: 'પેકેજ',
			SEC: 'એસ.ઈ.સી.',
			ATLSUCCESS: 'યુએમસી સફળતા',
			PDF: 'પી.ડી.એફ.',
			FIELDGROUPMAPPING: 'ક્ષેત્ર જૂથ મેપિંગ',
			STATEMENTTYPENAME: 'વિધાન પ્રકાર',
			FIELDMAPPING: 'ફીલ્ડ મેપિંગ',
			QUESTIONNAIRETOOL: 'પ્રશ્નાવલી સાધન',
			ATLSETTING: 'વૈશ્વિક સેટિંગ',
			GLOBALSETTING: 'વૈશ્વિક સેટિંગ',
			SETTING: 'સેટિંગ',
			SETTINGTYPE: 'સેટિંગ પ્રકાર',
			ACCESSLOGS : 'પ્રવેશ લોગ',
			SURVEYMANAGEMENT: 'સર્વે મેનેજમેન્ટ',
			PUBLICCONVENIENCE: 'જાહેર સગવડ',
			EMAILTEMPLATE: 'ઇમેઇલ નમૂના',
			QUESTIONS: 'પ્રશ્નો',
			CATEGORY: 'કેટેગરી',
			MENU: 'મેનુ'
		},
		AUTH: {
			GENERAL: {
				OR: 'અથવા',
				SUBMIT_BUTTON: 'સબમિટ કરો',
				NO_ACCOUNT: 'તમારું ખાતું નથી?',
				SIGNUP_BUTTON: 'સાઇન અપ કરો',
				FORGOT_BUTTON: 'પાસવર્ડ ભૂલી ગયા છો?',
				BACK_BUTTON: 'પાછળ',
				PRIVACY: 'ગોપનીયતા',
				LEGAL: 'કાયદેસર',
				CONTACT: 'સંપર્ક કરો',
			},
			LOGIN: {
				TITLE: 'પ્રવેશ કરો',
				BUTTON: 'સાઇન ઇન',
				SUCCESSMESSAGE: 'સફળતાપૂર્વક પ્રવેશ કરો',
			},
			FORGOT: {
				TITLE: 'ભૂલી ગયા પાસવર્ડ',
				DESC: 'તમારો પાસવર્ડ ફરીથી સેટ કરવા માટે તમારું ઇમેઇલ દાખલ કરો',
				SUCCESS: 'લિંક તમારા રજિસ્ટર્ડ ઇમેઇલ પર મોકલવામાં આવી છે જેનો ઉપયોગ કરીને તમે તમારો પાસવર્ડ ફરીથી સેટ કરી શકો છો.'
			},
			SETPASSWORD: {
				TITLE: 'પાસવર્ડ સેટ કરો',
				BUTTON: 'ફરીથી સેટ કરો',
				SUCCESS: 'પાસવર્ડ સફળતાપૂર્વક બદલાઈ ગયો છે'
			},
			REGISTER: {
				TITLE: 'સાઇન અપ કરો',
				DESC: 'તમારું એકાઉન્ટ બનાવવા માટે તમારી વિગતો દાખલ કરો',
				SUCCESS: 'તમારું એકાઉન્ટ સફળતાપૂર્વક નોંધાયેલું છે.'
			},
			INPUT: {
				EMAIL: 'ઇમેઇલ',
				FULLNAME: 'પૂરું નામ',
				PASSWORD: 'પાસવર્ડ',
				CONFIRM_PASSWORD: 'પાસવર્ડની પુષ્ટિ કરો',
				USERNAME: 'વપરાશકર્તા નામ',
				TITLE: 'શીર્ષક દાખલ કરો',
				REMEMBER_ME: 'મને યાદ રાખો',
			},
			VALIDATION: {
				INVALID: '{{name}} માન્ય નથી',
				REQUIRED: '{{name}} જરૂરી છે',
				MIN_LENGTH: '{{name}} લઘુત્તમ લંબાઈ છે {{min}}',
				AGREEMENT_REQUIRED: 'નિયમો અને શરતો સ્વીકારવી જરૂરી છે',
				NOT_FOUND: 'વિનંતી {{name}} મળ્યું નથી',
				INVALID_LOGIN: 'આ પ્રવેશ વિગતવાર ખોટું છે',
				REQUIRED_FIELD: 'આવશ્યક ક્ષેત્ર',
				MIN_LENGTH_FIELD: 'ન્યૂનતમ ક્ષેત્ર લંબાઈ:',
				MAX_LENGTH_FIELD: 'મહત્તમ ક્ષેત્રની લંબાઈ:',
				INVALID_FIELD: 'ક્ષેત્ર માન્ય નથી',
				MUST_MATCH: 'પાસવર્ડ અને પુષ્ટિ પાસવર્ડ મેચ કરવો આવશ્યક છે'
			}
		},
		PAGES: {
			MODULES: {
				LISTINGTITLE: projectname + ' |મોડ્યુલ યાદી',
				ADDTITLE: projectname + ' |મોડ્યુલ ઉમેરો',
				EDITTITLE: projectname + ' |મોડ્યુલ અપડેટ',
				MODULESLIST : 'મોડ્યુલો યાદી',
				MODULENAMEPLURAL : 'મોડ્યુલો',
				MODULENAMESINGULAR : 'મોડ્યુલ',
				NEWMODULE : 'નવું મોડ્યુલ',
				DELETEDSELECTED : 'પસંદ કરેલ મોડ્યુલ કાઢી નાંખો',
				UPDATESELECTED : 'પસંદ કરેલ મોડ્યુલો માટે સ્થિતિ અપડેટ કરો',
				ADDNEW : 'નવું મોડ્યુલ ઉમેરો',
				UPDATE : 'મોડ્યુલ અપડેટ કરો',
				CREATEMESSAGE : 'સફળતાપૂર્વક મોડ્યુલ બનાવ્યું',
				UPDATEMESSAGE : 'મોડ્યુલ સફળતાપૂર્વક અપડેટ કરો',
				EDIT: 'મોડ્યુલ સંપાદિત કરો',
				ADD: 'મોડ્યુલ ઉમેરો',
			},
			REGION: {
				LISTINGTITLE: projectname + ' |પ્રદેશ યાદી',
				ADDTITLE: projectname + ' |પ્રદેશ ઉમેરો',
				EDITTITLE: projectname + ' |પ્રદેશ અપડેટ',
				REGIONLIST : 'પ્રદેશોની સૂચિ',
				REGIONNAMEPLURAL : 'પ્રદેશો',
				REGIONNAMESINGULAR : 'ક્ષેત્ર',
				NEWREGION : 'નવો પ્રદેશ',
				DELETEDSELECTED : 'પસંદ કરેલા પ્રદેશો કાઢી નાંખો',
				UPDATESELECTED : 'પસંદ કરેલા પ્રદેશો માટે સ્થિતિ અપડેટ કરો',
				ADDNEW : 'નવો પ્રદેશ ઉમેરો',
				UPDATE : 'અપડેટ ક્ષેત્ર',
				CREATEMESSAGE : 'સફળતાપૂર્વક પ્રદેશ બનાવ્યું',
				UPDATEMESSAGE : 'ક્ષેત્ર અપડેટ કારીયુ',
				EDIT: 'પ્રદેશ સંપાદિત કરો',
				ADD: 'ઉમેરો પ્રદેશ',
				REGION_NAME: 'પ્રદેશ',
				REGION_ERROR: 'કૃપા કરીને પ્રદેશનો ઉલ્લેખ કરો',
				PATTERN: {
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
				}
			},
			COUNTRY: {
				LISTINGTITLE: projectname + ' |દેશની સૂચિ ',
				ADDTITLE: projectname + ' |દેશ ઉમેરો ',
				EDITTITLE: projectname + ' |દેશ અપડેટ ',
				COUNTRYLIST : 'દેશોની સૂચિ',
				COUNTRYNAMEPLURAL : 'દેશો',
				COUNTRYNAMESINGULAR : 'દેશ',
				NEWCOUNTRY : 'ન્યુ કન્ટ્રી',
				DELETEDSELECTED : 'પસંદ કરેલા દેશો કાઢી નાખો',
				UPDATESELECTED : 'પસંદ કરેલા દેશો માટે સ્થિતિ અપડેટ કરો',
				ADDNEW : 'નવો દેશ ઉમેરો',
				UPDATE : 'અપડેટ કન્ટ્રી',
				CREATEMESSAGE : 'દેશ સફળતાપૂર્વક બનાવો',
				UPDATEMESSAGE : 'દેશ સફળતાપૂર્વક અપડેટ કરો',
				EDIT: 'સંપાદિત દેશ',
				ADD: 'દેશ ઉમેરો',
				COUNTRY_CODE: 'દેશનો કોડ',
				COUNTRY_NAME: 'દેશ',
				COUNTRY_ERROR: 'મહેરબાની કરીને દેશનો ઉલ્લેખ કરો',
				PATTERN: {
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]*\\)?)?[^0-9_\\- \\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
				}
			},
			SETTING: {
				LISTINGTITLE: projectname + ' |સેટિંગ સૂચિ ',
				ADDTITLE: projectname + ' |એડ સેટ કરો ',
				EDITTITLE: projectname + ' |સુધારા સુયોજિત ',
				SETTINGLIST : 'સેટિંગ્સ સૂચિ',
				SETTINGNAMEPLURAL : 'સેટિંગ્સ',
				SETTINGNAMESINGULAR : 'સેટિંગ',
				NEWSETTING : 'નવી સેટિંગ',
				DELETEDSELECTED : 'પસંદ કરેલી સેટિંગ્સ કાઢી નાખો',
				UPDATESELECTED : 'પસંદ કરેલી સેટિંગ્સ માટે સ્થિતિ અપડેટ કરો',
				ADDNEW : 'નવી સેટિંગ ઉમેરો',
				UPDATE : 'અપડેટ સેટિંગ',
				CREATEMESSAGE : 'સફળતાપૂર્વક સેટિંગ બનાવો',
				UPDATEMESSAGE : 'અપડેટ સેટિંગ સફળતાપૂર્વક',
				EDIT: 'એડિટિંગ સેટિંગ',
				ADD: 'એડ સેટિંગ',
				VALUES: 'મૂલ્યો',
				VALIDATION: {
					SELECTTYPE: 'કૃપા કરી સેટિંગ પ્રકાર પસંદ કરો'
				}
			},
			SETTINGTYPE: {
				LISTINGTITLE: projectname + ' |સુયોજિત પ્રકાર સૂચિ ',
				ADDTITLE: projectname + ' |સેટિંગ ટાઇપ એડ ',
				EDITTITLE: projectname + ' |સેટિંગ ટાઇપ અપડેટ ',
				SETTINGTYPELIST : 'સેટિંગ્સ પ્રકાર સૂચિ',
				SETTINGTYPENAMEPLURAL : 'સેટિંગ પ્રકારો',
				SETTINGTYPENAMESINGULAR : 'સેટિંગ ટાઇપ',
				NEWSETTINGTYPE : 'નવું સેટિંગ પ્રકાર',
				DELETEDSELECTED : 'પસંદ કરેલા સેટિંગ પ્રકારોને કાઢી નાખો',
				UPDATESELECTED : 'પસંદ કરેલા સેટિંગ પ્રકારો માટે સ્થિતિ અપડેટ કરો',
				ADDNEW : 'નવું સેટિંગ પ્રકાર ઉમેરો',
				UPDATE : 'અપડેટ સેટિંગ ટાઇપ',
				CREATEMESSAGE : 'સેટિંગ પ્રકાર સફળતાપૂર્વક બનાવો',
				UPDATEMESSAGE : 'અપડેટ સેટિંગ પ્રકાર સફળતાપૂર્વક',
				EDIT: 'એડિટિંગ સેટિંગ ટાઇપ',
				ADD: 'સેટિંગ પ્રકાર ઉમેરો',
				VALIDATION: {
					SELECTTYPE: 'કૃપા કરી પ્રકાર પસંદ કરો'
				}
			},
			STATE: {
				LISTINGTITLE: projectname + ' |રાજ્ય સૂચિ ',
				ADDTITLE: projectname + ' |રાજ્ય ઉમેરો ',
				EDITTITLE: projectname + ' |રાજ્ય સુધારો ',
				STATELIST : 'સ્ટેટ્સ લિસ્ટ',
				STATENAMEPLURAL : 'સ્ટેટ્સ',
				STATENAMESINGULAR : 'રાજ્ય',
				NEWSTATE : 'નવું રાજ્ય',
				DELETEDSELECTED : 'પસંદ કરેલા દેશો કાઢી નાખો',
				UPDATESELECTED : 'પસંદ કરેલા દેશો માટે સ્થિતિ અપડેટ કરો',
				ADDNEW : 'નવું રાજ્ય ઉમેરો',
				UPDATE : 'અપડેટ સ્ટેટ',
				CREATEMESSAGE : 'રાજ્ય સફળતાપૂર્વક બનાવો',
				UPDATEMESSAGE : 'રાજ્ય સફળતાપૂર્વક અપડેટ કરો',
				EDIT: 'રાજ્ય સંપાદિત કરો',
				ADD: 'રાજ્ય ઉમેરો',
				STATE_NAME: 'રાજ્ય',
				STATE_ERROR: 'કૃપા કરીને રાજ્ય સ્પષ્ટ કરો',
				DRAG_FROM_LEFT: 'રેકોર્ડ ઉમેરવા માટે ડાબી બાજુથી ખેંચો અને નીચે છોડો',
				PATTERN: {
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
				}
			},
			TEMPLATE: {
				LISTINGTITLE: projectname + ' | ઇમેઇલ નમૂના સૂચિ ',
				ADDTITLE: projectname + ' | ઇમેઇલ નમૂના ઉમેરો ',
				EDITTITLE: projectname + ' | ઇમેઇલ નમૂના અપડેટ ',
				TEMPLATELIST : 'ઇમેઇલ નમૂનાઓ સૂચિ',
				TEMPLATENAMEPLURAL: 'ઇમેઇલ નમૂનાઓ',
				TEMPLATENAMESINGULAR: 'ઇમેઇલ નમૂના',
				NEWTEMPLATE: 'નવું ઇમેઇલ નમૂના',
				DELETEDSELECTED : 'પસંદ કરેલા દેશો કા Deleteી નાખો',
				UPDATESELECTED : 'પસંદ કરેલા દેશો માટે સ્થિતિ અપડેટ કરો',
				ADDNEW: 'નવું ઇમેઇલ નમૂના ઉમેરો',
				UPDATE: 'અપડેટ ઇમેઇલ નમૂના',
				CREATEMESSAGE: 'ઇમેઇલ નમૂના સફળતાપૂર્વક બનાવો',
				UPDATEMESSAGE: 'ઇમેઇલ નમૂના સફળતાપૂર્વક અપડેટ કરો',
				EDIT: 'એડિટ ઇમેઇલ નમૂના',
				ADD: 'ઇમેઇલ નમૂના ઉમેરો',
				TEMPLATE_NAME: 'ઇમેઇલ નમૂના',
				TEMPLATE_ERROR: 'કૃપા કરીને ઇમેઇલ નમૂના સ્પષ્ટ કરો',
				DRAG_FROM_LEFT: 'રેકોર્ડ ઉમેરવા માટે ડાબેથી ખેંચો અને નીચે છોડો',
				TITLE : 'શીર્ષક',
				PATTERN: {
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
				}
			},
			CITY: {
				LISTINGTITLE: projectname + ' |શહેરની સૂચિ ',
				ADDTITLE: projectname + ' |શહેર ઉમેરો ',
				EDITTITLE: projectname + ' |સિટી અપડેટ ',
				CITYLIST : 'શહેરોની સૂચિ',
				CITYNAMEPLURAL : 'શહેરો',
				CITYNAMESINGULAR : 'શહેર',
				NEWCITY : 'નવું શહેર',
				DELETEDSELECTED : 'પસંદ કરેલા દેશો કાઢી નાખો',
				UPDATESELECTED : 'પસંદ કરેલા દેશો માટે સ્થિતિ અપડેટ કરો',
				ADDNEW : 'નવું શહેર ઉમેરો',
				UPDATE : 'અપડેટ સિટી',
				CREATEMESSAGE : 'સફળતાપૂર્વક શહેર બનાવો',
				UPDATEMESSAGE : 'શહેર સફળતાપૂર્વક અપડેટ કરો',
				EDIT: 'એડિટ સિટી',
				ADD: 'શહેર ઉમેરો',
			},
			CONVENIENCE: {
				LISTINGTITLE: projectname + ' | જાહેર સુવિધાની સૂચિ',
				ADDTITLE: projectname + ' | જાહેર સુવિધાયુક્ત ઉમેરો',
				EDITTITLE: projectname + ' | જાહેર સગવડ અપડેટ',
				CONVENIENCELIST: 'જાહેર સગવડ સૂચિ',
				CONVENIENCENAMEPLURAL: 'જાહેર સગવડતા',
				CONVENIENCENAMESINGULAR: 'જાહેર સગવડતા',
				NEWCONVENIENCE: 'નવી જાહેર સગવડતા',
				DELETEDSELECTED: 'પસંદ કરેલા દેશો કાઢી નાખો',
				UPDATESELECTED: 'પસંદ કરેલા દેશો માટે સ્થિતિ અપડેટ કરો',
				ADDNEW: 'નવી જાહેર સગવડ ઉમેરો',
				UPDATE: 'જાહેર સગવડ અપડેટ કરો',
				CREATEMESSAGE: 'જાહેર સગવડ સફળતાપૂર્વક બનાવો',
				UPDATEMESSAGE: 'જાહેર સગવડતાને સફળતાપૂર્વક અપડેટ કરો',
				EDIT: 'જાહેર સગવડમાં ફેરફાર કરો',
				ADD: 'જાહેર સગવડ ઉમેરો',
			},
			DISTRICT: {
				LISTINGTITLE: projectname + ' | જિલ્લા યાદી',
				ADDTITLE: projectname + ' | જિલ્લા એડ',
				EDITTITLE: projectname + ' | જિલ્લા સુધારો',
				DISTRICTLIST: 'જિલ્લાઓની સૂચિ',
				DISTRICTNAMEPLURAL: 'જિલ્લાઓ',
				DISTRICTNAMESINGULAR: 'જીલ્લા',
				NEWDISTRICT : 'નવો જિલ્લો',
				DELETEDSELECTED: 'પસંદ કરેલા જિલ્લાઓ કાઢી નાખો',
				UPDATESELECTED: 'પસંદ કરેલા જિલ્લાઓ માટે સ્થિતિ અપડેટ કરો',
				ADDNEW : 'ન્યુ જિલ્લો ઉમેરો',
				UPDATE : 'અપડેટ ડિસ્ટ્રિક્ટ',
				CREATEMESSAGE : 'જીલ્લા સફળતાપૂર્વક બનાવો',
				UPDATEMESSAGE : 'જિલ્લા સફળતાપૂર્વક અપડેટ કરો',
				EDIT: 'એડિટ ડિસ્ટ્રિક્ટ',
				ADD: 'જિલ્લા ઉમેરો',
			},
			SURVEY: {
				LISTINGTITLE: projectname + ' | સર્વે યાદી',
				SURVEYLIST : 'સર્વે યાદી',
				NEWSURVEY : 'નવો સર્વે',
				SURVEYNAMEPLURAL : 'સર્વે',
				SURVEYNAMESINGULAR : 'સર્વે',
				DELETEDSELECTED : 'પસંદ કરેલા સર્વેયરને કાઢી નાખો',
				UPDATESELECTED : 'પસંદ કરેલા સર્વેયર માટે સ્થિતિ અપડેટ કરો',
				ADDNEW : 'નવો સર્વે ઉમેરો',
				UPDATE : 'અપડેટ સર્વે',
				PASSWORD: 'પાસવર્ડ',
				CONFIRMPASSWORD: 'પાસવર્ડની પુષ્ટિ કરો',
				SURVEYSUCCESS: 'સર્વે સફળતાપૂર્વક ઉમેર્યો',
				FILTERSURVEYGROUP: 'ફિલ્ટર સર્વે ગ્રુપ',
				ADDTITLE: projectname + ' | સર્વેયર ઉમેરો',
				EDITTITLE: projectname + ' | સર્વેયર અપડેટ',
				EDIT: 'સર્વેક્ષણ સંપાદિત કરો',
				ADD: 'સર્વે ઉમેરો',
			},
			ZONE: {
				LISTINGTITLE: projectname + ' | ઝોન યાદી',
				ADDTITLE: projectname + ' | ઝોન ઉમેરો',
				EDITTITLE: projectname + ' | ઝોન અપડેટ',
				ZONELIST: 'ઝોન યાદી',
				ZONENAMEPLURAL: 'ઝોન',
				ZONENAMESINGULAR: 'ઝોન',
				NEWZONE : 'નવું ઝોન',
				DELETEDSELECTED: 'પસંદ કરેલ ઝોન કાઢી નાખો',
				UPDATESELECTED: 'પસંદ કરેલા ઝોન માટે સ્થિતિ અપડેટ કરો',
				ADDNEW : 'ન્યુ ઝોન ઉમેરો',
				UPDATE : 'અપડેટ ઝોન',
				CREATEMESSAGE : 'સફળતાપૂર્વક ઝોન બનાવો',
				UPDATEMESSAGE : 'ઝોન સફળતાપૂર્વક અપડેટ કરો',
				EDIT: 'એડિટ ઝોન',
				ADD: 'ઝોન ઉમેરો',
			},
			LANGUAGE: {
				LISTINGTITLE: projectname + ' |ભાષા સૂચિ ',
				ADDTITLE: projectname + ' |ભાષા ઉમેરો ',
				EDITTITLE: projectname + ' |ભાષા અપડેટ ',
				LANGUAGELIST : 'ભાષાઓની સૂચિ',
				LANGUAGENAMEPLURAL : 'ભાષાઓ',
				LANGUAGENAMESINGULAR : 'ભાષા',
				NEWLANGUAGE : 'નવી ભાષા',
				DELETEDSELECTED : 'પસંદ કરેલી ભાષાઓ કાઢી નાખો',
				UPDATESELECTED : 'પસંદ કરેલી ભાષાઓ માટે સ્થિતિ અપડેટ કરો',
				ADDNEW : 'નવી ભાષા ઉમેરો',
				UPDATE : 'અપડેટ લેંગ્વેજ',
				CREATEMESSAGE : 'ભાષા સફળતાપૂર્વક બનાવો',
				UPDATEMESSAGE : 'ભાષા સફળતાપૂર્વક અપડેટ કરો',
				EDIT: 'ભાષા સંપાદિત કરો',
				ADD: 'ભાષા ઉમેરો',
				LOCALE: 'સ્થાનિક',
				LOCALE_REQUIRED: 'લોકેલ આવશ્યક છે',
				LOCALE_INVALID: 'લોકેલમાં અમાન્ય અક્ષરો છે',
			},
			USERS: {
				LISTINGTITLE: projectname + ' |વપરાશકર્તા સૂચિ ',
				USERSLIST : 'વપરાશકર્તાઓની સૂચિ',
				NEWUSER : 'નવા વપરાશકર્તા',
				USERNAMEPLURAL : 'વપરાશકર્તાઓ',
				USERNAMESINGULAR : 'વપરાશકર્તા',
				DELETEDSELECTED : 'પસંદ કરેલા વપરાશકર્તાઓને કાઢી નાખો',
				UPDATESELECTED : 'પસંદ કરેલા વપરાશકર્તાઓ માટે સ્થિતિ અપડેટ કરો',
				ADDNEW : 'નવો વપરાશકર્તા ઉમેરો',
				UPDATE : 'અપડેટ યુઝર',
				PASSWORD: 'પાસવર્ડ',
				CONFIRMPASSWORD: 'પાસવર્ડની પુષ્ટિ કરો',
				USERSUCCESS: 'વપરાશકર્તા સફળતાપૂર્વક ઉમેર્યા',
				FILTERUSERGROUP: 'ફિલ્ટર વપરાશકર્તા જૂથ',
				ADDTITLE: projectname + ' |વપરાશકર્તાઓ ઉમેરો ',
				EDITTITLE: projectname + ' |વપરાશકર્તાઓ અપડેટ કરો ',
				EDIT: 'એડિટ યુઝર',
				ADD: 'વપરાશકર્તા ઉમેરો',
			},
			USERGROUP: {
				LISTINGTITLE: projectname + ' |વપરાશકર્તા જૂથ સૂચિ ',
				USERGROUPLIST : 'યુઝર ગ્રુપ લિસ્ટ',
				USERGROUPNAMEPLURAL : 'વપરાશકર્તા જૂથો',
				USERGROUPNAMESINGULAR : 'વપરાશકર્તા જૂથ',
				NEWUSERGROUP : 'નવું યુઝર ગ્રુપ',
				DELETEDSELECTED : 'પસંદ કરેલા વપરાશકર્તા જૂથો કાઢી નાખો',
				UPDATESELECTED : 'પસંદ કરેલા વપરાશકર્તા જૂથો માટે સ્થિતિ અપડેટ કરો',
				ADDNEW : 'નવું વપરાશકર્તા જૂથ ઉમેરો',
				UPDATE : 'અપડેટ યુઝર ગ્રુપ',
				ADDTITLE : projectname + ' |વપરાશકર્તા જૂથ ઉમેરો ',
				EDITTITLE : projectname + ' |વપરાશકર્તા જૂથ અપડેટ કરો ',
			},
			QUESTION: {
				LISTINGTITLE: projectname + ' | પ્રશ્ન સૂચિ',
				ADDTITLE: projectname + ' | પ્રશ્ન ઉમેરો',
				EDITTITLE: projectname + ' | પ્રશ્ન સુધારો',
				QUESTIONLIST: 'પ્રશ્નોની સૂચિ',
				QUESTIONNAMEPLURAL: 'પ્રશ્નો',
				QUESTIONNAMESINGULAR: 'પ્રશ્ન',
				NEWQUESTION : 'નવો પ્રશ્ન',
				DELETEDSELECTED: 'પસંદ કરેલા પ્રશ્નો કાઢી નાખો',
				UPDATESELECTED: 'પસંદ કરેલા પ્રશ્નો માટે સ્થિતિ અપડેટ કરો',
				ADDNEW : 'નવો પ્રશ્ન ઉમેરો',
				UPDATE : 'અપડેટ પ્રશ્ન',
				CREATEMESSAGE : 'સફળતાપૂર્વક પ્રશ્ન બનાવો',
				UPDATEMESSAGE : 'પ્રશ્ન સફળતાપૂર્વક અપડેટ કરો',
				EDIT: 'પ્રશ્ન સંપાદિત કરો',
				ADD: 'પ્રશ્ન ઉમેરો',
				SINGLE_CHOICE: 'સિંગલ ચોઇસ',
				MULTIPLE_CHOICE: 'બહુવૈીકલ્પિક',
				QUESTION_IMAGES: 'પ્રશ્ન છબીઓ',
				ANSWER_LIMIT: 'જવાબ મર્યાદા',
				CHOICE: 'ચોઇસ',
				NUMERIC: 'આંકડાકીય',
				DATE: 'તારીખ',
				TEXT : 'ટેક્સ્ટ',
				IMAGE: 'છબી',
				BOTH: 'બંને',
				MULTIPLE_ANSWER: 'બહુવિધ જવાબ',
				MULTIPLE_LABLE_MULTIPLE_ANSWER: 'મલ્ટીપલ લેબલ મલ્ટીપલ જવાબ',
				ANSWER_INPUT: 'જવાબ ઇનપુટ',
				ATTACH_FILES: 'ફાઇલો જોડો',
				QUESTION_TEXT: 'પ્રશ્ન ટેક્સ્ટ',
				ANSWER_TYPE: 'જવાબ પ્રકાર',
				ANSWER_IMAGE: 'જવાબ છબી',
				MIN_RANGE: 'નાની રેન્જ',
				MAX_RANGE: 'મોટામોમટી રેન્જ',
				ANSWER: 'જવાબ',
				QUESTION: 'પ્રશ્ન',
				SEQUENCE: 'સિક્વન્સ',
				ENTER_SEQUENCE: 'કૃપા કરી ક્રમ દાખલ કરો',
				INVALID_SEQUENCE: 'અમાન્ય ક્રમ',
				ADD_OPTION: 'ઉમેરો',
				ADD_MAXIMUM_OF: 'તમે વધુમાં વધુ ઉમેરી શકો',
				ENTER_ANSWER: 'મહેરબાની કરીને જવાબ દાખલ કરો',
				INVALID_ANSWER: 'અમાન્ય જવાબ',
				EXCLUSIVE_RESPONSE: 'પરસ્પર એક્સક્લુઝિવ રિસ્પોન્સ',
				ENTER_MAXIMUM_RANGE: 'કૃપા કરીને મહત્તમ શ્રેણી દાખલ કરો',
				Maximum_number_minimum_number: 'મહત્તમ સંખ્યા લઘુત્તમ સંખ્યા કરતા ઓછી નથી',
				ENTER_MINIMUM_RANGE: 'કૃપા કરી ન્યૂનતમ શ્રેણી દાખલ કરો',
				INVALID_RANGE: 'અમાન્ય શ્રેણી (આંકડાકીય મૂલ્ય, લઘુત્તમ લંબાઈ 1 અને મહત્તમ લંબાઈ 5 છે)',
				PLEASEENTERQUESTION: 'કૃપા કરી પ્રશ્ન દાખલ કરો',
				INVALID_QUESTION: 'અમાન્ય પ્રશ્ન',
				QUESTIONMUSTBEATLEAST: 'પ્રશ્ન ઓછામાં ઓછો 3 અક્ષરો લાંબુ હોવો જોઈએ અને જગ્યાની મંજૂરી નથી',
				MAX_UPLOAD_SIZE: 'મહત્તમ અપલોડ કદ 5MB છે. માન્ય ફાઇલ પ્રકારો છે jpg, png, jpeg. તમે મહત્તમ પ્રશ્ન છબી અપલોડ કરી શકો છો કદ 228px * 200px હોવું આવશ્યક છે',
				MAX_UPLOAD_SIZE_ADD: 'મહત્તમ અપલોડ કદ 5MB છે. માન્ય ફાઇલ પ્રકારો છે jpg, png, jpeg. છબીનું કદ 228px * 200px હોવું જોઈએ',
				REVISION_FOR_THIS_QUESTION: 'શું તમે આ પ્રશ્ન માટે સંશોધન બનાવવા માંગો છો?',
				CREATEMAPPINGMESSAGE : 'સવાલ મેપિંગ સફળતાપૂર્વક બનાવો',
				UPDATEMAPPINGMESSAGE : 'પ્રશ્ન મેપિંગને સફળતાપૂર્વક અપડેટ કરો',
				DELETEMAPPINGMESSAGE : 'પ્રશ્ન મેપિંગને સફળતાપૂર્વક કાઢી નાખો',
				UPLOAD_ONLY: 'તમે ફક્ત અપલોડ કરી શકો છો',
				CONDITIONISMAPPED: 'શરત મેપ થયેલ હોવાથી તમે ઓર્ડર બદલી શકતા નથી!!',
			},
			MENU: {
				ADDNEWMENU : 'નવું મેનુ ઉમેરો',
				LISTINGTITLE: projectname + ' | મેનુ સૂચિ',
				ADDTITLE: projectname + ' | મેનુ ઉમેરો',
				EDITTITLE: projectname + ' | મેનુ અપડેટ',
				MENULIST : 'મેનૂ સૂચિ',
				MENUNAMEPLURAL : 'મેનુ',
				MENUNAMESINGULAR : 'મેનુ',
				NEWMENU : 'નવું મેનૂ',
				DELETEDSELECTED : 'પસંદ કરેલા મેનૂઝ કાઢી નાખો',
				UPDATESELECTED : 'પસંદ કરેલા મેનૂઝ માટે સ્થિતિ અપડેટ કરો',
				ADDNEW : 'નવું મેનૂ ઉમેરો',
				UPDATE : 'અપડેટ મેનૂ',
				CREATEMESSAGE : 'મેનુ સફળતાપૂર્વક બનાવો',
				UPDATEMESSAGE : 'મેનુ સફળતાપૂર્વક અપડેટ કરો',
				EDIT: 'મેનુ સંપાદિત કરો',
				ADD: 'મેનુ ઉમેરો',
				VALUES: 'મૂલ્યો',
				PARENT: 'પેરેંટ',
				MODULE: 'મોડ્યુલ',
				PAGE: 'પૃષ્ઠ',
				ICON: 'ચિહ્ન',
				ORDERING: 'ઓર્ડરિંગ',
				VALIDATION: {
					SELECTTYPE: 'કૃપા કરી મેનુ પ્રકાર પસંદ કરો'
				}
			},
			ACCESSLOGS: {
				LISTINGTITLE: projectname + ' | પ્રવેશ લોગ   સૂચિ ',
				ADDTITLE: projectname + ' | પ્રવેશ લોગ ઉમેરો ',
				EDITTITLE: projectname + ' | પ્રવેશ લોગ અપડેટ ',
				ACCESSLOGSLIST : 'પ્રવેશ લોગ યાદી',
				ACCESSLOGSNAMEPLURAL : 'પ્રવેશ લોગ',
				ACCESSLOGSNAMESINGULAR : 'પ્રવેશ લોગ',
				NEWACCESSLOGS : 'નવું પ્રવેશ લોગ',
				DELETEDSELECTED : 'પસંદ પ્રવેશ લોગ કાઢી નાખો',
				UPDATESELECTED : 'પસંદ કરેલ માટે અપડેટ સ્થિતિ પ્રવેશ લોગ',
				ADDNEW : 'પ્રવેશ લોગ નવો ઉમેરો',
				UPDATE : 'પ્રવેશ લોગ અપડેટ',
				CREATEMESSAGE : 'પ્રવેશ લોગ બનાવો સફળતાપૂર્વક',
				UPDATEMESSAGE : 'પ્રવેશ લોગ સફળતાપૂર્વક અપડેટ કરો',
				EDIT: 'પ્રવેશ લોગ સંપાદિત કરો',
				ADD: 'પ્રવેશ લોગ ઉમેરો',
				VALUES: 'મૂલ્યો',
				PARENT: 'પિતૃ',
				MODULE: 'મોડ્યુલ',
				PAGE: 'પાનું',
				ICON: 'ચિહ્ન',
				ORDERING: 'ઓર્ડર',
				VALIDATION: {
					SELECTTYPE: 'કૃપા કરી પસંદ કરો પ્રવેશ લોગ પ્રકાર'
				}
			},
			COMMON: {
				HOME: 'ઘર',
				ALL: 'બધા',
				ACTIVE: 'સક્રિય',
				INACTIVE: 'નિષ્ક્રિય',
				PLEASESELECT: 'કૃપા કરીને પસંદ કરો',
				BASICINFORMATION: 'મૂળભૂત માહિતી',
				COMPANYMANAGEMENT: 'કંપની મેનેજમેન્ટ',
				COMPANYREGISTEARS: 'કંપની રજિસ્ટ્રાર્સ',
				CREATEDAT: 'બનાવેલી તારીખ',
				STATUS: 'સ્થિતિ',
				ACTION: 'એક્શન',
				DELETE: 'કાઢી નાંખો',
				TITLE: 'શીર્ષક',
				VIEW: 'જુઓ',
				UPDATE: 'સંપાદિત કરો',
				PARENT: 'પેરેંટ',
				PIC: 'પીક',
				SEARCHBTN: 'શોધ',
				FIRSTNAME: 'પ્રથમ નામ',
				FIRSTNAMEREQ: 'પ્રથમ નામ આવશ્યક છે',
				FIRSTNAMEERROR: 'પ્રથમ નામ અમાન્ય છે',
				NAME: 'નામ',
				LASTNAME: 'છેલ્લું નામ',
				LASTNAMEREQ: 'છેલ્લું નામ આવશ્યક છે',
				LASTNAMEERROR: 'છેલ્લું નામ અમાન્ય છે',
				EMAIL: 'ઇમેઇલ',
				EMAILREQ: 'ઇમેઇલ આવશ્યક છે',
				EMAILERROR: 'ઇમેઇલ અમાન્ય છે',
				WEBSITE: 'વેબસાઇટ',
				WEBSITEREQ: 'વેબસાઇટ આવશ્યક છે',
				WEBSITEERROR: 'વેબસાઇટ અમાન્ય છે',
				FAX: 'ફેક્સ',
				FAXERROR: 'ફેક્સ અમાન્ય છે',
				ADDRESS: 'સરનામું',
				MOBILENUMBER: 'મોબાઇલ નંબર',
				DATEOFBIRTH: 'જન્મ તારીખ',
				DATEOFBIRTHERROR: 'જન્મ તારીખ આવશ્યક છે.',
				SUBMITBTN: 'સેવ',
				SEARCH: 'શોધ',
				BACKBTN: 'પાછળ',
				UPDATEBTN: 'અપડેટ',
				RESETBTN: 'રીસેટ',
				CANCELBTN: 'રદ કરો',
				REPORT: 'અહેવાલ',
				CITY: 'સિટી',
				COUNTRY: 'દેશ',
				CODE: 'કોડ',
				SELECTCOUNTRY: 'દેશ પસંદ કરો',
				SELECTREGION: 'પસંદ કરો પ્રદેશ',
				SELECTSTATE: 'રાજ્ય પસંદ કરો',
				SELECTCITY: 'સિલેકટ સિટી',
				STATE: 'રાજ્ય',
				PIN: 'પિન',
				ZIPCODE: 'પિન કોડ',
				NORECORDS : 'કોઈ રેકોર્ડ (ઓ) મળ્યો નથી',
				DELETEALL : 'બધું કાઢી નાંખો',
				UPDATESTATUS : 'અપડેટ સ્ટેટસ',
				DESCRIPTION: 'વર્ણન',
				DESIGNATION: 'હોદ્દો',
				ENTERDESCRIPTION: 'મહેરબાની કરીને વર્ણન દાખલ કરો',
				STATUSUPDATED: 'સ્થિતિ સફળતાપૂર્વક અપડેટ કરવામાં આવી છે',
				SUCCESS: 'સફળતાપૂર્વક',
				SELECTED: 'પસંદ કરેલું',
				STATUSSELECTEDUPDATE: 'સ્થિતિ પસંદ કરવા માટે અપડેટ કરવામાં આવી છે',
				PERMANTLYDELETE: 'શું તમને ખાતરી છે કે આ કાયમીરૂપે કાઢી નાખશે',
				ISDELETING: 'ડિલીટ કરી રહ્યું છે',
				DELETESELECTED: 'પસંદ કરેલા કાઢી નાંખો',
				UPDATESELECTED: 'અપડેટ સ્ટેટસ ફોર સિલેક્ટેડ',
				AMOUNT: 'રકમ',
				TIME: 'સમય',
				VALUE: 'કિંમત',
				USERGROUP: 'યુઝર ગ્રુપ',
				SELECT: 'પસંદ કરો',
				VERSION: 'સંસ્કરણ',
				TYPE_THREE_LETTERS: 'ત્રણ અક્ષરો લખો અને થોડો સમય રાહ જુઓ',
				USERGROUPREQUIRED: 'વપરાશકર્તા જૂથ આવશ્યક છે',
				PERMISSION: 'પરવાનગી',
				LABELSEARCH: 'અહીં ટાઇપ લેબલ',
				SELECTANYONE: 'કોઈપણ પસંદ કરો',
				PASSWORDHINT: '8-10 અક્ષરો, ઓછામાં ઓછું એક સંખ્યા, ઓછામાં ઓછું એક અપર કેરેક્ટર, ઓછામાં ઓછું એક લોઅર કેરેક્ટર, ઓછામાં ઓછું એક સ્પેશ્યલ કેરેક્ટર સમાવે છે.',
				OOPS: 'અરેરે!',
				SUBJECT: 'વિષય',
				ERROR: 'ભૂલ!',
				SELECTUSERGROUP: 'વપરાશકર્તા જૂથ પસંદ કરો',
				NEXT: 'આગળ',
				SELECTZONE: 'કૃપા કરી ઝોન પસંદ કરો',
				SELECTWARD: 'મહેરબાની કરીને વોર્ડ પસંદ કરો',
				WARD: 'વોર્ડ',
				SELECTCONVENIENCE: 'કૃપા કરીને જાહેર સગવડતા પસંદ કરો',
				PREVIOUS: 'અગાઉના',
				SETTINGTYPE: 'સેટિંગ પ્રકાર',
				SELECTDATE: 'કૃપા કરી તારીખ પસંદ કરો',
				ENTERVALUE: 'કૃપા કરી કિંમત દાખલ કરો',
				UPLOADIMAGE: 'કૃપા કરીને છબી અપલોડ કરો',
				HELLO: 'હાય!',
				WELCOMEBACK: 'પાછા સ્વાગત છે',
				QUESTIONTEXT: 'એવું લાગે છે કે તમે અગાઉની ભાગીદારી પૂર્ણ કરી શક્યા નથી. તમે જ્યાંથી ગયા ત્યાંથી ચાલુ રાખવા માંગો છો અથવા તમે નવો સર્વેક્ષણ પ્રશ્ન શરૂ કરવા માંગો છો?',
				CONTINUEWITH: 'સાથે ચાલુ રાખો',
				OR: 'અથવા',
				PREVIOUSQUESTION: 'પાછલો પ્રશ્ન',
				FRESHQUESTION: 'નવો પ્રશ્ન',
				CONFIRM: 'પુષ્ટિ કરો',
				AREYOUSURE: 'શું તમને ખાતરી છે કે પહેલા શરૂઆત થશે?',
				YES: 'હા',
				NO: 'ના',
				FILTERCATEGORY: 'ફિલ્ટર કેટેગરી',
				ALLCLUSTERS: 'બધા ક્લસ્ટરો',
				CLEAR: 'ચોખ્ખુ',
				MAPQUESTIONNAIRE: 'ભેગા કરો પ્રશ્નાવલી',
				SURVEYQUESTIONTITLE: 'જાહેર સવલતોનો સર્વે',
				VALIDATION: {
					NAME_INVALID: 'નામમાં અમાન્ય અક્ષરો છે',
					NAME_REQUIRED: 'કૃપા કરી નામ સ્પષ્ટ કરો',
					NAME_MIN_LENGTH: 'નામ ઓછામાં ઓછું 2 અક્ષરો લાંબું હોવું જોઈએ.',
					NAME_MAX_LENGTH: '50 અક્ષરોથી વધુ ન હોઈ શકે.',
					TEMPLATE_REQUIRED: 'ઇમેઇલ નમૂના ખાલી હોઈ શકતો નથી',
					ZIP_REQUIRED: 'ઝિપકોડ આવશ્યક છે',
					ZIP_INVALID: 'ઝિપકોડ અમાન્ય છે',
					CITY_REQUIRED: 'શહેર જરૂરી છે',
					STATE_REQUIRED: 'રાજ્ય જરૂરી છે',
					REGION_REQUIRED: 'પ્રદેશ જરૂરી છે',
					COUNTRY_REQUIRED: 'દેશ જરૂરી છે',
					USERGROUPREQUIRED: 'કૃપા કરીને વપરાશકર્તા જૂથનો ઉલ્લેખ કરો',
					VALUE_REQUIRED: 'કૃપા કરી કિંમત સ્પષ્ટ કરો',
					TITLE_INVALID: 'શીર્ષકમાં અમાન્ય અક્ષરો છે',
					TITLE_REQUIRED: 'મહેરબાની કરીને શીર્ષક સ્પષ્ટ કરો',
					TITLE_MIN_LENGTH: 'શીર્ષક ઓછામાં ઓછું 2 અક્ષરો લાંબું હોવું જોઈએ.',
					TITLE_MAX_LENGTH: '50 અક્ષરોથી વધુ ન હોઈ શકે.',
					SUBJECT_INVALID: 'વિષયમાં અમાન્ય અક્ષરો છે',
					SUBJECT_REQUIRED: 'કૃપા કરી વિષયનો ઉલ્લેખ કરો',
					SUBJECT_MIN_LENGTH: 'વિષય ઓછામાં ઓછો 2 અક્ષરો લાંબો હોવો જોઈએ.',
					SUBJECT_MAX_LENGTH: '50 અક્ષરોથી વધુ ન હોઈ શકે.',
					PIN_REQUIRED: 'કૃપા કરીને પિન સ્પષ્ટ કરો',
					PIN_REQUIRED_MIN_LENGTH: 'પિન ઓછામાં ઓછું 5 અક્ષરો લાંબું હોવું જોઈએ.',
					PIN_REQUIRED_MAX_LENGTH: '6 અક્ષરોથી વધુ ન હોઈ શકે.',
					LATITUDE_REQUIRED: 'અક્ષાંશનો ઉલ્લેખ કરો',
					LONGITUDE_REQUIRED: 'કૃપા કરીને રેખાંશનો ઉલ્લેખ કરો',
				},
				PATTERN: {
					PUBLICCONVENIENCENAME: '[a-zA-Z0-9\\-\\s]+',
					MENUNAME: '[^\.\\s][^\\!\\@\\#\\$\\%\\^\\&\\*\\(\\)\\_\\+]*',
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
				},
			},
			PROFILE: {
				NAME: 'મારી પ્રોફાઈલ',
				LISTINGTITLE: projectname + ' |મારી પ્રોફાઈલ',
				PROFILESUCCESS: 'પ્રોફાઇલ સફળતાપૂર્વક અપડેટ થઈ !!',
				INVALID_IMAGE: 'તમે અમાન્ય ફાઇલ પ્રકાર અપલોડ કર્યો છે અથવા મહત્તમ અપલોડ ફાઇલ કદ: 5 એમબી!',
				PROFILE_IMAGE: 'પ્રોફાઇલ છબી',
				BASIC_INFO: 'મૂળભૂત માહિતી',
				PHONE: 'સંપર્ક નં.',
				PHONEREQ: 'સંપર્ક નંબર આવશ્યક છે',
				PHONEERROR: 'સંપર્ક નંબર અમાન્ય છે',
				ADDRESS: 'સરનામું દાખલ કરો',
				ADDRESSTAB: 'સરનામું',
				CHANGEPASSWORD: 'પાસવર્ડ બદલો',
				RESIDENTIALADDREDSS: 'રહેણાંક સરનામું',
				PASSWORDNOTMATCH: 'ઓહ ત્વરિત! પાસવર્ડ મેળ ખાતો નથી. ',
				PASSWORDREQUIRED: 'પાસવર્ડ અનિવાર્ય છે',
				OLDPASSWORDREQUIRED: 'જૂનો પાસવર્ડ આવશ્યક છે',
				ENTEROLDPASSWORD: 'જુનો પાસવર્ડ',
				NEWPASSWORDREQUIRED: 'નવો પાસવર્ડ આવશ્યક છે',
				ENTERNEWPASSWORD: 'નવો પાસવર્ડ',
				INVALIDNEWPASSWORD: 'નવો પાસવર્ડ માન્ય નથી',
				INVALIDPASSWORD: 'પાસવર્ડ માન્ય નથી',
				NEWPASSWORDSAME: 'નવો પાસવર્ડ ઓલ્ડ પાસવર્ડ જેવો ન હોવો જોઈએ',
				CONFIRMPASSWORD: 'પાસવર્ડની પુષ્ટિ કરો',
				CONFIRMPASSWORDREQUIRED: 'પુષ્ટિ પાસવર્ડ આવશ્યક છે',
				CONFIRMPASSWORDSAME: 'નવો પાસવર્ડ અને પુષ્ટિ પાસવર્ડ મૂલ્યો મેળ ખાતા નથી.',
				INVALIDOLDPASSWORD: 'જૂનો પાસવર્ડ માન્ય નથી',
				CREATEUSERGROUP: 'સફળતાપૂર્વક વપરાશકર્તા જૂથ બનાવો',
				UPDATEUSERGROUP: 'સફળતાપૂર્વક વપરાશકર્તા જૂથને અપડેટ કરો',
			},
			CHART: {
				PUBLIC: 'જાહેર શૌચાલય',
				COMMUNITY: 'સમુદાય શૌચાલય',
				BOTH: 'સાર્વજનિક અને સમુદાય શૌચાલય',
				YES: 'હા',
				NO: 'ના',
				SECTION: 'વિભાગમાં',
				SEPRATEFACILITY: 'સુવિધા અલગ કરો',
				FUNCTIONAL: 'કાર્યાત્મક',
				NONFUNCTIONAL: 'બિન કાર્યાત્મક',
				IDEAL: 'આદર્શ',
				UNDERUTILIZED: 'હેઠળ ઉપયોગ',
				OVERUTILIZED: 'અતિ ઉપયોગી',
				FUNCTIONALFLUSH: 'શૌચાલયની બેઠકોમાં કાર્યાત્મક ફ્લશ',
				FUCTIONALTAP: 'ટોઇલેટ સીટમાં કાર્યાત્મક નળ',
				MUGCUBICLES: 'ઘન માં મગ',
				HOOKSCHANGING: 'કપડાં બદલવાનું',
				VANTILATOR : 'વેન્ટિલેટર / એક્ઝોસ્ટ',
				SEPRATELIGHT: 'ક્યુબિકલમાં અલગ પ્રકાશ',
				DEFICIT: 'ખોટ',
				CHILDFRIENDLYWASHBASIN: 'બાળ મૈત્રીપૂર્ણ ટોઇલેટ સેટ',
				CHILDFRIENDLYTOILET: 'બાળ મૈત્રીપૂર્ણ ધોવા બેસિન',
				FUNCTIONALDOOR: 'કાર્યાત્મક દરવાજો અને કળણ',
				CHILDURINALPOT: 'બાળક પેશાબ પોટ',
				DISABLEDFRIENDLY: 'અકબંધ અક્ષમ મૈત્રીપૂર્ણ ટોઇલેટ સીટ',
				RAMPWITHHAND: 'હેન્ડ રેલ સાથે રેમ્પ',
				DISABLEDFRIENDLYCUBICLE: 'અક્ષમ કરેલ મૈત્રીપૂર્ણ ક્યુબિકલ કદ',
				GRABBAR: 'ગ્રેબ બાર',
				BARRIERFREE: 'અવરોધ મુક્ત પ્રવેશ',
				WASHBASINDISABLEDFRIENDLY: 'અક્ષમ પર બેસિન ધોવા મૈત્રીપૂર્ણ ઊંચાઈ',
				UNBROKENDOOR: 'યોગ્ય લ latચ સાથે અખંડ ડોર',
				ULB: 'યુ.એલ.બી.',
				PVT: 'પ્રા.લિ. એજન્સી',
				GOOD: 'સારું',
				FAIR: 'યોગ્ય',
				BAD: 'ખરાબ',
				ONCEDAY: 'દિવસમાં એકવાર',
				TWICEDAY: 'દિવસમાં બે વાર',
				THRICEDAY: 'દિવસમાં ત્રણ વખત',
				ASMANYREQUIRED: 'જેટલી વખત જરૂરી છે	',
				IRREGULAR: 'અનિયમિત',
				NEVER: 'ક્યારેય',
				CHECKEDANDREPAIRED: 'નિયમિત ધોરણે ચેક અને રિપેર કરાવી',
				WHENREQUIRED: 'જ્યારે અને જ્યારે જરૂરી હોય',
				NOTREPAIRED: 'સમારકામ નથી',
				OPENINGHOUR: 'શરૂઆતના કલાકો દરમિયાન',
				FEWHOUR: 'દિવસના કેટલાક કલાકો',
				NOTFIXED: 'નિશ્ચિત નથી',
				FUNCTIONALTAPCUBICLE: 'ક્યુબિકલ્સમાં કાર્યાત્મક નળ',
				STORAGETANK: 'સ્ટોરેજ ટાંકીમાંથી પાણી લાવવું',
				OWNHOUSE: 'પોતાના ઘરમાંથી પાણી લઈ આવવું',
				TYPEOFTOILET: 'શૌચાલયનો પ્રકાર',
				INCLUSIVETOILET: 'સમાવિષ્ટ શૌચાલય',
				FUNCTIONALITYTOILET: 'શૌચાલયની કાર્યક્ષમતા',
				UTILIZEDOFTOILET: 'ટોઇલેટ બ્લોકનો ઉપયોગ',
				FUNCTIONLTYVSUTILIZATION: 'કાર્યક્ષમતા વિ ટોટલેટ બ્લોકનો ઉપયોગ',
				MALEBLOCK: 'બેઠકો ઇન્ફ્રાસ્ટ્રક્ચરલ પુરૂષ બ્લોકમાં ખોટ',
				FEMALEBLOCK: 'બેઠકો ઇન્ફ્રાસ્ટ્રક્ચરલ ફિમેલ બ્લોકમાં ખોટ',
				CHILDBLOCK: 'બેઠકો ઇન્ફ્રાસ્ટ્રક્ચરલ બાળ મૈત્રીપૂર્ણ બ્લોકમાં ખોટ',
				DISABLEDBLOCK: 'બેઠકો ઇન્ફ્રાસ્ટ્રક્ચરલ અક્ષમ મૈત્રીપૂર્ણ બ્લોકમાં ખોટ',
				RESPONSIBILITYOFOPERATION: 'સંચાલન અને જાળવણીની જવાબદારી',
				BUILDINGCONDITION: 'બિલ્ડિંગની સ્થિતિ',
				CLIENLINESSTOILET: 'ટોઇલેટ બ્લોકની ક્લાયન્સી',
				CLIENLINESSALLTOILET: 'તમામ શૌચાલય બ્લોકની ક્લાયન્સિસ',
				CLIENLINESSAMC: 'એએમસી સંચાલિત શૌચાલયોની એકંદર સફાઇ',
				CLIENLINESSPRIVATE: 'ખાનગી સંચાલિત શૌચાલયોની એકંદર સ્વચ્છતા',
				OMAGENCY: 'ઓ એન્ડ એમ વય અને સ્વચ્છતાની આવર્તન',
				REPAIRREGIME: 'સમારકામ શાસન અને સ્વચ્છતાની આવર્તન',
				WATERDUPPLY: 'ટોઇલેટ ક્યુબિકલમાં બે વખત પાણીનો સમયગાળો',
				WATERTOBEFATCH: 'સંગ્રહ ટાંકીમાંથી પાણી લાવવું',
			}
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'પસંદ કરેલા રેકોર્ડની ગણતરી:',
				ALL: 'બધા',
				SUSPENDED: 'સસ્પેન્ડ',
				ACTIVE: 'એક્ટિવ',
				FILTER: 'ફિલ્ટર',
				BY_STATUS: 'સ્ટેટસ બાય',
				BY_TYPE: 'ટાઈપ બાય',
				BUSINESS: 'બિઝનેસ',
				INDIVIDUAL: 'વ્યક્તિગત',
				SEARCH: 'શોધ',
				IN_ALL_FIELDS: 'બધા ક્ષેત્રમાં'
			},
			ECOMMERCE: 'ઈકોમર્સ',
			CUSTOMERS: {
				CUSTOMERS: 'ગ્રાહકો',
				CUSTOMERS_LIST: 'ગ્રાહકોની સૂચિ',
				NEW_CUSTOMER: 'નવો ગ્રાહક',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'ગ્રાહક કાઢી નાંખો',
					DESCRIPTION: 'શું તમે ખરેખર આ ગ્રાહકને કાયમી ધોરણે કાઢી નાખવા માંગો છો?',
					WAIT_DESCRIPTION: 'ગ્રાહક કાtingી રહ્યું છે ...',
					MESSAGE: 'ગ્રાહક કાઢી નાખવામાં આવ્યો છે'
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'ગ્રાહકો કાઢી નાંખો',
					DESCRIPTION: 'શું તમે ખરેખર પસંદ કરેલા ગ્રાહકોને કાયમી ધોરણે કાઢી નાખવા માંગો છો?',
					WAIT_DESCRIPTION: 'ગ્રાહકો કાtingી રહ્યાં છે ...',
					MESSAGE: 'પસંદ કરેલા ગ્રાહકોને કાઢી નાખવામાં આવ્યા છે'
				},
				UPDATE_STATUS: {
					TITLE: 'પસંદ કરેલા ગ્રાહકો માટે સ્થિતિ અપડેટ કરવામાં આવી છે',
					MESSAGE: 'પસંદ કરેલા ગ્રાહકોની સ્થિતિ સફળતાપૂર્વક અપડેટ કરવામાં આવી છે'
				},
				EDIT: {
					UPDATE_MESSAGE: 'ગ્રાહક અપડેટ કરવામાં આવ્યો છે',
					ADD_MESSAGE: 'ગ્રાહક બનાવવામાં આવ્યો છે'
				}
			}
		}
	}
};
