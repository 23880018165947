export class CsTableResource {
	public totalRecords: number = 10;
	public displayRecords: number = 10;
	public skipRecords: number = 0;
	public paginationRange = [];

	// sort
	public sortField = "name";
	public sortOrder = "desc";

	public filter = {
		filter: {},
	};

	constructor(filter, totalRecords) {
		this.filter = filter || {};
		if (totalRecords) {
			this.paginationRange = this.createPaginationArray(totalRecords);
			this.totalRecords = totalRecords;
		}

		if (this.filter["sortField"]) {
			this.sortField = this.filter["sortField"];
			this.sortOrder = this.filter["sortOrder"];
		}
	}

	resetValue() {
		this.displayRecords = 10;
		this.skipRecords = 0;
		this.sortField = "_id";
		this.sortOrder = "desc";
	}

	createPaginationArray(totalCount) {
		const paginationOptions = [10, 25, 50, 100, 200, 500, 1000];

		const filteredOptions = paginationOptions.filter((option) => option <= totalCount);

		const nextOption = paginationOptions.find((option) => option > totalCount);
		if (nextOption) {
			filteredOptions.push(nextOption);
		}

		return filteredOptions;
	}

	updatePagination(count) {
		this.paginationRange = this.createPaginationArray(count);
		this.totalRecords = count;
	}

	updateFilter(filter) {
		this.filter = filter;
	}
}
