import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class LunguageselectionService {

    languageVisibilityChange: Subject<boolean> = new Subject<boolean>();

    constructor()  {
    }

    changeLanguage(value) {
        this.languageVisibilityChange.next(value);
    }
}
