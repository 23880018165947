import { Component, OnInit, OnDestroy, Input, Inject, ChangeDetectorRef } from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";
import { MatCheckboxChange } from '@angular/material/checkbox';
@Component({
	selector: "tdata-check-box",
	templateUrl: "./checkbox.html",
	styleUrls: ["./checkbox.scss"],
})
export class TDataShowInSearch implements OnInit, OnDestroy {
	@Input() config;
	@Input() data;

	// isChecked: boolean = true;
	// selection: any = new SelectionModel(true);
	// isSelected = true;
	constructor(private cdr: ChangeDetectorRef) {
		// this.config = data;
	}

	ngOnInit() {
	}
	onCheckboxClick(event: MatCheckboxChange): void {
		this.config.dataConf.checkboxClick(this.data.id,event.checked);
	}

	ngOnDestroy() {}
}
