export class ArtisteListModel {
	_id: number;
	name: string;
	email: string;
	pressKitEmailId: string;
	contact_no: string;
	usergroup: any = null;
	pic: string;
	video: string;
	userType: string;
	about: string;
	otpCode: string;
	professions: any = null;
	interestedIn: any = null;
	socialLink: any;
	pressKit: any;
	management: any;
	managementConnect: any;
	webpassword: string;
	webemail: string;
	status: any;
	picture: string;
	package_id: string;
	carousal1: string;
	carousal2: string;
	carousal3: string;
	carousal4: string;
	carousal5: string;
	carousal1Picture: string;
	carousal2Picture: string;
	carousal3Picture: string;
	carousal4Picture: string;
	carousal5Picture: string;
	teamCreation: Boolean = false;
	showInSearch: Boolean = false;
	dailyConnectionLimit: any;
	reject: any;
	rejectList:any;
	clear(): void {
		this._id = undefined;
		this.email = "";
		this.name = "";
		this.pic = "./assets/media/users/user4.jpg";
		this.contact_no = "";
	}
	getSpotifyUrl(socialLink): string | undefined {
		const spotifyLink = socialLink.find((link) => link.label === "Spotify");
		return spotifyLink ? spotifyLink.url : undefined;
	}
}
