import { AfterViewInit, Component, ViewChild, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject, Input, OnDestroy, EventEmitter, HostListener } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { ListingService } from "../../../common/services/listing.service";
// ../model/artisteList-model
import { ArtisteService } from "../../../modules/artiste/service/artiste.service";
import { NotificationService } from "../../../modules/managerinvite/service/service";
import { ArtisteListModel } from "../../../modules/artiste/model/artisteList-model";
import { ArtisteAddEditComponent } from "../../../modules/artiste/artiste-edit/artiste-edit.component";
import { GridOption } from "../../../../../app/core/base/layout/models/gridOption.model";
import { merge, Observable, of as observableOf } from "rxjs";
import { catchError, map, startWith, switchMap, filter, throttleTime } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { CategoryService } from "../../../modules/category/service/category.service";
import { DeleteEntityDialogComponent } from "../../../../../app/views/partials/content/crud/delete-entity-dialog/delete-entity-dialog.component";
import { UpdateStatusDialogComponent } from "../../../../../app/views/partials/content/crud/update-status-dialog/update-status-dialog.component";
import { SelectionModel } from "@angular/cdk/collections";
import { ModuleUpdateModel } from "./model/moduleUpdate-model";
import { UserService } from "../../../modules/user/service/user.service";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { TranslationService } from "../../../../../app/core/base/layout/services/translation.service";
import { LunguageselectionService } from "../../../../../app/views/partials/layout/topbar/language-selector/language-selector.service";
import { NgForm } from "@angular/forms";
import { environment } from "../../../../../environments/environment";
import { LanguageService } from "../../services/language.service";
import { Subject } from "rxjs";
import * as jwt_decode from "jwt-decode";
import { SharedService } from "../../services/shared.service";
import { DashboardService } from "../../../modules/artiste/dashboard/service/dashboard.service";
import * as Highcharts from "highcharts";
import { MatCheckboxChange } from "@angular/material/checkbox";

interface singleCategory {
	_id: string;
	title: string;
	description: string;
	popular: boolean;
	status: number;
	parent: string | null;
	parent_title: string | null;
	order: number;
	subcategory: singleCategory[];
	selected: boolean | false;
	// Add other properties as needed
}
@Component({
	selector: "kt-listing",
	templateUrl: "./listing.component.html",
	styleUrls: ["./listing.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListingComponent implements OnInit, OnDestroy {
	@Input() modulenameSingular;
	@Input() modulenamePlural;
	@Input() moduleNameServiceRoute;
	@Input() displayedColumns;
	@Input() field;
	@Input() notdelete;
	@Input() advanceSearchFields;
	@Input() notedit;
	@Input() source;
	@Input() fieldgroup;
	@Input() actionButton;
	result: any;
	private subscription;
	categoryArr: any = [];
	companyselect: string;
	artisteModel = new ArtisteListModel();
	dataSource: any = new MatTableDataSource();
	selection: any = new SelectionModel(true);
	selectionSearch: any = new SelectionModel(true);
	moduleUpdateModel = new ModuleUpdateModel();
	resultsLength = 0;
	campaignListDeleteSuccess = "";
	campaignListDeleteConfirm = "";
	isLoadingResults = false;
	isRateLimitReached = false;
	EnoughPermission: any = "";
	isCreate = false;
	isRead = false;
	isReadCamp = false;
	isDelete = false;
	isUpdate = false;
	siteTitle = "";
	nameError: any = "";
	emailError: any = "";
	contactError: any = "";
	langobj: any;
	editingRowId: number | null = null;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	@ViewChild("mySelect", null) mySelect;

	selectedStatus: number = 2;
	statusArr: any = [
		{ value: this.translateService.instant("PAGES.COMMON.ALL"), key: 2 },
		{ value: this.translateService.instant("PAGES.COMMON.ACTIVE"), key: 1 },
		{ value: this.translateService.instant("PAGES.COMMON.INACTIVE"), key: 0 },
	];
	filterArr: any = [0, 1];
	gridOption: GridOption = {
		order: "-_id",
		search: "",
		filter: {},
		sortField: "_id",
		sortOrder: "desc",
		skip: 0,
		limit: 10,
		allrecords: false,
	};
	languageArr: any;
	currentLang: any;
	actionURL: string;
	token: any;
	tokenData: any;
	routeData: any;
	permissionArr: any;
	status: any = null;
	maxDate: string = "";
	searchText: string = "";
	maxDateForTo: string = "";
	fromDate: string;
	toDate: string;
	newCategoryArr: singleCategory[] = [];
	imgUrl: any;
	imagebucketurl = environment.imagebucketurl + "/userpic/";
	private addSuccess: EventEmitter<boolean> = new EventEmitter();
	private mangerAddSuccess: EventEmitter<boolean> = new EventEmitter();
	constructor(private dialog: MatDialog, private router: Router, private toastr: ToastrService, private commonlistingService: ListingService, private cd: ChangeDetectorRef, private translateService: TranslateService, private translationService: TranslationService, private languageService: LanguageService, private lunguageselectionService: LunguageselectionService, private activatedRoute: ActivatedRoute, private categoryService: CategoryService, private sharedService: SharedService, private cdr: ChangeDetectorRef, private artisteService: ArtisteService, private notificationService: NotificationService, private userService: UserService) {
		this.subscription = this.lunguageselectionService.languageVisibilityChange.subscribe((value) => {
			this.currentLang = JSON.parse(localStorage.getItem("language"));
			this.langobj = value;
			this.statusArr = [
				{ value: this.translateService.instant("PAGES.COMMON.ALL"), key: 2 },
				{ value: this.translateService.instant("PAGES.COMMON.ACTIVE"), key: 1 },
				{ value: this.translateService.instant("PAGES.COMMON.INACTIVE"), key: 0 },
			];
			if (this.currentLang.locale !== this.langobj.locale) {
				this.translationService.setLanguage(this.langobj);
				this.statusArr = [
					{ value: this.translateService.instant("PAGES.COMMON.ALL"), key: 2 },
					{ value: this.translateService.instant("PAGES.COMMON.ACTIVE"), key: 1 },
					{ value: this.translateService.instant("PAGES.COMMON.INACTIVE"), key: 0 },
				];
				this.getData();
			}
		});
	}

	ngOnInit() {
		// this.imgUrl = this.imagebucketurl
		
		this.setMaxDate();
		this.dataSource = new MatTableDataSource(); // create new object
		this.dataSource.sort = this.sort;

		this.dataSource.paginator = this.paginator;
		this.gridOption.filter.status = { $in: [0, 1] };
		this.actionURL = this.router.url;

		this.token = localStorage.getItem("authToken");
		this.tokenData = jwt_decode(this.token);
		this.permissionArr = this.tokenData.usergroup[0].permission;
		this.routeData = this.activatedRoute.snapshot.data;
		let usergroup = this.tokenData.usergroup[0];

		if (this.routeData.modulename === "artiste") {
			this.getCategoryList();
		}
		/*
	if(this.routeData && this.routeData.modulename === 'profilerequest'){
	  this.gridOption.filter.status = { $in: [0, 1, 3] };
	  //this.status = 0;
	  this.filterArr = [0,1,3];
	  this.statusArr = [
		{ 'value':  this.translateService.instant('PAGES.COMMON.ALL'), 'key': 2 },
		{ 'value': this.translateService.instant('PAGES.PROFILEREQUEST.PENDING'), 'key': 3 },
		{ 'value': this.translateService.instant('PAGES.PROFILEREQUEST.APPROVED'), 'key': 1 },
		{ 'value': this.translateService.instant('PAGES.PROFILEREQUEST.REJECTED'), 'key': 0 }
	  ];
	}
	*/

		if ((this.routeData && this.routeData.modulename === "artiste") || (this.routeData && this.routeData.modulename === "customer") || (this.routeData && this.routeData.modulename === "manage")) {
			this.gridOption.filter.status = { $in: [0, 1, 3, 4, 5] };

			this.filterArr = [0, 1, 3, 4, 5];
			this.statusArr = [
				{ value: this.translateService.instant("PAGES.COMMON.ALL"), key: 2 },
				{ value: this.translateService.instant("PAGES.ARTISTE.PENDING"), key: 3 },
				{ value: this.translateService.instant("PAGES.COMMON.ACTIVE"), key: 1 },
				{ value: this.translateService.instant("PAGES.COMMON.INACTIVE"), key: 0 },
				{ value: "Incomplete", key: 4 },
				{ value: "Reject", key: 5 },
			];

			const localFilter = localStorage.getItem(`${this.moduleNameServiceRoute}-filter`);
			if (localFilter) {
				const data = JSON.parse(localFilter);
				this.searchText = data.search;
				if (typeof data.filter.status !== "object") {
					this.status = data.filter.status;
				}
				if (data.filter.createdAt && data.filter.createdAt.$gte && typeof data.filter.createdAt === "object") {
					this.fromDate = new Date(data.filter.createdAt["$gte"]).toISOString().split("T")[0];
				}
				if (data.filter.createdAt && data.filter.createdAt.$lte && typeof data.filter.createdAt === "object") {
					this.toDate = new Date(data.filter.createdAt["$lte"]).toISOString().split("T")[0];
				}
				if (data.filter.professions && data.filter.professions.$in) {
					this.artisteModel.professions = data.filter.professions.$in;
				}
				this.gridOption = data;
			}
			this.gridOption.sortField = "_id";
			this.gridOption.sortOrder = "desc";
		}

		if (this.routeData && this.routeData.modulename === "couponcode") {
			this.gridOption.filter.status = { $in: [0, 1, 3, 4] };
			//this.status = 0;
			this.filterArr = [0, 1, 3, 4];
			this.statusArr = [
				{ value: this.translateService.instant("PAGES.COMMON.ALL"), key: 2 },
				{ value: this.translateService.instant("PAGES.ARTISTE.EXPIRED"), key: 3 },
				{ value: this.translateService.instant("PAGES.ARTISTE.USED"), key: 4 },
				{ value: this.translateService.instant("PAGES.COMMON.ACTIVE"), key: 1 },
				{ value: this.translateService.instant("PAGES.COMMON.INACTIVE"), key: 0 },
			];
		}
		if (this.routeData && this.routeData.modulename === "notification") {
			this.gridOption.filter.status = { $in: [0, 1, 3, 4] };
			//this.status = 0;
			this.filterArr = [0, 1, 3, 4];
			this.statusArr = [
				{ value: this.translateService.instant("PAGES.COMMON.ALL"), key: 2 },
				{ value: this.translateService.instant("PAGES.NOTIFICATION.ACTIVE"), key: 1 },
				{ value: this.translateService.instant("PAGES.NOTIFICATION.SENT"), key: 3 },
				{ value: this.translateService.instant("PAGES.NOTIFICATION.SENTERROR"), key: 4 },
				{ value: this.translateService.instant("PAGES.NOTIFICATION.INACTIVE"), key: 0 },
			];
		}

		if (this.routeData && this.routeData.modulename === "feedback") {
			this.gridOption.filter.status = { $in: [0, 1, 3] };
			//this.status = 0;
			this.filterArr = [0, 1, 3];
			this.statusArr = [
				{ value: this.translateService.instant("PAGES.COMMON.ALL"), key: 100 },
				{ value: this.translateService.instant("PAGES.NOTIFICATION.ACTIVE"), key: 1 },
				{ value: "Resolved", key: 3 },
				{ value: this.translateService.instant("PAGES.NOTIFICATION.INACTIVE"), key: 0 },
			];
		}
		if (this.routeData && this.routeData.modulename === "chat") {
			this.gridOption.filter.status = { $in: [1, 2, 3, 4] };
			this.filterArr = [1, 2, 3, 4];
			this.statusArr = [
				{ value: "All", key: 100 },
				{ value: "Blocked", key: 1 },
				{ value: "Unblock Requested", key: 2 },
				{ value: "Unblocked", key: 3 },
				{ value: "Unblock Request Rejected", key: 4 },
			];
		}
		if (this.routeData && this.routeData.modulename === "deactivate") {
			this.gridOption.filter.status = { $in: [1, 2, 3] };
			this.filterArr = [1, 2, 3];
			this.statusArr = [
				{ value: "All", key: 100 },
				{ value: "Requested", key: 1 },
				{ value: "Deleted", key: 2 },
				{ value: "Canceled By User", key: 3 },
				// { 'value': "Unblock Request Rejected", 'key': 4 }
			];
		}
		if (this.routeData && this.routeData.modulename === "managerinvite") {
			this.gridOption.filter.status = null;
			this.filterArr = [null];
			this.statusArr = [
				{ value: "All", key: 2 },
				{ value: "Active", key: 1 },
				{ value: "Inactive", key: 0 },
				{ value: "Requested", key: 3 },
				{ value: "Incomplete", key: 4 },
				{ value: "Invited", key: "nan" },
			];
		}
		if (this.routeData && this.routeData.modulename === "consolenotification") {
			this.gridOption.filter.status = { $in: [1, 0] };
			this.filterArr = [1, 0];
			this.statusArr = [
				{ value: "All", key: 100 },
				{ value: "Unread", key: 1 },
				{ value: "Viewed", key: 0 },
			];
		}

		if (this.routeData && this.routeData.modulename === "consolenotification") {
			this.gridOption.filter.status = { $in: [1, 0] };
			this.filterArr = [1, 0];
			this.statusArr = [
				{ value: "All", key: 100 },
				{ value: "Unread", key: 1 },
				{ value: "Viewed", key: 0 },
			];
		}

		if (this.routeData && this.routeData.modulename === "team") {
			this.gridOption.filter.status = { $in: [1, 2, 3, 0] };
			this.filterArr = [1, 2, 3, 0];
			this.statusArr = [
				{ value: "All", key: 100 },
				{ value: "Active", key: 1 },
				{ value: "New Request", key: 2 },
				{ value: "Reject", key: 3 },
				{ value: "Inactive", key: 0 },
			];
		}
		this.getData();
	}
	changeDailyDataLimit(row: any) {
		this.editingRowId = row._id;
	}

	updateDailyDataLimit(row: any) {
		this.subscription = this.artisteService.update({ _id: row._id, dailyConnectionLimit: row.dailyConnectionLimit }).subscribe(
			(res) => {
				if (res.message === "success" || res.status === 1) {
					this.editingRowId = null;
					this.router.navigate([this.actionURL]).then(() => {
						this.toastr.success(this.translateService.instant("PAGES.PROFILE.PROFILESUCCESS"), this.translateService.instant("PAGES.COMMON.SUCCESS"));
					});
				} else if (res.error && res.error.status == 0 && res.error.status_code == 500) {
					this.toastr.error(res.error.message);
				} else {
					this.nameError = res.message.firstname;
					this.emailError = res.message.email;
					this.contactError = res.message.contact_no;
				}
				this.cdr.detectChanges();
			},
			(err) => {
				console.log(err);
				this.toastr.error(err.message);
			}
		);
	}

	inviteAgain(row: any) {
		this.subscription = this.notificationService.managerInviteAgian({ invitationId: row._id }).subscribe(
			(res) => {
				if (res.message === "success" || res.status === 1) {
					// this.router.navigate(["admin/managerinvite"]).then(() => {
					this.toastr.success(this.translateService.instant("Invitation Sent Successfully"), this.translateService.instant("PAGES.COMMON.SUCCESS"));
					// });
					this.getData();
				} else if (res.error && res.error.status == 0 && res.error.status_code == 500) {
					this.toastr.error(res.error.message);
				} else {
					this.nameError = res.message.firstname;
					this.emailError = res.message.email;
					this.contactError = res.message.contact_no;
				}
			},
			(err) => {
				console.log(err);
				this.toastr.error(err.message);
			}
		);
	}

	openDialog2(): void {
		this.mySelect.close();
		const dialogRef = this.dialog.open(ArtisteProfessionDialogFilter, {
			data: { category: this.categoryArr, profession: JSON.parse(JSON.stringify(this.artisteModel.professions)) },
			width: "80vw",
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.artisteModel.professions = result;
				this.cdr.detectChanges();
			}
		});
	}

	getCategoryList() {
		this.gridOption.filter.status = 1;
		this.categoryService.searchAll({ ...this.gridOption, allrecords: true }).subscribe((response) => {
			this.categoryArr = response.data;
			let tempData = JSON.parse(JSON.stringify(response.data));
			// YourDataType[]
			this.formateCategoryWithParent(tempData)
				.then((data: singleCategory[]) => {
					this.newCategoryArr = data;
				})
				.catch((err) => {
					console.error(err);
				});
			this.cdr.detectChanges();
		});
	}
	formateCategoryWithParent(array: Array<singleCategory>) {
		return new Promise((resolve, reject) => {
			try {
				let newarray: Array<singleCategory> = [];
				for (let index = 0; index < array.length; index++) {
					const element = array[index];
					if (element.title === "Other") continue;
					if (this.artisteModel.interestedIn && this.artisteModel.interestedIn.includes(element._id)) {
						element.selected = true;
					} else {
						element.selected = false;
					}
					if (!element.parent) {
						const tempIndex = newarray.findIndex((item) => item._id === element.parent);
						if (!tempIndex) newarray.push(element);
					} else if (element.parent != "") {
						const tempIndex = newarray.findIndex((item) => item._id === element.parent);
						let tempObj = newarray[tempIndex];
						if (tempObj) {
							if (tempObj.subcategory) {
								tempObj.subcategory.push(element);
							} else {
								tempObj.subcategory = [element];
							}
							newarray[tempIndex] = tempObj;
						} else {
							let copy = JSON.parse(JSON.stringify(this.categoryArr));
							const futureElement = copy.find((item: singleCategory) => item._id === element.parent);
							if (futureElement) {
								futureElement.subcategory = [element];
								newarray.push(futureElement);
							}
						}
					}
				}

				// newarray.sort((a, b) => {
				// 	if (a.title === "Main") {
				// 		return -1; // 'Main' should come first
				// 	} else if (b.title === "Main") {
				// 		return 1; // 'Main' should come before other elements
				// 	} else if (a.title > b.title) {
				// 		return 1; // Indicates a should come after b
				// 	} else if (a.title < b.title) {
				// 		return -1; // Indicates a should come before b
				// 	} else {
				// 		return 0; // Indicates they are equal in terms of sorting
				// 	}
				// });

				// console.log(newarray)
				resolve(newarray);
			} catch (error) {
				reject(error);
			}
		});
	}
	Sort() {
		this.gridOption.limit = this.paginator.pageSize;
		this.gridOption.skip = this.paginator.pageSize * this.paginator.pageIndex;
		this.gridOption.sortField = this.sort.active;
		this.gridOption.sortOrder = this.sort.direction;
		this.paginator.pageIndex = 0;
		this.getData();
	}

	verifiedCheckBox(event: MatCheckboxChange): void {
		this.gridOption.filter["spotifyVerified"] = event.checked;
		this.getData();
	}

	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	onCheckboxClick(event: any, row: any): void {
		const isSelected = event.checked;
		this.subscription = this.artisteService.update({ _id: row._id, showInSearch: isSelected }).subscribe(
			(res) => {
				if (res.message === "success" || res.status === 1) {
					this.router.navigate(["admin/talent"]).then(() => {
						this.toastr.success(this.translateService.instant("PAGES.PROFILE.PROFILESUCCESS"), this.translateService.instant("PAGES.COMMON.SUCCESS"));
					});
				} else if (res.error && res.error.status == 0 && res.error.status_code == 500) {
					this.toastr.error(res.error.message);
				} else {
					this.nameError = res.message.firstname;
					this.emailError = res.message.email;
					this.contactError = res.message.contact_no;
				}
				this.cdr.detectChanges();
			},
			(err) => {
				console.log(err);
				this.toastr.error(err.message);
			}
		);

		event.stopPropagation(); // Prevents the click event from bubbling up
		this.selection.toggle(row); // Toggles the selection state of the row
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
	}

	pageChanged() {
		this.gridOption.limit = this.paginator.pageSize;
		this.gridOption.skip = this.paginator.pageSize * this.paginator.pageIndex;
		this.gridOption.sortField = this.sort.active;
		this.gridOption.sortOrder = this.sort.direction;
		this.getData();
	}

	deleteModule(id): void {
		let dialogRef = this.dialog.open(DeleteEntityDialogComponent, {
			// tslint:disable-next-line: max-line-length
			data: { description: this.translateService.instant("PAGES.COMMON.PERMANTLYDELETE") + " " + this.titleCase(this.modulenameSingular) + " ?", title: this.titleCase(this.modulenameSingular) + " " + this.translateService.instant("PAGES.COMMON.DELETE") + " ", waitDesciption: this.titleCase(this.modulenameSingular) + " " + this.translateService.instant("PAGES.COMMON.ISDELETING") + "..." },
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result !== undefined) {
				this.commonlistingService.delete(id, this.moduleNameServiceRoute).subscribe((res) => {
					if (res.message === "success" || res.status === 1) {
						if (this.source) {
							this.router.navigate(["/" + this.moduleNameServiceRoute + "/" + this.source]).then(() => {
								// tslint:disable-next-line: max-line-length
								this.toastr.success(this.modulenameSingular + " " + "deleted" + " " + this.translateService.instant("PAGES.COMMON.SUCCESS"), this.translateService.instant("PAGES.COMMON.SUCCESS"));
								this.getData();
							});
						} else {
							this.router.navigate([this.actionURL]).then(() => {
								// tslint:disable-next-line: max-line-length
								this.toastr.success(this.modulenameSingular + " " + "deleted" + " " + this.translateService.instant("PAGES.COMMON.SUCCESS"), this.translateService.instant("PAGES.COMMON.SUCCESS"));
								this.getData();
							});
						}
					} else {
						this.toastr.error(res.message.id, this.translateService.instant("PAGES.COMMON.ERROR"));
					}
				});
			}
		});
	}

	deleteModules() {
		const dialogRef = this.dialog.open(DeleteEntityDialogComponent, {
			// tslint:disable-next-line: max-line-length
			data: { description: this.translateService.instant("PAGES.COMMON.PERMANTLYDELETE") + " " + this.titleCase(this.modulenamePlural) + "?", title: "" + this.titleCase(this.modulenamePlural) + " " + this.translateService.instant("PAGES.COMMON.DELETE") + " ", waitDesciption: this.titleCase(this.modulenamePlural) + " " + this.translateService.instant("PAGES.COMMON.ISDELETING") + "..." },
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result !== undefined) {
				let idsForDeletion = [];
				for (let i = 0; i < this.selection.selected.length; i++) {
					idsForDeletion.push(this.selection.selected[i]._id);
				}
				this.moduleUpdateModel.ids = idsForDeletion;
				this.moduleUpdateModel.action = "delete";
				this.moduleUpdateModel.value = 2;
				this.commonlistingService.deleteSelected(this.moduleUpdateModel, this.moduleNameServiceRoute).subscribe((res) => {
					if (res.message === "success" || res.status === 1) {
						this.router.navigate([this.actionURL]).then(() => {
							// tslint:disable-next-line: max-line-length
							this.toastr.success(this.translateService.instant("PAGES.COMMON.DELETE") + " " + this.modulenameSingular + " " + this.translateService.instant("PAGES.COMMON.SUCCESS"), this.translateService.instant("PAGES.COMMON.SUCCESS"));
							this.selection.clear();
							this.getData();
						});
					} else {
						this.toastr.error(res.message.id, this.translateService.instant("PAGES.COMMON.ERROR"));
					}
				});
			}
		});
	}

	updateModules(selectedButton) {
		let Message = this.translateService.instant("PAGES.COMMON.SELECTED") + " " + this.titleCase(this.modulenamePlural) + " " + this.translateService.instant("PAGES.COMMON.STATUSUPDATED");
		let statuses = [];
		let selectionTitle = this.translateService.instant("PAGES.COMMON.STATUS");
		let title = this.translateService.instant("PAGES.COMMON.STATUSSELECTEDUPDATE") + " " + this.titleCase(this.modulenamePlural);
		if (selectedButton == this.translateService.instant("ACTIONS.UPDATEUSERTYPE.VALUE")) {
			statuses = [];
			selectionTitle = this.translateService.instant("PAGES.COMMON.USERTYPESTATUS");
			title = this.translateService.instant("PAGES.COMMON.SELECTEDUSERTYPEUPDATE") + " " + this.titleCase(this.modulenamePlural);
			const userTypes = {
				customer: { value: "03", text: this.translateService.instant("MENU.CUSTOMER") },
				manage: { value: "02", text: this.translateService.instant("MENU.MANAGE") },
				artiste: { value: "01", text: this.translateService.instant("MENU.ARTISTE") },
			};
			let getCurrentValue = Object.keys(userTypes).filter((e) => e != this.routeData.modulename);
			statuses = getCurrentValue.map((e) => userTypes[e]);
		} else {
			statuses.push({ value: 1, text: "Active" }, { value: 0, text: "InActive" });
		}

		const dialogRef = this.dialog.open(UpdateStatusDialogComponent, {
			// tslint:disable-next-line: max-line-length
			data: { Message: Message, title: title, statuses: statuses, selectionTitle: selectionTitle },
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result !== undefined) {
				let idsForDeletion = [];
				for (let i = 0; i < this.selection.selected.length; i++) {
					idsForDeletion.push(this.selection.selected[i]._id);
				}
				this.moduleUpdateModel.ids = idsForDeletion;
				this.moduleUpdateModel.action = "update";
				this.moduleUpdateModel.value = result;
				if (selectedButton == this.translateService.instant("ACTIONS.UPDATEUSERTYPE.VALUE")) {
					this.userService.updateUserType(this.moduleUpdateModel).subscribe((res) => {
						if (res.status === 1) {
							this.router.navigate([this.actionURL]).then(() => {
								this.toastr.success(this.translateService.instant("PAGES.COMMON.SELECTED") + " " + this.titleCase(this.modulenamePlural) + " " + this.translateService.instant("PAGES.COMMON.STATUSUPDATED"), this.translateService.instant("PAGES.COMMON.SUCCESS"));
								this.selection.clear();
								this.getData();
							});
						}
					});
				} else {
					this.commonlistingService.updateStatusMany(this.moduleUpdateModel, this.moduleNameServiceRoute).subscribe((res) => {
						if (res.status === 1) {
							this.router.navigate([this.actionURL]).then(() => {
								// tslint:disable-next-line: max-line-length
								this.toastr.success(this.translateService.instant("PAGES.COMMON.SELECTED") + " " + this.titleCase(this.modulenamePlural) + " " + this.translateService.instant("PAGES.COMMON.STATUSUPDATED"), this.translateService.instant("PAGES.COMMON.SUCCESS"));
								this.selection.clear();
								this.getData();
							});
						}
					});
				}
			}
		});
	}
	unblock(id: String) {
		this.commonlistingService.unblock({ id }, this.moduleNameServiceRoute).subscribe((data: any) => {
			if (!data) {
				this.toastr.error(`Somthing went Wrong`);
			} else {
				this.toastr.success("Unblocked Success");
				this.getData();
			}
		});
	}
	async getData() {
		if (this.source) {
			this.gridOption.filter.source = this.source;
		}
		if (this.moduleNameServiceRoute == "artiste" || this.moduleNameServiceRoute == "manage" || this.moduleNameServiceRoute == "customer") {
			localStorage.setItem(`${this.moduleNameServiceRoute}-filter`, JSON.stringify(this.gridOption));
		}
		// check if default filter and localstorage available and module is artiste then replace withj localstorage , "this.gridOption"
		// else store current filter in localstorage with module name.

		// /////////////////////////////   online talent obj move to first if below condtion true
		this.commonlistingService.searchAll(this.gridOption, this.moduleNameServiceRoute).subscribe(async (data: any) => {
			this.result = data;

			if (this.moduleNameServiceRoute == "earlyaccess" || this.moduleNameServiceRoute == "deactivate" || this.moduleNameServiceRoute == "search") {
				for (let index = 0; index < this.result.data.length; index++) {
					const element = this.result.data[index];
					if (element.userType == "01") {
						element.userType = "TALENT";
					}
					if (element.userType == "02") {
						element.userType = "OFFICALREPRESENTATIVE";
					}
					if (element.userType == "03") {
						element.userType = "INDUSTRYPROFESSIONAL";
					}
				}
			}
			if (this.moduleNameServiceRoute == "team") {
				for (let index = 0; index < this.result.data.length; index++) {
					const element = this.result.data[index];
					element.users = element.users[0] ? element.users[0] : { name: "Admin Error" };
				}
			}
			if (["customer", "manage", "artiste"].includes(this.moduleNameServiceRoute)) {
				let users: any = await this.getOnlineUser();
				if (users) {
					this.result.data.forEach((element) => {
						if (users.includes(element._id)) {
							element.online = 1;
						} else {
							element.online = 0;
						}
						if (element.showInSearch) {
							this.selectionSearch.toggle(element);
						}
						if (element.professions) {
							let professionArray = new Set(element.professions);
							let professionWithName = this.categoryArr.reduce((acc, obj) => {
								if (professionArray.has(obj._id)) {
									acc.push(obj.title);
								}
								return acc;
							}, []);
							element["professionName"] = professionWithName;
						}
					});
				}
			}

			this.dataSource = new MatTableDataSource(this.result.data);
			this.resultsLength = this.result.totalCount;
			this.cd.detectChanges();

		});
	}
	getOnlineUser() {
		return new Promise((resolve, reject) => {
			this.commonlistingService.getOnlineUser().subscribe((data) => {
				resolve(data);
			});
		});
	}
	setMaxDate() {
		const today = new Date();
		const year = today.getFullYear();
		const month = ("0" + (today.getMonth() + 1)).slice(-2);
		const day = ("0" + today.getDate()).slice(-2);
		this.maxDate = `${year}-${month}-${day}`;

		const tomorrow = new Date(today);
		tomorrow.setDate(today.getDate() + 1); // Set tomorrow to the next day
		const year2 = tomorrow.getFullYear();
		const month2 = ("0" + (tomorrow.getMonth() + 1)).slice(-2);
		const day2 = ("0" + tomorrow.getDate()).slice(-2);
		this.maxDateForTo = `${year2}-${month2}-${day2}`;
	}

	markAsRead(id: String) {
		this.commonlistingService.markAsRead({ _id: id, status: 0 }, this.moduleNameServiceRoute).subscribe((data: any) => {
			if (!data) {
				this.toastr.error(`Somthing went Wrong`);
			} else {
				this.toastr.success("Success");
				this.sharedService.triggerUpdate();
				this.getData();
			}
		});
	}
	viewnotifications(id: String) {
		let obj = this.result.data.find((e) => e._id == id);
		if (obj) {
			this.router.navigateByUrl(obj.link);
		}
	}

	convertDate(inputDate: any) {
		const date = new Date(inputDate);
		// Get year, month, and day
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
		const day = String(date.getDate()).padStart(2, "0");
		const formattedDate = `${year}-${month}-${day}`;
		return formattedDate;
	}

	applyFilter(filterValue: NgForm) {
		// return;
		// return;
		let filterData = filterValue.value;

		// this.gridOption.allrecords = false;
		let filterStatus = filterData.status;

		this.gridOption.limit = this.paginator.pageSize;
		this.gridOption.skip = this.paginator.pageSize * this.gridOption.page;
		this.gridOption.search = filterData.search;
		// delete filterData.search;  // remove search and status from original object
		// delete filterData.status;
		// tslint:disable-next-line: triple-equals
		let filterDataTest = Object.entries(filterData).reduce((a, [k, v]) => (v == null || v == "" || v == undefined ? a : { ...a, [k]: v }), {}); // delete null/undefined/blank from filter
		this.gridOption.filter = filterDataTest;

		delete this.gridOption.filter.search;
		// tslint:disable-next-line: triple-equals
		//if ((filterStatus == 0 || filterStatus == 1) && filterStatus) {
		if (this.filterArr.indexOf(Number(filterStatus)) > -1 && filterStatus != "2" && filterStatus != null) {
			this.gridOption.filter.status = Number(filterStatus);
			///////////////// add filter in profession after second time search button click ////////////////
			if (this.gridOption.filter.professions != undefined) {
				this.gridOption.filter.professions = { $in: this.gridOption.filter.professions };
			}
		} else {
			if (this.routeData && (this.routeData.modulename === "profilerequest" || this.routeData.modulename === "artiste" || this.routeData.modulename === "customer")) {
				this.gridOption.filter.status = { $in: [0, 1, 3, 4, 5] };

				if (this.gridOption.filter.professions != undefined) {
					this.gridOption.filter.professions = { $in: this.gridOption.filter.professions };
				}
			} else if (this.routeData && this.routeData.modulename === "chat") {
				this.gridOption.filter.status = { $in: filterStatus ? [filterStatus] : [1, 2, 3, 4] };
			} else if (this.routeData && this.routeData.modulename === "deactivate") {
				this.gridOption.filter.status = { $in: filterStatus ? [filterStatus] : [1, 2, 3] };
			}
			// else if (this.routeData && this.routeData.modulename === 'consolenotification') {
			//   this.gridOption.filter.status = { $in: filterStatus ? [filterStatus] : [1, 0] };
			// }
			else if (this.routeData && this.routeData.modulename === "notification") {
				this.gridOption.filter.status = { $in: [0, 1, 3, 4] };
			} else if (this.routeData && this.routeData.modulename === "manage") {
				this.gridOption.filter.status = { $in: [0, 1, 3, 4, 5] };
			} else if (this.routeData && this.routeData.modulename === "team" && filterStatus != "2") {
				this.gridOption.filter.status = { $in: [0, 1, 2, 3, 4] };
			} else if (this.routeData && this.routeData.modulename === "team" && filterStatus == "2") {
				this.gridOption.filter.status = { $in: [2] };
			}
			// else if (this.routeData && this.routeData.modulename === "managerinvite" && filterStatus == "2") {
			// 	this.gridOption.filter.status = { $in: [0, 1, 3, 4, null] };
			// } else if (this.routeData && this.routeData.modulename === "managerinvite" && filterStatus == "nan") {
			// 	this.gridOption.filter.status = { $in: [null] };
			// }
			else if (this.routeData && this.routeData.modulename === "managerinvite") {
				this.gridOption.filter.status = null;
			} else {
				this.gridOption.filter.status = { $in: [0, 1] };
			}
		}
		if (this.routeData && this.routeData.modulename === "chat" && filterStatus == "100") {
			this.gridOption.filter.status = { $in: [1, 2, 3, 4] };
		}
		if (this.routeData && this.routeData.modulename === "deactivate" && filterStatus == "100") {
			this.gridOption.filter.status = { $in: [1, 2, 3] };
		}
		if (this.routeData && this.routeData.modulename === "team" && filterStatus == "100") {
			this.gridOption.filter.status = { $in: [0, 1, 2, 3, 4] };
		}
		if (this.routeData && this.routeData.modulename === "feedback" && filterStatus == "100") {
			this.gridOption.filter.status = { $in: [1, 2, 3] };
		}

		//
		// if (this.routeData && this.routeData.modulename === 'consolenotification') {
		//   this.gridOption.filter.status = { $in: [1, 0] };
		// }

		this.paginator.pageIndex = 0;

		if (this.fromDate) {
			if (this.gridOption.filter.createdAt) {
				this.gridOption.filter.createdAt["$gte"] = new Date(`${this.fromDate}T00:00:00Z`);
			} else {
				this.gridOption.filter.createdAt = { $gte: new Date(`${this.fromDate}T00:00:00Z`) };
			}
			delete this.gridOption.filter.fromDate;
		}
		if (this.toDate) {
			if (this.gridOption.filter.createdAt) {
				this.gridOption.filter.createdAt["$lte"] = new Date(`${this.toDate}T23:59:59Z`);
			} else {
				this.gridOption.filter.createdAt = { $lte: new Date(`${this.toDate}T23:59:59Z`) };
			}
			delete this.gridOption.filter.toDate;
		}
		// new Date(`${formattedToday}T00:00:00Z`);
		this.getData();
	}

	resetFilter(filterForm: NgForm) {
		filterForm.reset();
		localStorage.removeItem(`${this.moduleNameServiceRoute}-filter`);
		this.gridOption.sortField = "_id";
		this.gridOption.sortOrder = "desc";
		this.applyFilter(filterForm);
	}
	addTime(dateString: string): string {
		const date = new Date(dateString);
		return this.formatDate(date);
	}
	formatDate(date: Date): string {
		const options: Intl.DateTimeFormatOptions = {
			hour: "numeric",
			minute: "numeric",
			hour12: true,
			day: "numeric",
			month: "short",
			year: "numeric",
		};
		return date.toLocaleDateString("en-US", options);
	}
	/** FILTRATION */
	filterConfiguration(filterValue): any {
		const filter: any = {};
		filter.name = filterValue;
		filter.status = this.gridOption.filter.status;
		return filter;
	}

	search() {
		if (this.source) {
			this.gridOption.filter.source = this.source;
		}
		setTimeout(() => {
			return this.commonlistingService.searchAll(this.gridOption, this.moduleNameServiceRoute);
		}, 0);
	}

	loadModuleList(statusValue: any) {
		this.selectedStatus = Number(statusValue);
		this.gridOption.limit = this.paginator.pageSize;
		this.gridOption.skip = this.paginator.pageSize * this.gridOption.page;
		if (this.selectedStatus === 0 || this.selectedStatus === 1) {
			this.gridOption.filter.status = Number(statusValue);
		} else {
			this.gridOption.filter.status = { $in: [0, 1] };
		}
		this.getData();
	}

	titleCase(str: any) {
		if (str) {
			let strAr = str.toLowerCase().split(" ");
			for (let i = 0; i < strAr.length; i++) {
				strAr[i] = strAr[i].charAt(0).toUpperCase() + strAr[i].slice(1);
			}
			return strAr.join(" ");
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	openProfileViewDialog(userId): void {
		const dialogRef = this.dialog.open(ArtisteProfileViewDialog, {
			width: "800px",
			panelClass: "icon-outside",
			data: { userId: userId },
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
			} else {
			}
		});
	}
}

export interface DialogData2 {
	category: Array<singleCategory>;
	profession: Array<string>;
}
export interface DialogData {
	userId: string;
}

@Component({
	selector: "artiste-view-dialog",
	templateUrl: "artiste-view.component.html",
	styleUrls: ["artiste-view.css"],
})
export class ArtisteProfileViewDialog {
	private subscription;
	useProfileViewFilter: string;
	paymentGraph2 = false;
	constructor(public dialogRef: MatDialogRef<ArtisteProfileViewDialog>, private dashboardService: DashboardService, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

	ngOnInit(): void {
		this.useProfileViewFilter = "m";
		this.getProfileView();
	}

	close(managerForm): void {
		this.dialogRef.close();
	}
	filterProfileView(filterBy) {
		this.useProfileViewFilter = filterBy;
		this.getProfileView();
	}
	getProfileView() {
		this.dashboardService.getArtisteProfileViews(this.useProfileViewFilter, this.data.userId).subscribe((response) => {
			var redData = response.data;
			if (response.status == 1 && redData.xAxis && redData.yAxis) {
				this.paymentGraph2 = true;
				Highcharts.chart("artisteProfileViews", {
					chart: {
						type: "column",
					},
					title: {
						text: "",
					},
					series: redData.yAxis,
					xAxis: { categories: redData.xAxis },
					yAxis: {
						min: 0,
						title: {
							text: "Profile View(s)",
						},
					},
					credits: {
						enabled: false,
					},
					tooltip: {
						headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
						pointFormat: '<tr><td style="color:{series.color};padding:0">Total User(s): </td>' + '<td style="padding:0"><b>{point.y}</b></td></tr>',
						footerFormat: "</table>",
						shared: true,
						useHTML: true,
					},
				});
				//this.cdr.detectChanges();
			}
		});
	}
}

export interface DialogData2 {
	category: Array<singleCategory>;
	profession: Array<string>;
}
@Component({
	selector: "artiste-list-profession-dialog",
	templateUrl: "artiste-filter-profession.component.html",
})
export class ArtisteProfessionDialogFilter {
	//artisteManager = new ArtisteManager();
	private subscription;
	private addSuccess: EventEmitter<boolean> = new EventEmitter();
	emailError: any = "";
	otpDisabled = false;
	newCategoryArr: Array<singleCategory>;
	private addManager: EventEmitter<boolean> = new EventEmitter();

	constructor(public dialogRef: MatDialogRef<ArtisteProfessionDialogFilter>, private toastr: ToastrService, private cdr: ChangeDetectorRef, @Inject(MAT_DIALOG_DATA) public data: DialogData2) {}
	ngOnInit() {
		this.formateCategoryWithParent(JSON.parse(JSON.stringify(this.data.category)))
			.then((data: singleCategory[]) => {
				this.newCategoryArr = data;
			})
			.catch((err) => {});
	}

	save(): void {}
	// data: { category: this.categoryArr, profession: this.artisteModel.professions },
	formateCategoryWithParent(array: Array<singleCategory>) {
		return new Promise((resolve, reject) => {
			try {
				let newarray: Array<singleCategory> = [];
				for (let index = 0; index < array.length; index++) {
					const element = array[index];
					if (element.title === "Other") continue;
					if (this.data.profession && this.data.profession.includes(element._id)) {
						element.selected = true;
					} else {
						element.selected = false;
					}
					if (!element.parent) {
						const tempIndex = newarray.findIndex((item) => item._id === element.parent);
						if (!tempIndex) newarray.push(element);
					} else if (element.parent != "") {
						const tempIndex = newarray.findIndex((item) => item._id === element.parent);
						let tempObj = newarray[tempIndex];
						if (tempObj) {
							if (tempObj.subcategory) {
								tempObj.subcategory.push(element);
							} else {
								tempObj.subcategory = [element];
							}
							newarray[tempIndex] = tempObj;
						} else {
							let copy = JSON.parse(JSON.stringify(this.data.category));
							const futureElement = copy.find((item: singleCategory) => item._id === element.parent);
							if (futureElement) {
								futureElement.subcategory = [element];
								newarray.push(futureElement);
							}
						}
					}
				}
				newarray.sort((a, b) => {
					if (a.title === "Main") {
						return -1; // 'Main' should come first
					} else if (b.title === "Main") {
						return 1; // 'Main' should come before other elements
					} else if (a.title > b.title) {
						return 1; // Indicates a should come after b
					} else if (a.title < b.title) {
						return -1; // Indicates a should come before b
					} else {
						return 0; // Indicates they are equal in terms of sorting
					}
				});

				// console.log(newarray)
				resolve(newarray);
			} catch (error) {
				reject(error);
			}
		});
	}
	handleCategoryClick(id: string, selected: boolean, childIndex: number, parentIndex: number) {
		this.newCategoryArr[parentIndex].subcategory[childIndex].selected = !selected;
		if (selected) {
			this.data.profession = this.data.profession.filter((e) => e != id);
		} else {
			//add
			if (this.data.profession) {
				this.data.profession.push(id);
			} else {
				this.data.profession = [id];
			}
		}
	}
	onInputChange(event: Event) {
		// You can access the input value using event.target.value
		const inputValue = (event.target as HTMLInputElement).value;

		// Perform your desired actions with the input value
		// console.log('Input value:', inputValue, this.categoryArr);
		if (inputValue == "") {
			this.formateCategoryWithParent(JSON.parse(JSON.stringify(this.data.category))).then((data: singleCategory[]) => {
				this.newCategoryArr = data;
			});
			return;
		}

		let array = JSON.parse(JSON.stringify(this.data.category));
		let newarray22: Array<singleCategory> = [];
		for (let index = 0; index < array.length; index++) {
			const element = array[index];
			if (element.title === "Other") continue;

			if (this.data.profession.includes(element._id)) {
				element.selected = true;
			} else {
				element.selected = false;
			}
			if (!element.parent) {
				const tempIndex = newarray22.findIndex((item) => item._id === element.parent);
				if (!tempIndex) newarray22.push(element);
			} else if (element.parent != "" && element.title.toLowerCase().includes(inputValue.toLowerCase())) {
				const tempIndex = newarray22.findIndex((item) => item._id === element.parent);
				let tempObj = newarray22[tempIndex];
				if (tempObj) {
					if (tempObj.subcategory) {
						tempObj.subcategory.push(element);
					} else {
						tempObj.subcategory = [element];
					}
					newarray22[tempIndex] = tempObj;
				} else {
					let copy = JSON.parse(JSON.stringify(this.data.category));
					const futureElement = copy.find((item: singleCategory) => item._id === element.parent);
					if (futureElement) {
						futureElement.subcategory = [element];
						newarray22.push(futureElement);
					}
				}
			}
		}
		newarray22.sort((a, b) => {
			if (a.title === "Main") {
				return -1; // 'Main' should come first
			} else if (b.title === "Main") {
				return 1; // 'Main' should come before other elements
			} else if (a.title > b.title) {
				return 1; // Indicates a should come after b
			} else if (a.title < b.title) {
				return -1; // Indicates a should come before b
			} else {
				return 0; // Indicates they are equal in terms of sorting
			}
		});
		this.newCategoryArr = newarray22;
		this.newCategoryArr = this.newCategoryArr.filter((e) => e.subcategory && e.subcategory.length > 0);

		this.cdr.detectChanges();
	}
	onKeyDown(event: KeyboardEvent, value: string) {
		if (event.key === "Backspace" && value.length <= 1) {
			this.formateCategoryWithParent(JSON.parse(JSON.stringify(this.data.category))).then((data: singleCategory[]) => {
				this.newCategoryArr = data;
			});
			// let value = document.getElementById("catageorySearchButton").value;
		}
	}
	ngOnDestroy(): void {
		this.newCategoryArr = [];
		this.dialogRef.close(this.data.profession);
	}
}
