// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
// RxJS
import { Observable,throwError } from 'rxjs';
import { tap,catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { LoaderService } from '../../layout/services/loader.service';
import { AuthService } from '../../../modules/auth/service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { LanguageService } from '../../../../core/common/services/language.service';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
	private requests: HttpRequest<any>[] = [];
	// intercept request and add token
	constructor(public loaderService: LoaderService,
		public authService: AuthService,
		public toastr: ToastrService,
		private languageService: LanguageService,
		) { }
		removeRequest(req: HttpRequest<any>) {
			const i = this.requests.indexOf(req);
			if (i >= 0) {
				this.requests.splice(i, 1);
			}
			this.loaderService.display(this.requests.length > 0);
		}
		intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
			//localStorage.setItem('language','{"_id":"5d43de669e156d1334c064ff","locale":"en","name":"English","active":true}');
			if (req.body instanceof FormData) {
				req = req.clone({
					setHeaders: {
						'Authorization': localStorage.getItem('authToken'),
						//'language': localStorage.getItem('language')
					}
				});
			} else {
				// tslint:disable-next-line: max-line-length				
				if (localStorage.getItem('authToken') && localStorage.getItem('authToken') !== null && localStorage.getItem('authToken') !== undefined) {
					req = req.clone({
						setHeaders: {
							'Content-Type': 'application/json',
							'Authorization': localStorage.getItem('authToken'),
							//'language': localStorage.getItem('language')
						}
					});
				} else {
					req = req.clone({
						setHeaders: {
							'Content-Type': 'application/json',
							// 'language': localStorage.getItem('language')
						}
					});
				}
			}
			this.requests.push(req);
			this.loaderService.display(true);
			return Observable.create(observer => {
				const subscription = next.handle(req)
					.subscribe(
						event => {
							if (event instanceof HttpResponse) {
								this.removeRequest(req);
								observer.next(event);
							}
						},
						err => {
							this.removeRequest(req);
							console.log('error',err);
							if (err && err.status === 401) {
								this.authService.logout();
							}
							observer.error(err);
						},
						() => {
							this.removeRequest(req);
							observer.complete();
						});
				// remove request from queue when cancelled
				return () => {
					this.removeRequest(req);
					subscription.unsubscribe();
				};
			});
	}
}
