import { BaseModel } from '../../../../../../core/base/crud';
import { Address } from './address.model';

export class User extends BaseModel {
    id: number;
    _id: number;
    username: string;
    password: string;
    email: string;
    accessToken: string;
    roles: number[];
    pic: string;
    name: string;
	address: Address;
    status: any;
    new_password: string;
    confirmPassword: string;
    items: any;
    contact_no: string;
    usergroup:any = null;
    userType:number;
    about:string;
    otpCode: string;
    professions: any = null;
    socialLink:any;
    pressKit:any;
    management:any;
    managementConnect:any;

    clear(): void {
        this.id = undefined;
        this._id = undefined;
        this.username = '';
        this.password = '';
        this.email = '';
        this.roles = [];
        this.name = '';
        this.accessToken = 'access-token-' + Math.random();
        this.pic = './assets/media/users/user4.jpg';
        this.contact_no = '';
        this.address = new Address();
        this.address.clear();
        this.new_password = '';
        this.confirmPassword = '';
        this.name = '';
        this.userType = 1;
        this.managementConnect = '';
        this.socialLink = '';
    }
}
