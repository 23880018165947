import { Injectable } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { CommonService } from '../../../../../src/app/core/common/services/common.service';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class LanguageService extends CommonService {
  languageArr: any = [];
  token: any;
  tokenData: any;
  constructor(http: HttpClient) {
    super('language',http);
}


getLanguage() {
         this.token = localStorage.getItem('authToken');
         this.tokenData = jwt_decode( this.token);
         this.languageArr = this.tokenData.language;
         return this.languageArr;
}
}
