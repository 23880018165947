import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Input, Injector, ComponentFactoryResolver, HostListener } from "@angular/core";
import { CsTableResource } from "./utils/custom-table-source";
@Component({
	selector: "kt-listing",
	templateUrl: "./listing.component.html",
	styleUrls: ["./listing.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListingComponent implements OnInit, OnDestroy {
	@Input() columnsConfig;
	@Input() getData;
	@Input() handleAdd: any;
	@Input() export: any;

	dropdownVisible: boolean = false;
	openFilter: boolean = false;
	showAdvanceSearch: boolean = false;
	tableData = [];

	// handle column resize fn
	private startX: number;
	private startWidth: number;
	private isResizing = false;
	private currentResizingElement: HTMLElement | null = null;
	tableObj = new CsTableResource(null, 0);
	private debounceTimer;

	// default status dropdown
	// TODO: changes to component level
	defaultStatus = [
		{ value: [0, 1, 2, 3, 4, 5], key: "All", color: "#1a1c3a" },
		{ value: 1, key: "Active", color: "#2e7de0" },
		{ value: 3, key: "Requested", color: "#8184ff" },
		{ value: 4, key: "Incomplete", color: "#ffcd17" },
		{ value: 5, key: "Reject", color: "#e05252" },
		{ value: 0, key: "Inactive", color: "#b8bbc6" },
	];

	constructor(private cdr: ChangeDetectorRef, private injector: Injector, private resolver: ComponentFactoryResolver) {}

	async ngOnInit() {
		let data = await this.getData();
		this.tableObj = new CsTableResource(data.filter, data.totalCount);
		this.tableData = data.data;
		this.cdr.detectChanges();
	}

	/**
	 * used this fn to get data of row and pass as parameter to assign component.
	 * @param dataFields
	 * @param data
	 * @returns
	 */
	getTemplateData(dataFields, data) {
		let result = {};
		if (dataFields) {
			Object.keys(dataFields).forEach((element) => {
				result[element] = data[dataFields[element]];
			});
		}
		result["id"] = data["_id"];
		return result;
	}

	/**
	 * used fn to call getData api and set table configuration
	 * @param filter
	 */

	async getTableData(filter) {
		filter = { ...filter, limit: this.tableObj.displayRecords, sortField: this.tableObj.sortField, sortOrder: this.tableObj.sortOrder, skip: this.tableObj.skipRecords };
		let data = await this.getData(filter);
		this.tableData = data.data;
		this.tableObj.updatePagination(data.totalCount);
		this.tableObj.updateFilter(data.filter);
		this.cdr.detectChanges();
	}

	// filter functions
	onInputChange(e) {
		this.tableObj.updateFilter({ ...this.tableObj.filter, search: e.target.value });
		clearTimeout(this.debounceTimer);
		this.debounceTimer = setTimeout(() => {
			this.getTableData(this.tableObj.filter);
		}, 300);
	}

	toggleFilter() {
		this.openFilter = !this.openFilter;
	}

	openAdvanceSearch() {
		this.showAdvanceSearch = !this.showAdvanceSearch;
	}

	/**
	 * handle dropdown selection
	 * @param option
	 */
	// TODO: create dynamic selection with key and value
	handleDropdownSearch(option) {
		let filter = { ...this.tableObj.filter, filter: { ...this.tableObj.filter.filter, status: option.value } };
		if (Array.isArray(option.value)) {
			filter.filter.status = { $in: option.value };
		}
		this.tableObj.updateFilter(filter);
	}

	resetFilter() {
		this.toggleFilter();
		this.tableObj.resetValue();
		// TODO: change filter status based on component, at parent level handle this change.
		this.getTableData({ ...this.tableObj.filter, filter: {}, search: null });
	}

	/**
	 *	use fn to create filter based on input in advance search
	 */
	applyFilter() {
		this.toggleFilter();
		this.getTableData(this.tableObj.filter);
	}

	/**
	 * handle sort : ascending or descending
	 * @param field
	 */
	handleSort(field) {
		if (field == this.tableObj.sortField && this.tableObj.sortOrder == "desc") {
			this.tableObj.sortOrder = "asc";
		} else {
			this.tableObj.sortField = field;
			this.tableObj.sortOrder = "desc";
		}
		this.getTableData(this.tableObj.filter);
	}

	onMouseDown(event: MouseEvent): void {
		this.initializeResize(event);
	}

	onMouseMove = (event: MouseEvent): void => {
		if (this.isResizing) {
			this.resizeColumn(event);
		}
	};

	onMouseUp = (): void => {
		this.endResize();
	};

	// /** Initialize resizing parameters and set up event listeners */
	private initializeResize(event: MouseEvent): void {
		this.startX = event.pageX;
		this.startWidth = 200;
		this.isResizing = true;

		const targetElement = event.target as HTMLElement;
		const parentElement = targetElement.parentElement;
		if (parentElement.style.width && parentElement.style.width.replace("px", "")) {
			this.startWidth = parseFloat(parentElement.style.width.replace("px", ""));
		}
		// Attach listeners to the specific target element to avoid affecting the whole document
		document.addEventListener("mousemove", this.onMouseMove);
		document.addEventListener("mouseup", this.onMouseUp);
		this.currentResizingElement = parentElement;
	}

	/** Adjust column width dynamically based on mouse movement */
	private resizeColumn(event: MouseEvent): void {
		const newWidth = this.startWidth + (event.pageX - this.startX);
		this.currentResizingElement.style.width = newWidth + "px";
		const elements = document.querySelectorAll(`[cellid='${this.currentResizingElement.id}']`);
		elements.forEach((e) => {
			(e as HTMLElement).style.width = `${newWidth}px`;
		});
	}

	/** Cleanup event listeners after resizing is done */
	private endResize(): void {
		this.isResizing = false;
		// Remove listeners from the specific element
		if (this.currentResizingElement) {
			// this.currentResizingElement.removeEventListener("mousemove", this.onMouseMove);
			document.body.removeEventListener("mouseup", () => {});
			document.body.removeEventListener("mousemove", () => {});
		}
	}

	// handle pagination
	toggleDropdown() {
		this.dropdownVisible = !this.dropdownVisible;
	}

	// close data limit selection when click out side dropdown ///////
	@HostListener("document:click", ["$event"])
	onDocumentClick(event: MouseEvent) {
		const clickedElement = event.target as HTMLElement;
		const isInsideDropdown = clickedElement.closest(".dropdown-container");

		// Close the dropdown if clicked outside
		if (!isInsideDropdown) {
			this.dropdownVisible = false;
		}
	}

	// Function to select an option and hide the dropdown
	selectOption(option: number) {
		this.tableObj.displayRecords = option;
		this.dropdownVisible = false;
		this.getTableData(this.tableObj.filter);
	}

	handleBackPageination() {
		if (this.tableObj.skipRecords > 0) {
			if (this.tableObj.skipRecords - this.tableObj.displayRecords >= 0) {
				this.tableObj.skipRecords = this.tableObj.skipRecords - this.tableObj.displayRecords;
				this.getTableData(this.tableObj.filter);
			} else {
				this.tableObj.skipRecords = 0;
				this.getTableData(this.tableObj.filter);
			}
		}
	}

	handleSkipPageination() {
		if (this.tableObj.skipRecords + this.tableObj.displayRecords < this.tableObj.totalRecords) {
			this.tableObj.skipRecords = this.tableObj.skipRecords + this.tableObj.displayRecords;
			this.getTableData(this.tableObj.filter);
		}
	}

	ngOnDestroy() {
		// this.subscription.unsubscribe();
	}
}
