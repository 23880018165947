// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS

import * as jwt_decode from 'jwt-decode';



@Injectable()
export class ArtisteauthGuard implements CanActivate {
    constructor(private router: Router) { }
    userData: any;
    token: any;
    tokenData: any;
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)   {
        // return ;
        /*return this.store
            .pipe(
                select(isLoggedIn),
                tap(loggedIn => {
                    if (!loggedIn) {
                        this.router.navigateByUrl('/auth/login');
                    }
                })
            );*/
            this.token = localStorage.getItem('authToken');                        
            if (this.token) {
                this.tokenData = jwt_decode( this.token);                
                if(this.tokenData && this.tokenData.userType == '01' && this.tokenData.status == 1){
                    return true;
                }else{
                    this.router.navigate(['/access-forbidden']);
                    return false;
                }                
            } else {
                this.router.navigate(['/auth/login']);
                return false;
            }
    }
}