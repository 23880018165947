// USA

const projectname = 'ATL';

export const locale = {
	lang: 'in',
	data: {
		UPDATE: 'अपडेट करें',
		LIST: 'सूची',
		HOME: 'घर',
		ADD: 'जोड़ें',
		NAME: 'नाम',
		ADSH: 'Adsh ',
		CIK: 'CIK ',
		SIC: 'इस प्रकार',
		COUNTRY: 'देश',
		STPRBA: 'राज्य',
		CITY: 'शहर ',
		ZIP: 'ज़िप ',
		BAS1: 'बीएएस 1',
		BAS2: 'बीएएस 2',
		COUNTRY_ID: 'आईडी',
		Usergroup: 'यूजर ग्रुप',
		QUESTION_TYPE: 'प्रश्न प्रकार',
		ANSWER_INPUT: 'उत्तर इनपुट',
		ANSWER_TYPE: 'उत्तर प्रकार',
		ANSWER_OPTIONS: 'उत्तर विकल्प',
		QUESTION_TEXT: 'सवाल पाठ',
		DESCRIPTION: 'विवरण',
		FIRSTNAME: 'पहला नाम',
		LASTNAME: 'पिछले नाम',
		EMAIL: 'ईमेल',
		PHONE: 'फ़ोन',
		COUNTRY_CODE: 'देश कोड',
		REGIONS: 'क्षेत्र',
		STATES: 'राज्य',
		CITIES: 'शहर ',
		LOCALE: 'स्थानीय',
		COUNTRIES: 'देश',
		VALUES: 'मूल्य',
		SETTINGTYPES: 'सेटिंग टाइप',
		CONTACT_NO: 'मोबाइल नंबर',
		USERGROUPS: 'यूजर ग्रुप',
		TITLE: 'शीर्षक',
		SUBJECT: 'विषय',
		USERS: 'उपयोगकर्ता',
		ZONES: 'क्षेत्र',
		WARD: 'परवरिश',
		CONVENIENCES: 'जन सुविधा का नाम',
		TRANSLATOR: {
			SELECT: 'अपनी भाषा का चयन करें',
		},
		MENU: {
			NEW: 'नया',
			ACTIONS: 'क्रियाएँ',
			CREATE_POST: 'नई पोस्ट बनाएँ',
			PAGES: 'पेज',
			FEATURES: 'विशेषताएं',
			APPS: 'ऐप्स',
			DASHBOARD: 'डैशबोर्ड',
			ASSIGNMENT: 'असाइनमेंट लिस्ट',
			USERS: 'उपयोगकर्ता',
			MASTERS: 'मास्टर्स',
			MODULES : 'मॉड्यूल',
			COMPANY: 'कंपनी',
			REGION: 'क्षेत्र',
			COUNTRY: 'देश',
			STATE: 'राज्य',
			CITY: 'शहर ',
			DISTRICT: 'जिला',
			ZONE: 'क्षेत्र',
			TAG: 'टैग',
			LANGUAGE: 'भाषा: हिन्दी',
			USERMANAGEMENT: 'उपयोगकर्ता प्रबंधन',
			USERGROUP: 'यूजर ग्रुप',
			PACKAGE: 'पैकेज',
			SEC: 'एसईसी ',
			GLOBALSUCCESS: 'वैश्विक सफलता',
			PDF: 'पीडीएफ',
			FIELDGROUPMAPPING: 'फील्ड ग्रुप मैपिंग',
			STATEMENTTYPENAME: 'स्टेटमेंट टाइप',
			FIELDMAPPING: 'फील्ड मैपिंग',
			QUESTIONNAIRETOOL: 'प्रश्नावली उपकरण',
			GLOBALSETTING: 'वैश्विक सेटिंग',
			SETTING: 'स्थापना',
			SETTINGTYPE: 'सेटिंग प्रकार',
			ACCESSLOGS : 'प्रवेश लॉग',
			SURVEYMANAGEMENT: 'सर्वेक्षण प्रबंधन',
			PUBLICCONVENIENCE: 'सार्वजनिक सुविधा',
			EMAILTEMPLATE: 'ईमेल टेम्प्लेट',
			QUESTIONS: 'प्रशन',
			CATEGORY: 'वर्ग',
			MENU: 'मेन्यू'
		},
		AUTH: {
			GENERAL: {
				OR: 'या',
				SUBMIT_BUTTON: 'प्रस्तुत',
				NO_ACCOUNT: 'खाता नहीं है?',
				SIGNUP_BUTTON: 'साइन अप करें',
				FORGOT_BUTTON: 'पासवर्ड भूल गए?',
				BACK_BUTTON: 'वापस',
				PRIVACY: 'गोपनीयता',
				LEGAL: 'कानूनी',
				CONTACT: 'संपर्क करें',
			},
			LOGIN: {
				TITLE: 'लॉग इन करें',
				BUTTON: 'साइन इन करें',
				SUCCESSMESSAGE: 'सफलतापूर्वक लॉग इन',
			},
			FORGOT: {
				TITLE: 'पासवर्ड भूल गए',
				DESC: 'अपना पासवर्ड रीसेट करने के लिए अपना ईमेल प्रविष्ट करें',
				SUCCESS: 'लिंक आपके पंजीकृत ईमेल पर भेज दिया गया है, जिसका उपयोग करके आप अपना पासवर्ड रीसेट कर सकते हैं।'
			},
			SETPASSWORD: {
				TITLE: 'पासवर्ड सेट करें',
				BUTTON: 'रीसेट',
				SUCCESS: 'पासवर्ड सफलतापूर्वक बदल दिया गया है'
			},
			REGISTER: {
				TITLE: 'साइन अप करें',
				DESC: 'अपना खाता बनाने के लिए अपना विवरण दर्ज करें',
				SUCCESS: 'आपका खाता सफल पंजीकृत हो गया है।'
			},
			INPUT: {
				EMAIL: 'ईमेल',
				FULLNAME: 'पूरा नाम',
				PASSWORD: 'कुंजिका',
				CONFIRM_PASSWORD: 'पासवर्ड की पुष्टि कीजिये',
				USERNAME: 'उपयोगकर्ता नाम',
				TITLE: 'शीर्षक दर्ज करें',
				REMEMBER_ME: 'मुझे याद रखना',
			},
			VALIDATION: {
				INVALID: '{{ name }} वैध नहीं है',
				REQUIRED: '{{ name }} आवश्यक है',
				MIN_LENGTH: '{{ name }} न्यूनतम लंबाई {{ min }} है',
				AGREEMENT_REQUIRED: 'नियम और शर्तों को स्वीकार करना आवश्यक है',
				NOT_FOUND: 'अनुरोधित {{ name }} नहीं मिला है',
				INVALID_LOGIN: 'लॉगिन विवरण गलत है',
				REQUIRED_FIELD: 'आवश्यक क्षेत्र',
				MIN_LENGTH_FIELD: 'न्यूनतम फ़ील्ड लंबाई:',
				MAX_LENGTH_FIELD: 'अधिकतम फ़ील्ड लंबाई:',
				INVALID_FIELD: 'फ़ील्ड मान्य नहीं है',
				MUST_MATCH: 'पासवर्ड और पासवर्ड की पुष्टि करें मिलान'
			}
		},
		PAGES: {
			MODULES: {
				LISTINGTITLE: projectname + ' |मॉड्यूल सूची',
				ADDTITLE: projectname + ' |मॉड्यूल जोड़ें',
				EDITTITLE: projectname + ' |मॉड्यूल अद्यतन',
				MODULESLIST : 'मॉड्यूल सूची ',
				MODULENAMEPLURAL : 'मॉड्यूल ',
				MODULENAMESINGULAR : 'मापांक',
				NEWMODULE : 'नया मॉड्यूल ',
				DELETEDSELECTED : 'चयनित मॉड्यूल हटाएं ',
				UPDATESELECTED : 'चयनित मॉड्यूल के लिए अद्यतन स्थिति ',
				ADDNEW : 'नया मॉड्यूल जोड़ें ',
				UPDATE : 'अद्यतन मॉड्यूल ',
				CREATEMESSAGE : 'मॉड्यूल को सफल बनाएं ',
				UPDATEMESSAGE : 'मॉड्यूल को सफलतापूर्वक अपडेट करें ',
				EDIT: 'मॉड्यूल संपादित करें ',
				ADD: 'मॉड्यूल जोड़ें ',
			},
			REGION: {
				LISTINGTITLE: projectname + ' |क्षेत्र सूची',
				ADDTITLE: projectname + ' |क्षेत्र जोड़ें',
				EDITTITLE: projectname + ' |क्षेत्र का अद्यतन',
				REGIONLIST : 'क्षेत्र सूची ',
				REGIONNAMEPLURAL : 'क्षेत्रों ',
				REGIONNAMESINGULAR : 'क्षेत्र',
				NEWREGION : 'नया क्षेत्र ',
				DELETEDSELECTED : 'चयनित क्षेत्र हटाएं ',
				UPDATESELECTED : 'चयनित क्षेत्रों के लिए अद्यतन स्थिति ',
				ADDNEW : 'नया क्षेत्र जोड़ें ',
				UPDATE : 'क्षेत्र अपडेट करें',
				CREATEMESSAGE : 'क्षेत्र को सफलतापूर्वक बनाएं ',
				UPDATEMESSAGE : 'क्षेत्र को सफलतापूर्वक अपडेट करें ',
				EDIT: 'क्षेत्र संपादित करें ',
				ADD: 'क्षेत्र जोड़ें ',
				REGION_NAME: 'क्षेत्र',
				REGION_ERROR: 'कृपया क्षेत्र निर्दिष्ट करें ',
				PATTERN: {
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
				}
			},
			COUNTRY: {
				LISTINGTITLE: projectname + ' |देश सूची',
				ADDTITLE: projectname + ' |देश जोड़ें',
				EDITTITLE: projectname + ' |देश अपडेट',
				COUNTRYLIST : 'देशों की सूची ',
				COUNTRYNAMEPLURAL : 'देश ',
				COUNTRYNAMESINGULAR : 'देश ',
				NEWCOUNTRY : 'नया देश',
				DELETEDSELECTED : 'चयनित देश हटाएं ',
				UPDATESELECTED : 'चयनित देशों के लिए अद्यतन स्थिति',
				ADDNEW : 'नया देश जोड़ें ',
				UPDATE : 'अद्यतन देश ',
				CREATEMESSAGE : 'देश को सफल बनाएं ',
				UPDATEMESSAGE : 'देश को सफलतापूर्वक अपडेट करें ',
				EDIT: 'देश संपादित करें ',
				ADD: 'देश जोड़ें ',
				COUNTRY_CODE: 'देश कोड',
				COUNTRY_NAME: 'देश ',
				COUNTRY_ERROR: 'कृपया देश निर्दिष्ट करें ',
				PATTERN: {
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
				}
			},
			SETTING: {
				LISTINGTITLE: projectname + ' |सेटिंग सूची',
				ADDTITLE: projectname + ' |सेटिंग जोड़ें',
				EDITTITLE: projectname + ' |अद्यतन स्थापित करना',
				SETTINGLIST : 'सेटिंग्स सूची ',
				SETTINGNAMEPLURAL : 'समायोजन',
				SETTINGNAMESINGULAR : 'स्थापना',
				NEWSETTING : 'नई सेटिंग ',
				DELETEDSELECTED : 'चयनित सेटिंग हटाएं ',
				UPDATESELECTED : 'चयनित सेटिंग्स के लिए अद्यतन स्थिति ',
				ADDNEW : 'नई सेटिंग जोड़ें ',
				UPDATE : 'सेटिंग अपडेट करें',
				CREATEMESSAGE : 'सफलतापूर्वक सेटिंग बनाएँ ',
				UPDATEMESSAGE : 'अद्यतन सेटिंग सफलतापूर्वक ',
				EDIT: 'सेटिंग संपादित करें ',
				ADD: 'सेटिंग जोड़ें ',
				VALUES: 'मान ',
				VALIDATION: {
					SELECTTYPE: 'कृपया सेटिंग प्रकार चुनें '
				}
			},
			SETTINGTYPE: {
				LISTINGTITLE: projectname + ' |प्रकार सूची की स्थापना',
				ADDTITLE: projectname + ' |सेटिंग प्रकार जोड़ें',
				EDITTITLE: projectname + ' |प्रकार अद्यतन स्थापित करना',
				SETTINGTYPELIST : 'सेटिंग्स प्रकार सूची ',
				SETTINGTYPENAMEPLURAL : 'सेटिंग प्रकार ',
				SETTINGTYPENAMESINGULAR : 'सेटिंग ',
				NEWSETTINGTYPE : 'नई सेटिंग प्रकार ',
				DELETEDSELECTED : 'चयनित सेटिंग प्रकार हटाएं ',
				UPDATESELECTED : 'चयनित सेटिंग प्रकारों के लिए अद्यतन स्थिति ',
				ADDNEW : 'नया सेटिंग प्रकार जोड़ें ',
				UPDATE : 'अद्यतन सेटिंग प्रकार ',
				CREATEMESSAGE : 'सेटिंग प्रकार को सफलतापूर्वक बनाएं ',
				UPDATEMESSAGE : 'अद्यतन सेटिंग प्रकार सफलतापूर्वक ',
				EDIT: 'एडिटिंग सेटिंग टाइप ’',
				ADD: 'सेटिंग टाइप करें ',
				VALIDATION: {
					SELECTTYPE: 'कृपया प्रकार चुनें '
				}
			},
			STATE: {
				LISTINGTITLE: projectname + ' |राज्य सूची',
				ADDTITLE: projectname + ' |राज्य जोड़ें',
				EDITTITLE: projectname + ' |राज्य अद्यतन',
				STATELIST : 'राज्यों की सूची',
				STATENAMEPLURAL : 'राज्य',
				STATENAMESINGULAR : 'राज्य',
				NEWSTATE : 'नया राज्य',
				DELETEDSELECTED : 'चयनित देश हटाएं',
				UPDATESELECTED : 'चयनित देशों के लिए अद्यतन स्थिति',
				ADDNEW : 'नया राज्य जोड़ें',
				UPDATE : 'अद्यतन स्थिति ',
				CREATEMESSAGE : 'राज्य सफलतापूर्वक बनाएं',
				UPDATEMESSAGE : 'राज्य को सफलतापूर्वक अपडेट करें',
				EDIT: 'राज्य संपादित करें',
				ADD: 'राज्य जोड़ें',
				STATE_NAME: 'स्थिति',
				STATE_ERROR: 'कृपया राज्य निर्दिष्ट करें',
				DRAG_FROM_LEFT: 'रिकॉर्ड जोड़ने के लिए बाएं और नीचे से खींचें',
				PATTERN: {
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",

				}
			},
			TEMPLATE: {
				LISTINGTITLE: projectname + ' |ईमेल टेम्प्लेट सूची ',
				ADDTITLE: projectname + ' |ईमेल टेम्पलेट जोड़ें ',
				EDITTITLE: projectname + ' |ईमेल टेम्प्लेट अपडेट ',
				TEMPLATELIST : 'ईमेल टेम्प्लेट सूची',
				TEMPLATENAMEPLURAL: 'ईमेल टेम्पलेट',
				TEMPLATENAMESINGULAR: 'ईमेल टेम्पलेट',
				NEWTEMPLATE: 'नया ईमेल टेम्पलेट',
				DELETEDSELECTED : 'चयनित देश हटाएं',
				UPDATESELECTED : 'चयनित देशों के लिए अद्यतन स्थिति',
				ADDNEW: 'नया ईमेल टेम्पलेट जोड़ें',
				UPDATE: 'ईमेल टेम्प्लेट अपडेट करें',
				CREATEMESSAGE: 'ईमेल टेम्प्लेट को सफलतापूर्वक बनाएं',
				UPDATEMESSAGE: 'ईमेल टेम्प्लेट को सफलतापूर्वक अपडेट करें',
				EDIT: 'ईमेल टेम्पलेट संपादित करें',
				ADD: 'ईमेल टेम्पलेट जोड़ें',
				TEMPLATE_NAME: 'ईमेल टेम्पलेट',
				TEMPLATE_ERROR: 'कृपया ईमेल टेम्पलेट निर्दिष्ट करें',
				DRAG_FROM_LEFT: 'बाएं से खींचें और रिकॉर्ड जोड़ने के लिए नीचे छोड़ें',
				TITLE : 'शीर्षक',
				PATTERN: {
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
				}
			},
			CITY: {
				LISTINGTITLE: projectname + ' |शहर की सूची',
				ADDTITLE: projectname + ' |शहर जोड़ें',
				EDITTITLE: projectname + ' |सिटी अपडेट',
				CITYLIST : 'शहरों की सूची ',
				CITYNAMEPLURAL : 'शहरों',
				CITYNAMESINGULAR : 'शहर',
				NEWCITY : 'नया शहर',
				DELETEDSELECTED : 'चयनित देश हटाएं ',
				UPDATESELECTED : 'चयनित देशों के लिए अद्यतन स्थिति',
				ADDNEW : 'नया शहर जोड़ें ',
				UPDATE : 'अपडेट सिटी ',
				CREATEMESSAGE : 'शहर को सफल बनाएं ',
				UPDATEMESSAGE : 'शहर को सफलतापूर्वक अपडेट करें ',
				EDIT: 'शहर संपादित करें ',
				ADD: 'शहर जोड़ें ',
				CITY_NAME: 'शहर',
			},
			CONVENIENCE: {
				LISTINGTITLE: projectname + ' | सार्वजनिक सुविधा सूची ',
				ADDTITLE: projectname + ' | सार्वजनिक सुविधा जोड़ें ',
				EDITTITLE: projectname + ' | सार्वजनिक सुविधा अपडेट ',
				CONVENIENCELIST: 'सार्वजनिक सुविधा सूची',
				CONVENIENCENAMEPLURAL: 'सार्वजनिक सुविधा',
				CONVENIENCENAMESINGULAR: 'जनता की सुविधा',
				NEWCONVENIENCE: 'नई सार्वजनिक सुविधा',
				DELETEDSELECTED: 'चयनित देश हटाएं',
				UPDATESELECTED: 'चयनित देशों के लिए अद्यतन स्थिति',
				ADDNEW: 'नई सार्वजनिक सुविधा जोड़ें',
				UPDATE: 'अद्यतन सार्वजनिक सुविधा',
				CREATEMESSAGE: 'जन सुविधा सफलतापूर्वक बनाएं',
				UPDATEMESSAGE: 'सार्वजनिक सुविधा को सफलतापूर्वक अपडेट करें',
				EDIT: 'सार्वजनिक सुविधा संपादित करें',
				ADD: 'सार्वजनिक सुविधा जोड़ें',
			},
			DISTRICT: {
				LISTINGTITLE: projectname + ' |जिला सूची',
				ADDTITLE: projectname + ' |जिला जोड़ें',
				EDITTITLE: projectname + ' |जिला अद्यतन',
				DISTRICTLIST: 'जिलों की सूची ',
				DISTRICTNAMEPLURAL: 'जिलों ',
				DISTRICTNAMESINGULAR: 'जिला',
				NEWDISTRICT : 'नया जिला ',
				DELETEDSELECTED: 'चयनित जिले हटाएं ',
				UPDATESELECTED: 'चयनित जिलों के लिए अद्यतन स्थिति ',
				ADDNEW : 'नया जिला जोड़ें ',
				UPDATE : 'जिला अपडेट करें ',
				CREATEMESSAGE : 'जिले को सफल बनाएं ',
				UPDATEMESSAGE : 'जिले को सफलतापूर्वक अपडेट करें ',
				EDIT: 'जिला संपादित करें ',
				ADD: 'जिला जोड़ें ',
			},
			ZONE: {
				LISTINGTITLE: projectname + ' |जोन सूची',
				ADDTITLE: projectname + ' |जोन जोड़ें',
				EDITTITLE: projectname + ' |जोन अपडेट',
				ZONELIST: 'जोन सूची ',
				ZONENAMEPLURAL: 'क्षेत्र',
				ZONENAMESINGULAR: 'क्षेत्र',
				NEWZONE : 'नया क्षेत्र ',
				DELETEDSELECTED: 'चयनित क्षेत्र हटाएं ',
				UPDATESELECTED: 'चयनित क्षेत्र के लिए अद्यतन स्थिति ',
				ADDNEW : 'नया क्षेत्र जोड़ें ',
				UPDATE : 'अद्यतन क्षेत्र ',
				CREATEMESSAGE : 'ज़ोन को सफलतापूर्वक बनाएं ',
				UPDATEMESSAGE : 'जोन को सफलतापूर्वक अपडेट करें ',
				EDIT: 'एडिट जोन ',
				ADD: 'जोन जोड़ें ',
			},
			SURVEY: {
				LISTINGTITLE: projectname + ' | सर्वेक्षण सूचि',
				SURVEYLIST : 'सर्वेक्षण सूचि',
				NEWSURVEY : 'नया सर्वेक्षण',
				SURVEYNAMEPLURAL : 'सर्वेक्षण',
				SURVEYNAMESINGULAR : 'सर्वेक्षण',
				DELETEDSELECTED : 'चयनित सर्वेयर को हटा दें',
				UPDATESELECTED : 'चयनित सर्वेयर के लिए अद्यतन स्थिति',
				ADDNEW : 'नया सर्वेक्षण जोड़ें',
				UPDATE : 'अद्यतन सर्वेक्षण',
				PASSWORD: 'कुंजिका',
				CONFIRMPASSWORD: 'पासवर्ड की पुष्टि कीजिये',
				SURVEYSUCCESS: 'सर्वेक्षण सफलतापूर्वक जोड़ा गया',
				FILTERSURVEYGROUP: 'फ़िल्टर सर्वेक्षण समूह',
				ADDTITLE: projectname + ' | सर्वेयर जोड़ें',
				EDITTITLE: projectname + ' | सर्वेयर अपडेट',
				EDIT: 'सर्वेक्षण संपादित करें',
				ADD: 'सर्वेक्षण जोड़ें',
			},
			LANGUAGE: {
				LISTINGTITLE: projectname + ' |भाषा सूची',
				ADDTITLE: projectname + ' |भाषा जोड़ें',
				EDITTITLE: projectname + ' |भाषा अपडेट',
				LANGUAGELIST : 'भाषा सूची ',
				LANGUAGENAMEPLURAL : 'बोली ',
				LANGUAGENAMESINGULAR : 'भाषा: हिन्दी',
				NEWLANGUAGE : 'नई भाषा',
				DELETEDSELECTED : 'चयनित भाषाओं को हटाएं ',
				UPDATESELECTED : 'चयनित भाषाओं के लिए अद्यतन स्थिति',
				ADDNEW : 'नई भाषा जोड़ें ',
				UPDATE : 'अद्यतन भाषा ',
				CREATEMESSAGE : 'भाषा को सफलतापूर्वक बनाएं ',
				UPDATEMESSAGE : 'भाषा को सफलतापूर्वक अपडेट करें ',
				EDIT: 'भाषा संपादित करें ',
				ADD: 'भाषा जोड़ें ',
				LOCALE: 'स्थानीय ',
				LOCALE_REQUIRED: 'लोकेल आवश्यक ',
				LOCALE_INVALID: 'लोकेल में अमान्य वर्ण हैं '
			},
			USERS: {
				LISTINGTITLE: projectname + ' |उपयोगकर्ता सूची',
				USERSLIST : 'उपयोगकर्ता सूची ',
				NEWUSER : 'नया उपयोगकर्ता',
				USERNAMEPLURAL : 'उपयोगकर्ताओं की',
				USERNAMESINGULAR : 'उपयोगकर्ता',
				DELETEDSELECTED : 'चयनित उपयोगकर्ताओं को हटाएं ',
				UPDATESELECTED : 'चयनित उपयोगकर्ताओं के लिए अद्यतन स्थिति',
				ADDNEW : 'नई उपयोगकर्ता को जोड़ना',
				UPDATE : 'उपयोगकर्ता को अपडेट करें',
				PASSWORD: 'कुंजिका',
				CONFIRMPASSWORD: 'पासवर्ड की पुष्टि कीजिये',
				USERSUCCESS: 'उपयोगकर्ता जोड़ा गया सफलतापूर्वक ',
				FILTERUSERGROUP: 'उपयोगकर्ता समूह फ़िल्टर करें',
				ADDTITLE: projectname + ' |उपयोगकर्ता जोड़ें',
				EDITTITLE: projectname + ' |उपयोगकर्ता अद्यतन',
				EDIT: 'यूजर को संपादित करो',
				ADD: 'उपयोगकर्ता जोड़ें',
			},
			USERGROUP: {
				LISTINGTITLE: projectname + ' |उपयोगकर्ता समूह सूची',
				USERGROUPLIST : 'उपयोगकर्ता समूह सूची ',
				USERGROUPNAMEPLURAL : 'यूसर समूह',
				USERGROUPNAMESINGULAR : 'यूजर ग्रुप',
				NEWUSERGROUP : 'नया उपयोगकर्ता समूह ',
				DELETEDSELECTED : 'चयनित उपयोगकर्ता समूह हटाएं ',
				UPDATESELECTED : 'चयनित उपयोगकर्ता समूहों के लिए अद्यतन स्थिति',
				ADDNEW : 'नया उपयोगकर्ता समूह जोड़ें ',
				UPDATE : 'अद्यतन उपयोगकर्ता समूह ',
				ADDTITLE : projectname + ' |उपयोगकर्ता समूह जोड़ें',
				EDITTITLE : projectname + ' |अद्यतन उपयोगकर्ता समूह',
			},
			TAG: {
				SELECTTLABLE: 'टीलेबेल का चयन करें',
				LISTINGTITLE: projectname + ' |टैग चालाक',
				NAME: 'टैग ',
				NAME_REQUIRED: 'टैग नाम आवश्यक है ',
				TLABEL: 'TLabel ',
				TLABEL_REQUIRED: 'टीलाबेल आवश्यक है ',
				TAGLIST : 'टैग सूची ',
				TAGLISTSEC: 'SEC टैग सूची ',
				TAGLISTGS: 'ग्लोबल सक्सेस टैग लिस्ट ',
				TAGLISTPDF: 'पीडीएफ टैग सूची ',
				TAGNAMEPLURAL : 'टैग ',
				TAGNAMESINGULAR : 'टैग ',
				NEWTAG : 'नया दिन ',
				DELETEDSELECTED : 'चयनित टैग हटाएं ',
				UPDATESELECTED : 'चयनित टैग के लिए अद्यतन स्थिति ',
				ADDNEWGS : 'नई वैश्विक सफलता टैग जोड़ें',
				ADDNEWPDF : 'नया पीडीएफ टैग जोड़ें ',
				UPDATEGS : 'अपडेट ग्लोबल सक्सेस टैग ',
				UPDATEPDF : 'PDF टैग अपडेट करें',
				ADDTITLE: projectname + ' |टैग जोड़ें',
				EDITTITLE: projectname + ' |टैग अपडेट',
				MODULESLIST : 'टैग सूची ',
				MODULENAMEPLURAL : 'टैग ',
				MODULENAMESINGULAR : 'टैग ',
				NEWMODULE : 'नया दिन ',
				CREATEMESSAGE : 'टैग को सफलतापूर्वक बनाएं ',
				UPDATEMESSAGE : 'टैग को सफलतापूर्वक अपडेट करें ',
				EDIT: 'टैग संपादित करें',
				ADD: 'टैग जोड़ो',
				OTHER: 'अन्य ',
				SEARCHTAG: 'टैग लगाएं',
			},
			QUESTION: {
				LISTINGTITLE: projectname + ' | प्रश्न सूची ',
				ADDTITLE: projectname + ' | प्रश्न जोड़ें ',
				EDITTITLE: projectname + ' | प्रश्न अपडेट ',
				QUESTIONLIST: 'प्रश्न सूची',
				QUESTIONNAMEPLURAL: 'प्रशन',
				QUESTIONNAMESINGULAR: 'सवाल',
				NEWQUESTION : 'नया सवाल',
				DELETEDSELECTED: 'चयनित प्रश्न हटाएं',
				UPDATESELECTED: 'चयनित प्रश्नों के लिए अद्यतन स्थिति',
				ADDNEW : 'नया प्रश्न जोड़ें',
				UPDATE : 'अपडेट प्रश्न',
				CREATEMESSAGE : 'प्रश्न सफलतापूर्वक बनाएं',
				UPDATEMESSAGE : 'अद्यतन प्रश्न सफलतापूर्वक',
				EDIT: 'प्रश्न संपादित करें',
				ADD: 'प्रश्न जोड़ें',
				SINGLE_CHOICE: 'सिंगल चॉइस',
				MULTIPLE_CHOICE: 'बहुविकल्पी',
				NUMERIC: 'संख्यात्मक',
				DATE: 'दिनांक',
				TEXT : 'टेक्स्ट',
				IMAGE: 'छवि',
				BOTH: 'दोनों',
				MULTIPLE_ANSWER: 'बहु उत्तर',
				MULTIPLE_LABLE_MULTIPLE_ANSWER: 'मल्टीपल लैबल मल्टीपल उत्तर',
				QUESTION_IMAGES: 'प्रश्न छवियाँ',
				ANSWER_LIMIT: 'उत्तर सीमा',
				CHOICE: 'च्वाइस',
				ANSWER_INPUT: 'उत्तर इनपुट',
				ATTACH_FILES: 'फ़ाइल संलग्न करें',
				QUESTION_TEXT: 'प्रश्न पाठ',
				ANSWER_TYPE: 'उत्तर प्रकार',
				ANSWER_IMAGE: 'उत्तर छवि',
				MIN_RANGE: 'न्यूनतम रेंज ',
				MAX_RANGE: 'मैक्स रेंज',
				ANSWER: 'उत्तर',
				QUESTION: 'सवाल',
				SEQUENCE: 'अनुक्रम',
				ENTER_SEQUENCE: 'कृपया अनुक्रम दर्ज करें',
				INVALID_SEQUENCE: 'अमान्य अनुक्रम',
				ADD_OPTION: 'जोड़ें',
				ADD_MAXIMUM_OF: 'आप अधिकतम जोड़ सकते हैं',
				ENTER_ANSWER: 'कृपया जवाब दर्ज करें',
				INVALID_ANSWER: 'अमान्य उत्तर',
				EXCLUSIVE_RESPONSE: 'पारस्परिक रूप से विशिष्ट प्रतिक्रिया',
				ENTER_MAXIMUM_RANGE: 'कृपया अधिकतम सीमा दर्ज करें',
				Maximum_number_minimum_number: 'अधिकतम संख्या न्यूनतम संख्या से कम नहीं है',
				ENTER_MINIMUM_RANGE: 'कृपया न्यूनतम सीमा दर्ज करें',
				INVALID_RANGE: 'अमान्य श्रेणी (संख्यात्मक मान, न्यूनतम लंबाई 1 है और अधिकतम लंबाई 5 है)',
				PLEASEENTERQUESTION: 'कृपया प्रश्न दर्ज करें',
				INVALID_QUESTION: 'अमान्य प्रश्न',
				QUESTIONMUSTBEATLEAST: 'प्रश्न कम से कम 3 वर्ण लंबा होना चाहिए और स्थान की अनुमति नहीं है',
				MAX_UPLOAD_SIZE: 'अधिकतम अपलोड का आकार 5 एमबी है। अनुमत फ़ाइल प्रकार jpg, png, jpeg हैं। आप अधिकतम प्रश्न का आकार अपलोड कर सकते हैं आकार 228px * 200px होना चाहिए ',
				MAX_UPLOAD_SIZE_ADD: 'अधिकतम अपलोड का आकार 5 एमबी है। अनुमत फ़ाइल प्रकार jpg, png, jpeg हैं। छवि का आकार 228px * 200px होना चाहिए',
				REVISION_FOR_THIS_QUESTION: 'क्या आप इस प्रश्न के लिए संशोधन बनाना चाहते हैं?',
				CREATEMAPPINGMESSAGE : 'प्रश्नोत्तर सफलतापूर्वक बनाएं',
				UPDATEMAPPINGMESSAGE : 'अद्यतन प्रश्न मानचित्रण सफलतापूर्वक',
				DELETEMAPPINGMESSAGE : 'प्रश्न मानचित्रण को सफलतापूर्वक हटाएं',
				UPLOAD_ONLY: 'आप केवल अपलोड कर सकते हैं',
				CONDITIONISMAPPED: 'आप क्रम को बदल नहीं सकते क्योंकि स्थिति मैप की गई है!!',
				ATLEASTONEQUESTION: 'Please add atleast one question',
			},
			MENU: {
				LISTINGTITLE: projectname + ' |मेनू सूची ',
				ADDTITLE: projectname + ' |मेनू जोड़ें ',
				EDITTITLE: projectname + ' |मेनू अपडेट ',
				MENULIST : 'मेनू सूची',
				MENUNAMEPLURAL : 'मेन्यू',
				MENUNAMESINGULAR : 'मेन्यू',
				NEWMENU : 'नया मेनू',
				DELETEDSELECTED : 'हटाए गए मेनू को हटाएं',
				UPDATESELECTED : 'चयनित पुरुषों के लिए अद्यतन स्थिति',
				ADDNEW : 'नया मेनू जोड़ें',
				UPDATE : 'अपडेट मेनू',
				CREATEMESSAGE : 'मेनू को सफलतापूर्वक बनाएं',
				UPDATEMESSAGE : 'अपडेट मेन्यू सक्सेसफुल',
				EDIT: 'मेनू संपादित करें',
				ADD: 'मेनू जोड़ें',
				VALUES: 'मान',
				PARENT: 'जनक',
				MODULE: 'मापांक',
				PAGE: 'पृष्ठ',
				ICON: 'आइकन',
				ORDERING: 'आदेश',
				VALIDATION: {
					SELECTTYPE: 'कृपया मेनू प्रकार चुनें'
				}
			},
			ACCESSLOGS: {
				LISTINGTITLE: projectname + ' | प्रवेश सूची',
				ADDTITLE: projectname + ' | प्रवेश लॉग जोड़ें',
				EDITTITLE: projectname + ' | प्रवेश लॉग अद्यतन',
				ACCESSLOGSLIST : 'प्रवेश लॉग सूची',
				ACCESSLOGSNAMEPLURAL : 'प्रवेश लॉग',
				ACCESSLOGSNAMESINGULAR : 'प्रवेश लॉग',
				NEWACCESSLOGS : 'न्यू एक्सेस लॉग्स',
				DELETEDSELECTED : 'हटाए गए प्रवेश लॉग हटाएं',
				UPDATESELECTED : 'चयनित प्रवेश लॉग के लिए अद्यतन स्थिति',
				ADDNEW : 'नई पहुंच लॉग्स जोड़ें',
				UPDATE : 'अपडेट एक्सेस लॉग्स',
				CREATEMESSAGE : 'प्रवेश लॉग सफलतापूर्वक बनाएं',
				UPDATEMESSAGE : 'अपडेट एक्सेस लॉग्स सक्सेसफुल',
				EDIT: 'प्रवेश लॉग संपादित करें',
				ADD: 'प्रवेश प्रवेश करें',
				VALUES: 'मान',
				PARENT: 'जनक',
				MODULE: 'मापांक',
				PAGE: 'पृष्ठ',
				ICON: 'आइकन',
				ORDERING: 'आदेश',
				VALIDATION: {
					SELECTTYPE: 'कृपया प्रवेश लॉग प्रकार का चयन करें'
				}
			},
			COMMON: {
				HOME: 'घर',
				ALL: 'सब',
				ACTIVE: 'सक्रिय',
				INACTIVE: 'निष्क्रिय',
				PLEASESELECT: 'कृपया चुने',
				BASICINFORMATION: 'मूलभूत जानकारी',
				COMPANYMANAGEMENT: 'कंपनी प्रबंधन',
				COMPANYREGISTEARS: 'कंपनी रजिस्ट्रार',
				CREATEDAT: 'रचना तिथि',
				STATUS: 'स्थिति',
				ACTION: 'एक्शन ',
				DELETE: 'हटाएं ',
				TITLE: 'शीर्षक ',
				VIEW: 	'राय',
				UPDATE: 'संपादित करें ',
				PARENT: 'जनक ',
				PIC: 'पिक ',
				SEARCHBTN: 'खोज',
				FIRSTNAME: 'पहला नाम ',
				FIRSTNAMEREQ: 'कृपया पहला नाम निर्दिष्ट करें ',
				FIRSTNAMEERROR: 'पहला नाम अमान्य है ',
				NAME: 'नाम ',
				LASTNAME: 'उपनाम',
				LASTNAMEREQ: 'कृपया अंतिम नाम निर्दिष्ट करें ',
				LASTNAMEERROR: 'अंतिम नाम अमान्य है ',
				EMAIL: 'ईमेल',
				EMAILREQ: 'कृपया ईमेल ',
				EMAILERROR: 'ईमेल अमान्य है',
				WEBSITE: 'वेबसाइट',
				WEBSITEREQ: 'कृपया वेबसाइट निर्दिष्ट करें ',
				WEBSITEERROR: 'वेबसाइट अमान्य है ',
				FAX: 'फैक्स ',
				FAXERROR: 'फैक्स अमान्य है ',
				ADDRESS: 'पता ',
				MOBILENUMBER: 'मोबाइल नंबर',
				DATEOFBIRTH: 'जन्म की तारीख',
				DATEOFBIRTHERROR: 'कृपया जन्म तिथि निर्दिष्ट करें ',
				SUBMITBTN: 'सहेजें',
				SEARCH: 'खोज',
				BACKBTN: 'वापस',
				UPDATEBTN: 'अपडेट करें',
				RESETBTN:  'रीसेट',
				CANCELBTN:  'रद्द करना',
				REPORT: 'रिपोर्ट good',
				CITY: 'शहर',
				COUNTRY: 'देश ',
				CODE: 'कोड ',
				SELECTCOUNTRY: 'देश चुनिए',
				SELECTREGION: 'क्षेत्र का चयन करें',
				SELECTSTATE: 'राज्य चुनें',
				SELECTCITY: 'शहर चुनें',
				STATE: 'राज्य ',
				PIN: 'पिन ',
				ZIPCODE: 'पिन कोड',
				NORECORDS : 'कोई रिकॉर्ड नहीं मिला',
				DELETEALL : 'सभी हटा दो',
				UPDATESTATUS : 'नवीनतम स्थिति',
				DESCRIPTION: 'विवरण',
				DESIGNATION: 'पद ',
				ENTERDESCRIPTION: 'कृपया विवरण दर्ज करें ',
				STATUSUPDATED: 'स्थिति को सफलतापूर्वक अपडेट कर दिया गया है ',
				SUCCESS: 'सफलतापूर्वक ',
				SELECTED: 'चयनित',
				STATUSSELECTEDUPDATE: 'चयनित के लिए स्थिति अपडेट कर दी गई है, ',
				PERMANTLYDELETE: 'क्या आप इसे स्थायी रूप से हटाना सुनिश्चित करेंगे ',
				ISDELETING: 'हटा रहा है ',
				DELETESELECTED: 'चयनित मिटाएं',
				UPDATESELECTED: 'चयनित के लिए अद्यतन स्थिति ',
				AMOUNT: 'रकम',
				TIME: 'समय',
				VALUE: 'मान ',
				USERGROUP: 'यूजर ग्रुप',
				SELECT: 'चुनते हैं',
				VERSION: 'संस्करण ',
				TYPE_THREE_LETTERS: 'तीन अक्षर लिखें और कुछ समय प्रतीक्षा करें ',
				USERGROUPREQUIRED: 'कृपया उपयोगकर्ता समूह निर्दिष्ट करें',
				PERMISSION: 'अनुमतियां ',
				LABELSEARCH: 'यहां लेबल टाइप करें ',
				SELECTANYONE: 'किसी एक का चयन करें ',
				INVALIDFILE: 'आपने एक अवैध फ़ाइल प्रकार अपलोड किया है ',
				PASSWORDHINT: 'इसमें 8-20 अक्षर, कम से कम एक संख्या, कम से कम एक ऊपरी वर्ण, कम से कम एक कम वर्ण, कम से कम एक विशेष वर्ण शामिल हैं। ',
				OOPS: 'उफ़!',
				SUBJECT: 'विषय',
				ERROR: 'त्रुटि!',
				SELECTUSERGROUP: 'यूजर ग्रुप चुनें',
				NEXT: 'आगे',
				SELECTZONE: 'कृपया एक क्षेत्र चुनें',
				SELECTWARD: 'कृपया वार्ड का चयन करें',
				SELECTCONVENIENCE: 'कृपया सार्वजनिक सुविधा का चयन करें',
				WARD: 'परवरिश',
				SETTINGTYPE: 'सेटिंग प्रकार',
				PREVIOUS: 'पिछला',
				SELECTDATE: 'कृपया एक तिथि चुनें',
				ENTERVALUE: 'कृपया मान दर्ज करें',
				UPLOADIMAGE: 'कृपया छवि अपलोड करें',
				HELLO: 'नमस्कार!',
				WELCOMEBACK: 'वापसी पर स्वागत है',
				QUESTIONTEXT: 'ऐसा लगता है कि आप पिछली भागीदारी को पूरा नहीं कर सके। क्या आप उस जगह से जारी रखना चाहेंगे जहां से आप गए थे या आप एक नया सर्वेक्षण प्रश्न शुरू करना चाहते हैं?',
				CONTINUEWITH: 'जारी रखें',
				OR: 'या',
				PREVIOUSQUESTION: 'पिछला प्रश्न',
				FRESHQUESTION: 'ताजा प्रश्न',
				CONFIRM: 'पुष्टि करें',
				AREYOUSURE: 'क्या आप वाकई पहले शुरू करेंगे?',
				YES: 'हाँ',
				NO: 'नहीं',
				FILTERCATEGORY: 'फ़िल्टर श्रेणी',
				ALLCLUSTERS: 'सभी क्लस्टर',
				CLEAR: 'स्पष्ट',
				MAPQUESTIONNAIRE: 'नक्शा प्रश्नावली',
				SURVEYQUESTIONTITLE: 'जन सुविधा का सर्वेक्षण',
				VALIDATION: {
					NAME_INVALID: 'नाम में अमान्य वर्ण हैं',
					NAME_REQUIRED: 'कृपया नाम निर्दिष्ट करें ',
					NAME_MIN_LENGTH: 'नाम कम से कम 2 वर्ण लंबा होना चाहिए। ',
					TEMPLATE_REQUIRED: 'ईमेल टेम्पलेट खाली नहीं हो सकता',
					NAME_MAX_LENGTH: '50 से अधिक वर्ण नहीं हो सकते। ',
					ZIP_REQUIRED: 'कृपया ज़िपकोड निर्दिष्ट करें',
					ZIP_INVALID: 'ज़िपकोड अमान्य है ',
					CITY_REQUIRED: 'कृपया शहर निर्दिष्ट करें ',
					STATE_REQUIRED: 'कृपया राज्य निर्दिष्ट करें ',
					REGION_REQUIRED: 'कृपया क्षेत्र निर्दिष्ट करें ',
					COUNTRY_REQUIRED: 'कृपया देश निर्दिष्ट करें ',
					VALUE_REQUIRED: 'कृपया मान निर्दिष्ट करें ',
					TITLE_INVALID: 'शीर्षक में अमान्य वर्ण हैं',
					TITLE_REQUIRED: 'कृपया शीर्षक निर्दिष्ट करें ',
					TITLE_MIN_LENGTH: 'शीर्षक कम से कम 2 वर्ण लंबा होना चाहिए। ',
					TITLE_MAX_LENGTH: '50 से अधिक वर्ण नहीं हो सकते। ',
					SUBJECT_INVALID: 'विषय में अमान्य वर्ण हैं',
					SUBJECT_REQUIRED: 'कृपया विषय निर्दिष्ट करें',
					SUBJECT_MIN_LENGTH: 'विषय कम से कम 2 वर्ण लंबा होना चाहिए.',
					SUBJECT_MAX_LENGTH: '50 से अधिक वर्ण नहीं हो सकते.',
					PIN_REQUIRED: 'कृपया पिन निर्दिष्ट करें ',
					PIN_REQUIRED_MIN_LENGTH: 'पिन कम से कम 5 वर्ण लंबा होना चाहिए। ',
					PIN_REQUIRED_MAX_LENGTH: '6 वर्णों से अधिक नहीं हो सकता। ',
					LATITUDE_REQUIRED: 'कृपया अक्षांश निर्दिष्ट करें',
					LONGITUDE_REQUIRED: 'कृपया देशांतर निर्दिष्ट करें',
					USERGROUPREQUIRED: 'कृपया उपयोगकर्ता समूह निर्दिष्ट करें',
				},
				PATTERN: {
					MENUNAME: '[^\.\\s][^\\!\\@\\#\\$\\%\\^\\&\\*\\(\\)\\_\\+]*',
					// tslint:disable-next-line: quotemark
					// NAME : "[a-zA-Z '.&:_()-]*'"
					// tslint:disable-next-line: quotemark
					NAME: "(\\(?\\+?[^\\s0-9\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]+(\\s{0,1}[a-zA-Z-, ][^<>\\s])*\\)?)?[^0-9_\\-\\(\\)\\!\\@\\#\\$\\%\\^\\&\\*\\{\\}\\<\\.\\>\\?\\/]",
					// tslint:disable-next-line: quotemark
					EMAIL : "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$",
					// tslint:disable-next-line: quotemark
					ADSH : "[0-9-]{20}",
					// tslint:disable-next-line: quotemark
					SIC : "[0-9]{2,50}",
					// tslint:disable-next-line: quotemark
					NUMBER_ONLY : "[0-9]+",
					// tslint:disable-next-line: quotemark
					FNAME_LNAME : "^[a-zA-Z]+\\s?[a-zA-Z]+$",
					// tslint:disable-next-line: quotemark
					CONTACT_NO : "[0-9]{10}",
					// tslint:disable-next-line: quotemark
					TAG : "[a-zA-Z']*",
					// tslint:disable-next-line: quotemark
					AMOUNT : "[0-9.]+",
					// tslint:disable-next-line: quotemark
					TIME : "^([0-9]|0[0-9]|1[0-9]|2[0-3])?(:([0-5]|[0-5][0-9])?)?$",
					PUBLICCONVENIENCENAME: '[a-zA-Z0-9\\-\\s]+',
				}
			},
			PROFILE: {
				NAME: 'मेरी प्रोफाइल',
				LISTINGTITLE: projectname + ' |मेरी प्रोफाइल',
				PROFILESUCCESS: 'प्रोफाइल को सफलतापूर्वक अपडेट किया गया!!',
				INVALID_IMAGE: 'आपने एक अमान्य फ़ाइल प्रकार या अधिकतम अपलोड फ़ाइल आकार अपलोड किया है: 5 MB! ',
				PROFILE_IMAGE: 'प्रोफ़ाइल छवि',
				BASIC_INFO: 'बुनियादी जानकारी',
				PHONE: 'संपर्क नंबर।',
				PHONEREQ: 'संपर्क नंबर आवश्यक है ',
				PHONEERROR: 'संपर्क नंबर अमान्य है ',
				ADDRESS: 'पता लिखिए',
				ADDRESSTAB: 'पता ',
				CHANGEPASSWORD: 'पासवर्ड बदलें',
				RESIDENTIALADDREDSS: 'घर का पता',
				PASSWORDNOTMATCH: 'ओह तस्वीर! पासवर्ड मेल नहीं खाता। ',
				PASSWORDREQUIRED: 'पासवर्ड की आवश्यकता है',
				OLDPASSWORDREQUIRED: 'पुराना पासवर्ड आवश्यक है ',
				ENTEROLDPASSWORD: 'पुराना पासवर्ड',
				NEWPASSWORDREQUIRED: 'नया पासवर्ड आवश्यक है ',
				ENTERNEWPASSWORD: 'नया पासवर्ड',
				INVALIDNEWPASSWORD: 'नया पासवर्ड मान्य नहीं है ',
				INVALIDPASSWORD: 'पासवर्ड मान्य नहीं है ',
				NEWPASSWORDSAME: 'नया पासवर्ड पुराने पासवर्ड के समान नहीं होना चाहिए ',
				CONFIRMPASSWORD: 'पासवर्ड की पुष्टि कीजिये',
				CONFIRMPASSWORDREQUIRED: 'पासवर्ड की आवश्यकता है ',
				CONFIRMPASSWORDSAME: 'नया पासवर्ड और पुष्टि पासवर्ड मान मेल नहीं खाते। ',
				INVALIDOLDPASSWORD: 'पुराना पासवर्ड मान्य नहीं है ',
				CREATEUSERGROUP: 'उपयोगकर्ता समूह को सफलतापूर्वक बनाएं',
				UPDATEUSERGROUP: 'उपयोगकर्ता समूह को सफलतापूर्वक अपडेट करें',
			}
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'चयनित रिकॉर्ड गणना: ',
				ALL: 'सब',
				SUSPENDED: 'बर्खास्त कर दिया',
				ACTIVE: 'सक्रिय ',
				FILTER: 'फ़िल्टर ',
				BY_STATUS: 'स्थिति द्वारा',
				BY_TYPE: 'प्रकार से',
				BUSINESS: 'व्यापार',
				INDIVIDUAL: 'व्यक्ति',
				SEARCH: 'खोज',
				IN_ALL_FIELDS: 'सभी क्षेत्रों में '
			},
			ECOMMERCE: 'ई-कॉमर्स ',
			CUSTOMERS: {
				CUSTOMERS: 'ग्राहक',
				CUSTOMERS_LIST: 'ग्राहकों की सूची ',
				NEW_CUSTOMER: 'नए ग्राहक',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'ग्राहक हटाएं ',
					DESCRIPTION: 'क्या आप इस ग्राहक को स्थायी रूप से हटाना चाहते हैं? ',
					WAIT_DESCRIPTION: 'ग्राहक हटा रहा है ... ',
					MESSAGE: 'ग्राहक को हटा दिया गया है '
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'ग्राहक हटाएं ',
					DESCRIPTION: 'क्या आप चयनित ग्राहकों को स्थायी रूप से हटाना चाहते हैं? ',
					WAIT_DESCRIPTION: 'ग्राहक हटा रहे हैं ... ',
					MESSAGE: 'चयनित ग्राहकों को हटा दिया गया है '
				},
				UPDATE_STATUS: {
					TITLE: 'चयनित ग्राहकों के लिए स्थिति अपडेट कर दी गई है',
					MESSAGE: 'चयनित ग्राहकों की स्थिति को सफलतापूर्वक अपडेट कर दिया गया है '
				},
				EDIT: {
					UPDATE_MESSAGE: 'ग्राहक को अपडेट कर दिया गया है ',
					ADD_MESSAGE: 'ग्राहक बनाया गया है '
				}
			}
		},
	}
};
