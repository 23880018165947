import { Component, OnInit, OnDestroy, Input, Inject } from "@angular/core";
import { environment } from "../../../../../../environments/environment";

@Component({
	selector: "tdata-profile",
	templateUrl: "./profile.html",
	styleUrls: ["./profile.scss"],
})
export class TDataProfile implements OnInit, OnDestroy {
	@Input() config;
	@Input() data;
	showDetails = false;
	imagebucketurl = environment.imagebucketurl + "/userpic/";
	constructor() {
		// this.config = data;
	}

	ngOnInit() {
	}

	handleDetailDailoge(){
		this.showDetails = !this.showDetails
	}
	handleEdit(){
		this.config.dataConf.editAction(this.data.id)
	}


	ngOnDestroy() {}
}
