import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class LoaderService {
     // public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
     loading = new Subject<boolean>();
     display(value: boolean) {
         this.loading.next(value);

     }
}
