// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

const routes: Routes = [
	// {path: 'auth', loadChildren: 'app/views/pages/auth/auth.module#AuthModule'},
	{path: 'auth', loadChildren: 'app/core/modules/auth/auth.module#AuthModule'},

	{path: 'talent', loadChildren: 'app/views/themes/Artiste/theme.module#ThemeModule'},
	{path: 'manager', loadChildren: 'app/views/themes/Manager/theme.module#ThemeModule'},
	{path: 'admin', loadChildren: 'app/views/themes/ATLAdmin/theme.module#ThemeModule'},
	// enable this router to set which demo theme to load,
	{path: '', loadChildren: 'app/core/modules/home/home.module#HomeModule'},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
